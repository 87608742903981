<template>
    <v-dialog
        v-model="dialog"
        max-width="500px"
        scrollable
    >
        <v-card
            class="mx-auto blue-grey darken-4 rounded-lg px-3 py-6"
            dark
        >
            <v-system-bar color="transparent" class="mt-n8">
                <v-spacer></v-spacer>
                <v-btn  class="mt-12 pl-1" text plain icon @click="dialog = false">
                    <v-icon color="grey lighten-2">mdi-close</v-icon>
                </v-btn>
            </v-system-bar>
            <v-card-title class="mb-4">Añadir campaña de cultivo</v-card-title>
            <v-card-text>
                <div class="mb-15">
                    <v-row class="px-2 py-0 pt-3">
                        <v-col cols="12" class="pa-1">
                            <v-autocomplete
                                v-model="editedCampaign.seedId"
                                label="Cultivo"
                                :items="seeds.filter(s => s.is_from_user)"
                                item-text="name"
                                item-value="id"
                                required
                                outlined
                                class="rounded-lg grey darken-4"
                                rounded
                                dark
                                hide-details
                                append-icon="mdi-leaf"
                            ></v-autocomplete>
                        </v-col>
                    </v-row>
                    <v-row class="px-2 py-0">
                        <v-col cols="6" class="pa-1">
                            <v-autocomplete
                                v-model="editedCampaign.startMonth"
                                label="Mes Inicio"
                                :items="months"
                                item-text="name"
                                item-value="id"
                                required
                                outlined
                                class="rounded-lg grey darken-4"
                                rounded
                                dark
                                hide-details
                                append-icon="mdi-calendar-month"
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="6" class="pa-1">
                            <v-autocomplete
                                v-model="editedCampaign.startYear"
                                label="Año Inicio"
                                :items="years"
                                required
                                outlined
                                class="rounded-lg grey darken-4"
                                rounded
                                dark
                                hide-details
                                append-icon="mdi-calendar-start"
                            ></v-autocomplete>
                        </v-col>
                    </v-row>
                    <v-row class="px-2 py-0">
                        <v-col cols="6" class="pa-1">
                            <v-autocomplete
                                v-model="editedCampaign.endMonth"
                                label="Mes Fin"
                                :items="months"
                                item-text="name"
                                item-value="id"
                                required
                                outlined
                                class="rounded-lg grey darken-4"
                                rounded
                                dark
                                hide-details
                                append-icon="mdi-calendar-month"
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="6" class="pa-1">
                            <v-autocomplete
                                v-model="editedCampaign.endYear"
                                label="Año Fin"
                                :items="years"
                                required
                                outlined
                                class="rounded-lg grey darken-4"
                                rounded
                                dark
                                hide-details
                                append-icon="mdi-calendar-end"
                            ></v-autocomplete>
                        </v-col>
                    </v-row>
                </div>
                <div>
                    <v-autocomplete
                        v-model="editedCampaign.farmId"
                        :items="farms"
                        label="Finca"
                        item-text="name"
                        item-value="id"
                        class="rounded-lg grey darken-4 mb-3"
                        outlined
                        hide-details
                        rounded
                        dark
                        append-icon="mdi-vector-square"
                    ></v-autocomplete>
                    <v-autocomplete
                        v-model="editedCampaign.sectorId"
                        :items="sectors"
                        label="Sector"
                        item-text="name"
                        item-value="id"
                        outlined
                        hide-details
                        class="rounded-lg grey darken-4 mb-6"
                        rounded
                        dark
                        append-icon="mdi-group"
                    ></v-autocomplete>
                    <v-text-field
                        v-if="seeds.find(seed => seed.id === editedCampaign.seedId)?.units == 'plant.'"
                        v-model="editedCampaign.horizontalPlantFrame"
                        label="Marco de plantación horizontal"
                        outlined
                        hide-details
                        class="rounded-lg grey darken-4 mb-6"
                        rounded
                        dark
                        suffix="m"
                    ></v-text-field>
                    <v-text-field
                        v-model="editedCampaign.verticalPlantFrame"
                        v-if="seeds.find(seed => seed.id === editedCampaign.seedId)?.units == 'plant.'"
                        label="Marco de plantación vertical"
                        outlined
                        hide-details
                        class="rounded-lg grey darken-4 mb-6"
                        rounded
                        dark
                        suffix="m"
                    ></v-text-field>
                    <v-text-field
                        v-model="editedCampaign.plantingArea"
                        label="Área de plantación"
                        outlined
                        hide-details
                        class="rounded-lg grey darken-4 mb-6"
                        rounded
                        dark
                        suffix="ha"
                    ></v-text-field>
                </div>
                <div v-if="editedCampaign.id>0">
                    <v-btn
                        class="flex-grow-1 pa-6 elevation-0 rounded-lg red darken-4"
                        @click="remove"
                    >
                        Eliminar
                    </v-btn>
                </div>
            </v-card-text>
            <v-card-actions class="d-flex">
                <v-btn
                    class="flex-grow-1 pa-6 elevation-0 rounded-lg"
                    @click="dialog = false"
                >
                    Cerrar
                </v-btn>
                <v-btn
                    class="flex-grow-1 pa-6 elevation-0 rounded-lg teal"
                    @click="save"
                >
                    Guardar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'DialogCampaignFormComponent',
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        campaign: {
            type: Object,
            default: () => ({
                id: null,
            }),
        },
    },
    data() {
        return {
            name: '',
            editedCampaign: {
                id: null,
                farmId: null,
                sectorId: null,
                startYear: '',
                startMonth: '',
                endYear: '',
                endMonth: '',
            },
        };
    },
    computed: {
        ...mapGetters(['farms', 'months', 'seeds']),
        dialog: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            },
        },
        sectors() {
            if (this.editedCampaign.farmId) {
                return this.farms.map(farm => farm.sectors).flat().filter(sector => sector.farmId === this.editedCampaign.farmId);
                return this.farms.find(farm => farm.id === this.editedEvent.farmId).sectors;
            }
            return [];
        },
        years() {
            const years = [];
            for (let i = 2020; i <= 2050; i++) {
                years.push(i);
            }
            return years;
        },
    },
    watch: {
        dialog: {
            handler(newVal) {
                if (this.campaign.id) {
                    this.editedCampaign = Object.assign({}, this.campaign);
                    this.editedCampaign.farmId = this.campaign.sector.farmId;
                } else {
                    this.editedCampaign = {
                        id: null,
                        sectorId: this.campaign.sector.id,
                        farmId: this.campaign.sector.farmId,
                        startMonth: this.campaign.startMonth,
                        startYear: this.campaign.startYear,
                        endMonth: this.campaign.endMonth,
                        endYear: this.campaign.endYear,
                        plantingArea: this.campaign.sector.area,
                    };
                }
            },
            deep: true,
        },
    },
    methods: {
        ...mapActions(['insertCampaign', 'updateCampaign', 'deleteCampaign', 'getFarms', 'getSeeds']),
        // formatDate(date) {
        //     console.log('date', date);
        //     const year = date.getFullYear();
        //     let month = (date.getMonth() + 1).toString();
        //     let day = date.getDate().toString();
        //     let hour = date.getHours().toString();
        //     let minute = date.getMinutes().toString();
        //     let second = date.getSeconds().toString();

        //     month = month.length < 2 ? `0${month}` : month;
        //     day = day.length < 2 ? `0${day}` : day;
        //     hour = hour.length < 2 ? `0${hour}` : hour;
        //     // minute = minute.length < 2 ? `0${minute}` : minute;
        //     // second = second.length < 2 ? `0${second}` : second;

        //     // return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
        //     return `${year}-${month}-${day}`;
        // },
        save() {
            if (this.editedCampaign.id) {
                this.updateCampaign(this.editedCampaign);
            } else {
                this.insertCampaign(this.editedCampaign);
            }
            this.dialog = false;
        },
        remove() {
            this.deleteCampaign(this.editedCampaign.id);
            this.dialog = false;
        },
        
    },
    created() {
        if (!this.farms.length) {
            this.getFarms();
        }
        if (!this.seeds.length) {
            this.getSeeds();
        }
    },
};
</script>

<style scoped>

</style>    
  