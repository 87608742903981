<template>
    <v-container fluid class="fill-height blue-grey darken-4 px-3 py-0">
        <v-container fluid class="pa-0 align-self-start">
            <v-app-bar color="blue-grey darken-4" dark app fixed flat>
                <v-app-bar-nav-icon>
                    <template v-slot:default>
                        <v-img :src="require('@/assets/logo_leaf.png')" max-height="35" contain></v-img>
                    </template>
                </v-app-bar-nav-icon>
                <v-toolbar-title>Productos</v-toolbar-title>
                
                <v-spacer></v-spacer>
                <!-- <v-btn class="px-6 py-5 rounded-lg teal darken-2 text-none teal--text text--lighten-4" text>
                    <v-icon left small>mdi-download</v-icon>
                    <span class="ml-2">Descargar</span>
                </v-btn> -->
                <v-btn class="ml-2 px-6 py-5 rounded-lg teal darken-2 text-none teal--text text--lighten-4" text @click="newItem(selectedTab)">
                    <v-icon left small>mdi-plus</v-icon>
                    <span class="ml-2">{{ 
                    selectedTab=='phytosanitaries' ? 'Nuevo fitosanitario' 
                    : (
                        selectedTab=='fertilizers' ? 'Nuevo abono' 
                        : (
                            selectedTab=='seeds' ? 'Nuevo semilla/árbol'
                            : 'Nueva cosecha'
                        )
                        
                        ) }}</span>
                </v-btn>
            </v-app-bar>
            <v-row class="grey darken-4">
                <v-col cols="12" class="px-0">
                    <v-sheet elevation="6" dark>
                        <v-tabs v-model="selectedTab" dark color="teal lighten-2" class="d-flex justify-center rounded-lg">
                            <v-tab href="#phytosanitaries" class="text-capitalize" style="width: 200px">
                                <v-icon left>mdi-leaf</v-icon>
                                Fitosanitarios
                            </v-tab>
                            <v-tab href="#fertilizers" class="text-capitalize" style="width: 200px">
                                <v-icon left>mdi-auto-mode</v-icon>
                                Abonos
                            </v-tab>
                            <v-tab href="#seeds" class="text-capitalize" style="width: 200px">
                                <v-icon left>mdi-seed-outline</v-icon>
                                Semillas/Árboles
                            </v-tab>
                            <v-tab href="#harvests" class="text-capitalize" style="width: 200px">
                                <v-icon left>mdi-basket-fill</v-icon>
                                Cosechas
                            </v-tab>
                        </v-tabs>
                    </v-sheet>
                </v-col>
                <v-col cols="12" class="pa-0">
                    <v-tabs-items v-model="selectedTab" dark>
                        <v-tab-item value="phytosanitaries">
                            <PhytosanitariesView ref="phytosanitaries"/>
                        </v-tab-item>
                        <v-tab-item value="fertilizers">
                            <FertilizersView ref="fertilizers"/>
                        </v-tab-item>
                        <v-tab-item value="seeds">
                            <SeedsView ref="seeds"/>
                        </v-tab-item>
                        <v-tab-item value="harvests">
                            <HarvestsView ref="harvests"/>
                        </v-tab-item>
                    </v-tabs-items>
                </v-col>
            </v-row>
        </v-container>
    </v-container>
</template>

<script>
import PhytosanitariesView from './PhytosanitariesView';
import FertilizersView from './FertilizersView';
import SeedsView from './SeedsView';
import HarvestsView from './HarvestsView';
import { mapActions } from 'vuex'


export default {
    name: 'ProductsLayout',
    components: {
        PhytosanitariesView,
        FertilizersView,
        SeedsView,
        HarvestsView,
    },
    props: {

    },
    data() {
        return {
            selectedTab: 0,
            loading: false,
        };
    },
    computed: {

    },
    methods: {
        ...mapActions([
            'getPhytosanitaries',
            'getFertilizers',
            'getSeeds',
            'getHarvests',
        ]),
        newItem(tab) {
            this.$refs[tab].edit();
        },
    },
    async created() {
        await this.getPhytosanitaries();
        await this.getFertilizers();
        // await this.getSeeds();
        // await this.getHarvests();
    },
};
</script>