<template>
    <v-container fluid class="fill-height blue-grey darken-4 px-3 py-0">
        <v-row class="grey darken-4">
            <v-col cols="12" class="mt-3 d-flex justify-center">
                <div style="width:900px;">
                    <v-text-field
                    v-model="filters.search"
                    label="Busca en tus fitosanitarios"
                    class="rounded-lg mb-3"
                    clearable
                    outlined
                    hide-details
                    rounded
                    dark
                    append-icon="mdi-magnify"
                    >
                    </v-text-field>
                </div>
            </v-col>
            <v-col cols="12" class="px-0 py-3 overflow-y-auto d-flex justify-center" style="height: calc(100vh - 53px);">
                <v-overlay :value="loading" :absolute="true">
                    <v-progress-circular
                        indeterminate
                        size="64"
                    ></v-progress-circular>
                </v-overlay>
                <v-simple-table
                dark
                style="width: 900px"
                >
                <template v-slot:default>
                    <thead class="teal darken-3">
                        <tr class="">
                            <th class="text-left">
                                Nombre
                            </th>
                            <th class="text-left">
                                Nº de inscripción (ROPO)
                            </th>
                            <th class="text-left">
                                Composición
                            </th>
                            <th class="text-center">
                                Acciones
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                        v-for="phytosanitary in filteredPhytosanitaries"
                        :key="phytosanitary.id"
                        >
                            <td>{{ phytosanitary.name }}</td>
                            <td class="text-center">
                                <v-chip class="teal" label small>#{{ phytosanitary.registration_number }}</v-chip>
                            </td>
                            <td>{{ phytosanitary.composition }}</td>
                            <td width="120px" class="text-center">
                                <v-btn icon small class="mr-2" v-if="phytosanitary.createdBy">
                                    <v-icon small color="grey lighten-2" @click="edit(phytosanitary)">mdi-pencil</v-icon>
                                </v-btn>
                                <v-btn icon small>
                                    <v-icon small color="grey lighten-2" @click="remove(phytosanitary.id)" title="Archivar producto">mdi-archive-arrow-down</v-icon>
                                </v-btn>
                            </td>
                        </tr>
                    </tbody>
                    </template>
                </v-simple-table>
            </v-col>
        </v-row>
        <v-dialog 
        v-model="dialogEdit"
        max-width="900px"
        scrollable
        persistent
        >
            <v-card dark>
                <v-card-title>
                    <!-- TODO: Editar/Nuevo -->
                    <span class="headline">Agregar fitosanitario</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <v-autocomplete
                                    v-model="selectedItems"
                                    :items="phytosanitaries.filter(phytosanitary => !phytosanitary.is_from_user)"
                                    item-text="name"
                                    item-value="id"
                                    color="white"
                                    hide-no-data
                                    multiple
                                    label="Busca tu producto"
                                    placeholder="Escribe para buscar tu producto"
                                    prepend-icon="mdi-database-search"
                                    return-object
                                >
                                    <template v-slot:selection="{ item }">
                                        <v-chip color="teal" small label class="rounded-lg mr-1">
                                            {{ item.full_name }}
                                        </v-chip>
                                    </template>
                                    <template v-slot:item="{ item }">
                                        <v-row>
                                            <v-col cols="1">
                                                <v-checkbox :value="isSelectedItem(item)" readonly color="primary"></v-checkbox> 
                                            </v-col>
                                            <v-col cols="3" class="ml-n10 d-flex flex-column justify-center body-2 font-weight-medium">
                                                <span>{{ item.name }}</span>
                                            </v-col>
                                            <v-col cols="6" class="d-flex flex-column justify-center">
                                                <span class="caption">Composición</span>
                                                <span class="body-2 teal--text text--darken-4 font-weight-medium">{{ item.composition }}</span>
                                            </v-col>
                                            <v-col cols="2" class="d-flex flex-column justify-center">
                                                <span class="caption">Nº de inscripción</span>
                                                <v-chip class="body-2 align-self-start teal" dark label small>#{{ item.registration_number }}</v-chip>
                                            </v-col>
                                        </v-row>
                                    </template>
                                </v-autocomplete>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions class="d-flex">
                    <v-btn
                        class="flex-grow-1 pa-6 elevation-0 rounded-lg"
                        @click="close"
                    >
                        Cerrar
                    </v-btn>
                    <v-btn
                        class="flex-grow-1 pa-6 elevation-0 rounded-lg teal"
                        @click="add"
                    >
                        Añadir
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'


export default {
    name: 'PhytosanitariesView',
    data() {
        return {
            loading: false,
            dialogEdit: false,
            filters: {
                search: '',
            },
            editedPhytosanitary: {
                id: null,
                name: '',
            },       
            selectedItems: [],
        };
    },
    computed: {
        ...mapGetters([
            'phytosanitaries',
        ]),
        filteredPhytosanitaries() {
            return this.phytosanitaries.filter(phytosanitary => {
                return phytosanitary.name.toLowerCase().includes(this.filters.search.toLowerCase()) && phytosanitary.is_from_user
            })
        },
    },
    watch: {

    },
    methods: {
        ...mapActions([
            'getPhytosanitaries',
            'insertPhytosanitary',
            'updatePhytosanitary',
            'deletePhytosanitary',
            'addPhytosanitariesToUser',
        ]),
        isSelectedItem(item) {
            return this.selectedItems.some(selectedItem => selectedItem.id === item.id)
        },
        close() {
            this.dialogEdit = false
        },
        edit(phytosanitary) {
            if (phytosanitary) {
                this.editedPhytosanitary = Object.assign({}, phytosanitary)
            } else {
                this.editedPhytosanitary = {
                    id: null,
                    name: '',
                }
            }
            this.dialogEdit = true
        },
        // save() {
        //     if (this.editedPhytosanitary.id) {
        //         this.updatePhytosanitary(this.editedPhytosanitary)
        //     } else {
        //         this.insertPhytosanitary(this.editedPhytosanitary)
        //     }
        //     this.close()
        // },
        add() {
            this.addPhytosanitariesToUser(this.selectedItems)
            this.close()
        },
        remove(id) {
            this.deletePhytosanitary(id)
        }
    },
    async mounted() {
        if (this.phytosanitaries.length === 0) await this.getPhytosanitaries()
    },
};
</script>