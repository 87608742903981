import api from '@/services/api'

export default {
    namespaced: false,
    state: {
        phytosanitaryMeasureTypes: [],
    },
    getters: {
        phytosanitaryMeasureTypes: state => state.phytosanitaryMeasureTypes,
    },
    mutations: {
        setPhytosanitaryMeasureTypes(state, phytosanitaryMeasureTypes) {
            state.phytosanitaryMeasureTypes = phytosanitaryMeasureTypes
        },
    },
    actions: {
        async getPhytosanitaryMeasureTypes({ commit, dispatch }) {
            const response = await api.get(`/phytosanitaryMeasureTypes`)
            if (response.status === 200) {
                commit('setPhytosanitaryMeasureTypes', response.data.phytosanitaryMeasureTypes)
            } else if (response.status === 401 || response.status === 403) {
                dispatch('goToLogin')
            }
        },
    },
};