<template>
    <v-container fluid class="fill-height blue-grey darken-4 px-3 py-0">
        <v-container fluid class="pa-0 align-self-start">
            <v-app-bar color="blue-grey darken-4" dark app fixed flat>
                <v-app-bar-nav-icon>
                    <template v-slot:default>
                        <v-img :src="require('@/assets/logo_leaf.png')" max-height="35" contain></v-img>
                    </template>
                </v-app-bar-nav-icon>
                <v-toolbar-title>Personal</v-toolbar-title>
                
                <v-spacer></v-spacer>
                <v-btn class="ml-2 px-6 py-5 rounded-lg teal darken-2 text-none teal--text text--lighten-4" text @click="newItem(selectedTab)">
                    <v-icon left small>mdi-plus</v-icon>
                    <span class="ml-2">{{ selectedTab=='crew' ? 'Nueva cuadrilla' : (selectedTab=='staff' ? 'Nuevo trabajador' : 'Nuevo trabajador externo') }}</span>
                </v-btn>
            </v-app-bar>
            <v-row class="grey darken-4">
                <v-col cols="12" class="px-0">
                    <v-sheet elevation="6" dark>
                        <v-tabs v-model="selectedTab" dark color="teal lighten-2" class="d-flex justify-center rounded-lg">
                            <v-tab href="#staff" class="text-capitalize" style="width: 200px">
                                <v-icon left>mdi-account</v-icon>
                                Personal
                            </v-tab>
                            <v-tab href="#crew" class="text-capitalize" style="width: 200px">
                                <v-icon left>mdi-account-switch</v-icon>
                                Cuadrillas
                            </v-tab>
                            <v-tab href="#externalStaff" class="text-capitalize" style="width: 200px">
                                <v-icon left>mdi-account-arrow-left</v-icon>
                                Personal Externo
                            </v-tab>
                        </v-tabs>
                    </v-sheet>
                </v-col>
                <v-col cols="12" class="pa-0">
                    <v-tabs-items v-model="selectedTab" dark>
                        <v-tab-item value="staff">
                            <StaffView ref="staff"/>
                        </v-tab-item>
                        <v-tab-item value="crew">
                            <CrewView ref="crew"/>
                        </v-tab-item>
                        <v-tab-item value="externalStaff">
                            <ExternalStaffView ref="externalStaff"/>
                        </v-tab-item>
                    </v-tabs-items>
                </v-col>
            </v-row>
        </v-container>
    </v-container>
</template>

<script>
import StaffView from './StaffView';
import CrewView from './CrewView';
import ExternalStaffView from './ExternalStaffView';
import { mapActions } from 'vuex'

export default {
    name: 'StaffLayout',
    components: {
        StaffView,
        CrewView,
        ExternalStaffView,
    },
    props: {

    },
    data() {
        return {
            selectedTab: 0,
            loading: false,
        };
    },
    computed: {

    },
    methods: {
        ...mapActions([
            'getStaff',
            'getCrew',
            'getExternalStaff',
        ]),
        newItem(tab) {
            this.$refs[tab].edit();
        },
    },
    created() {
        this.getStaff();
        this.getCrew();
        this.getExternalStaff();
    },
};
</script>