import Vue from "vue";
import VueRouter from "vue-router";
import store from '@/store';
import HomeView from "@/views/HomeView";
import CalendarView from "@/views/CalendarView";
import ForecastView from "@/views/ForecastView";
import AnalysisView from "@/views/AnalysisView";
import MainLayout from "@/layouts/MainLayout";
import MachinesView from "@/views/MachinesView";
import ClientsView from "@/views/ClientsView";
import JobsView from "@/views/JobsView";
import WarehousesView from "@/views/WarehousesView";
import StaffLayout from "@/views/StaffLayout";
import ProductsLayout from "@/views/ProductsLayout";
import NotebooksView from "@/views/NotebooksView";
import CropCalendarView from "@/views/CropCalendarView";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "main",
        component: MainLayout,
        redirect: "/login",
        children: [
            {
                path: "/home",
                name: "home",
                component: HomeView,
            },
            {
                path: "/calendar",
                name: "calendar",
                component: CalendarView,
            },
            {
                path: "/forecast/:sectorId?",
                name: "forecast",
                component: ForecastView,
                props: true,
            },
            {
                path: "/analysis",
                name: "analysis",
                component: AnalysisView,
            },
            {
                path: "/machines",
                name: "machines",
                component: MachinesView,
            },
            {
                path: "/staff",
                name: "staff",
                component: StaffLayout,
            },
            {
                path: "/clients",
                name: "clients",
                component: ClientsView,
            },
            {
                path: "/jobs",
                name: "jobs",
                component: JobsView,
            },
            {
                path: "/warehouses",
                name: "warehouses",
                component: WarehousesView,
            },
            {
                path: "/products",
                name: "products",
                component: ProductsLayout,
            },
            {
                path: "/notebooks",
                name: "notebooks",
                component: NotebooksView,
            },
            {
                path: "/crop-calendar",
                name: "crop-calendar",
                component: CropCalendarView,
            },
            {
                path: '/logout',
                beforeEnter (to, from, next) {
                    store.dispatch('auth/logout');
                    next();
                },
            },
        ]
    },
    {
        path: "/login",
        name: "login",
        component: function () {
            return import("@/views/LoginView.vue");
        },
    },
    
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

export default router;
