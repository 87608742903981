<template>
    <v-container fluid class="fill-height blue-grey darken-4 px-3 py-0">
        <v-container fluid class="pa-0 align-self-start">
            <v-app-bar color="blue-grey darken-4" dark app fixed flat>
                <v-app-bar-nav-icon>
                    <template v-slot:default>
                        <v-img :src="require('@/assets/logo_leaf.png')" max-height="35" contain></v-img>
                    </template>
                </v-app-bar-nav-icon>
                <v-toolbar-title>Almacenes</v-toolbar-title>
                
                <v-spacer></v-spacer>
                <!-- <v-btn class="px-6 py-5 rounded-lg teal darken-2 text-none teal--text text--lighten-4" flat>
                    <v-icon left small>mdi-download</v-icon>
                    <span class="ml-2">Descargar</span>
                </v-btn> -->
                <v-btn class="ml-2 px-6 py-5 rounded-lg teal darken-2 text-none teal--text text--lighten-4" text @click="edit()">
                    <v-icon left small>mdi-plus</v-icon>
                    <span class="ml-2">Nuevo almacén</span>
                </v-btn>
            </v-app-bar>
            <v-row class="grey darken-4">
                <v-col cols="12" class="mt-3 d-flex justify-center">
                    <div style="width:900px;">
                        <v-text-field
                        v-model="filters.search"
                        label="Buscar almacén"
                        class="rounded-lg mb-3"
                        clearable
                        outlined
                        hide-details
                        rounded
                        dark
                        append-icon="mdi-magnify"
                        >
                        </v-text-field>
                    </div>
                </v-col>
                <v-col cols="12" class="px-0 py-3 overflow-y-auto d-flex justify-center" style="height: calc(100vh - 53px);">
                    <v-overlay :value="loading" :absolute="true">
                        <v-progress-circular
                            indeterminate
                            size="64"
                        ></v-progress-circular>
                    </v-overlay>
                    <v-simple-table
                    dark
                    style="width: 900px"
                    >
                    <template v-slot:default>
                        <thead class="teal darken-3">
                            <tr>
                                <th class="text-left">
                                    Nombre
                                </th>
                                <th class="text-left">
                                    Referencia catastral
                                </th>
                                <th class="text-left">
                                    Descripción
                                </th>
                                <th class="text-left">
                                    Dirección
                                </th>
                                <th>
                                    Acciones
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                            v-for="warehouse in filteredWarehouses"
                            :key="warehouse.id"
                            >
                                <td>{{ warehouse.name }}</td>
                                <td>{{ warehouse.cadastralReference }}</td>
                                <td>{{ warehouse.description }}</td>
                                <td>{{ warehouse.address }}</td>
                                <td>
                                    <v-btn icon small class="mr-2">
                                        <v-icon small color="grey lighten-2" @click="edit(warehouse)">mdi-pencil</v-icon>
                                    </v-btn>
                                    <v-btn icon small>
                                        <v-icon small color="grey lighten-2" @click="remove(warehouse.id)">mdi-delete</v-icon>
                                    </v-btn>
                                </td>
                            </tr>
                        </tbody>
                        </template>
                    </v-simple-table>
                </v-col>
            </v-row>
            <v-dialog 
            v-model="dialogEdit"
            max-width="600px"
            scrollable
            >
                <v-card dark>
                    <v-card-title>
                        <!-- TODO: Editar/Nuevo -->
                        <span class="headline">Editar almacén</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12">
                                    <v-text-field
                                        v-model="editedWarehouse.name"
                                        label="Nombre"
                                        required
                                        class="rounded-lg"
                                        outlined
                                        hide-details
                                        rounded
                                        dark
                                        append-icon="mdi-form-textbox"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field
                                        v-model="editedWarehouse.cadastralReference"
                                        label="Referencia catastral"
                                        required
                                        class="rounded-lg"
                                        outlined
                                        hide-details
                                        rounded
                                        dark
                                        append-icon="mdi-pound"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-textarea
                                        v-model="editedWarehouse.description"
                                        label="Descripción"
                                        required
                                        class="rounded-lg"
                                        outlined
                                        hide-details
                                        rounded
                                        dark
                                        append-icon="mdi-card-text"
                                    ></v-textarea>
                                </v-col>
                                <v-col cols="12">
                                    <v-textarea
                                        v-model="editedWarehouse.address"
                                        label="Dirección"
                                        required
                                        class="rounded-lg"
                                        outlined
                                        hide-details
                                        rounded
                                        dark
                                        append-icon="mdi-map-marker"
                                    ></v-textarea>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions class="d-flex">
                        <v-btn
                            class="flex-grow-1 pa-6 elevation-0 rounded-lg"
                            @click="close"
                        >
                            Cerrar
                        </v-btn>
                        <v-btn
                            class="flex-grow-1 pa-6 elevation-0 rounded-lg teal"
                            @click="save"
                        >
                            Guardar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-container>
    </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
// import api from '@/services/api'

export default {
    name: 'WarehousesView',
    components: {
    },
    props: {

    },
    data() {
        return {
            loading: false,
            editedWarehouse: null,
            dialogEdit: false,
            filters: {
                search: '',
            },
            editedWarehouse: { 
                id: null, 
                name: '', 
                cadastralReference: '',
                description: '',
                address: '',
            },       
            // warehouses: [
            //     { 
            //         id: 1, 
            //         name: 'Almacén principal', 
            //         cadastralReference: '123456789',
            //         description: 'Almacén principal de la empresa',
            //         address: 'Calle de la piruleta, 123',
            //     },
            //     {
            //         id: 2,
            //         name: 'Almacén secundario',
            //         cadastralReference: '987654321',
            //         description: 'Almacén secundario de la empresa',
            //         address: 'Calle de la piruleta, 123',
            //     }
            // ],
        };
    },
    computed: {
        ...mapGetters([
            'warehouses',
        ]),
        filteredWarehouses() {
            return this.warehouses.filter(warehouse => {
                return warehouse.name.toLowerCase().includes(this.filters.search.toLowerCase())
            })
        },
    },
    watch: {

    },
    methods: {
        ...mapActions([
            'getWarehouses',
            'insertWarehouse',
            'updateWarehouse',
            'deleteWarehouse',
        ]),
        close() {
            this.dialogEdit = false
        },
        edit(warehouse) {
            if (warehouse) {
                this.editedWarehouse = Object.assign({}, warehouse)
            } else {
                this.editedWarehouse = {
                    id: null,
                    name: '',
                    cadastralReference: '',
                    description: '',
                    address: '',
                }
            }
            this.dialogEdit = true
        },
        save() {
            if (this.editedWarehouse.id) {
                this.updateWarehouse(this.editedWarehouse)
            } else {
                this.insertWarehouse(this.editedWarehouse)
            }
            this.close()
        },
        remove(id) {
            this.deleteWarehouse(id)
        }
    },
    created() {
        this.getWarehouses()
    },
    mounted() {

    },
};
</script>