import api from '@/services/api'

export default {
    namespaced: false,
    state: {
        externalStaff: [],
    },
    getters: {
        externalStaff: state => state.externalStaff,
    },
    mutations: {
        setExternalStaff(state, externalStaff) {
            state.externalStaff = externalStaff
        },
    },
    actions: {
        async getExternalStaff({ commit, dispatch }) {
            const response = await api.get(`/externalStaff`)
            if (response.status === 200) {
                commit('setExternalStaff', response.data.externalStaff)
            } else if (response.status === 401 || response.status === 403) {
                dispatch('goToLogin')
            }
        },
        
        async insertExternalStaff({ commit, dispatch }, externalStaff) {
            const response = await api.post(`/externalStaff`, { externalStaff })
            if (response.status === 201) {
                dispatch('getExternalStaff')
            } else if (response.status === 401 || response.status === 403) {
                // dispatch('goToLogin')
            }
        },
        async updateExternalStaff({ commit, dispatch }, externalStaff) {
            const response = await api.put(`/externalStaff/${externalStaff.id}`, { externalStaff })
            if (response.status === 200) {
                dispatch('getExternalStaff')
            } else if (response.status === 401 || response.status === 403) {
                // dispatch('goToLogin')
            }
        },
        async deleteExternalStaff({ commit, dispatch }, externalStaffId) {
            const response = await api.delete(`/externalStaff/${externalStaffId}`)
            if (response.status === 204) {
                dispatch('getExternalStaff')
            } else if (response.status === 401 || response.status === 403) {
                // dispatch('goToLogin')
            }
        },
    },
};