<template>
    <v-dialog
        v-model="dialog"
        max-width="800px"
        scrollable
    >
        <v-card
            class="mx-auto blue-grey darken-4 rounded-lg px-3 py-6"
            dark
        >
            <v-system-bar color="transparent" class="mt-n8">
                <v-spacer></v-spacer>
                <v-btn  class="mt-12 pl-1" text plain icon @click="dialog = false">
                    <v-icon color="grey lighten-2">mdi-close</v-icon>
                </v-btn>
            </v-system-bar>
            <v-card-title class="mb-4 grey--text text--lighten-1">{{ editedEvent.id ? 'Editar actividad' : 'Añadir actividad'}}</v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12" class="px-15 pt-8">
                        <div class="mb-8">
                            <v-row class="px-2 py-0">
                                <v-col cols="12" class="pa-1">
                                    <v-autocomplete
                                        v-model="editedEvent.type"
                                        label="Tipo de actividad"
                                        :items="jobs"
                                        item-text="name"
                                        item-value="type"
                                        required
                                        outlined
                                        class="rounded-lg grey darken-4"
                                        rounded
                                        dark
                                        hide-details
                                        append-icon="mdi-format-list-bulleted-type"
                                    ></v-autocomplete>
                                </v-col>
                            </v-row>
                        </div>
                    </v-col>
                    <v-col cols="12" v-if="editedEvent.type !== ''">
                        <div class="mb-4">
                            <span class="text-h6">Configuración de la actividad</span>
                            <v-chip class="ml-4 mt-n1 px-5 rounded-lg" label  dark color="teal darken-1">
                                <v-icon small left>{{ jobTypes.find(t => t.value === editedEvent.type)?.icon }}</v-icon>
                                {{ jobTypes.find(t => t.value === editedEvent.type)?.name }}
                            </v-chip>
                        </div>
                        <ve-stepper
                            v-model="selectedStep"
                            vertical
                            class="blue-grey darken-4 elevation-0"
                        >
                            <ve-stepper-step
                            :complete="tab != ''"
                            step="1"
                            editable
                            edit-icon="mdi-list-status"
                            icon="mdi-list-status"
                            >
                                Estado
                                <div class="text-h6 teal--text" v-if="tab=='completed'">
                                    <v-icon dense left color="teal">mdi-check</v-icon>
                                    <span class="body-1 font-weight-bold">Completada</span>
                                </div>
                                <div class="text-h6 blue--text" v-else-if="tab=='planned'">
                                    <v-icon dense left color="blue">mdi-note-plus-outline</v-icon>
                                    <span class="body-1 font-weight-bold">Planificada</span>
                                </div>
                                <div class="text-h6 warning--text" v-else-if="tab=='in_progress'">
                                    <v-icon dense left color="warning">mdi-progress-clock</v-icon>
                                    <span class="body-1 font-weight-bold">En proceso</span>
                                </div>
                            </ve-stepper-step>
                            <v-stepper-content step="1">
                                <div class="d-flex justify-center">
                                    <div class="d-flex rounded-lg grey darken-4" style="width: 70%;">
                                        <v-btn class="pa-4 flex-grow-1 rounded-lg text-caption text-none" :color="tab!='planned' ? 'grey' : 'teal darken-2'" small  :text="tab!='planned'" @click="tab='planned'">
                                            Planificada
                                        </v-btn>
                                        <v-btn class="pa-4 flex-grow-1 rounded-lg text-caption text-none" active :color="tab!='in_progress' ? 'grey' : 'teal darken-2'" small :text="tab!='in_progress'" @click="tab='in_progress'">
                                            En proceso
                                        </v-btn>
                                        <v-btn class="pa-4 flex-grow-1 rounded-lg text-caption text-none" active :color="tab!='completed' ? 'grey' : 'teal darken-2'" small :text="tab!='completed'" @click="tab='completed'">
                                            Completada
                                        </v-btn>
                                    </div>
                                </div>
                            </v-stepper-content>

                            <ve-stepper-step
                            :complete="editedEvent.startDate != ''"
                            step="2"
                            editable
                            icon="mdi-calendar"
                            edit-icon="mdi-calendar"
                            >
                                Fecha
                                <div class="text-h6 teal--text text-lighten-2">
                                    <!-- <v-icon small left color="teal">mdi-calendar</v-icon> -->
                                    <span class="body-1 font-weight-bold">{{ editedEvent.startDate }}</span>
                                </div>
                            </ve-stepper-step>
                            <v-stepper-content step="2">
                                <v-menu
                                    ref="startDate"
                                    v-model="menus.startDate"
                                    :close-on-content-click="true"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                    dark
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="editedEvent.startDate"
                                        label="Fecha"
                                        v-bind="attrs"
                                        v-on="on"
                                        readonly
                                        filled 
                                        hide-details 
                                        rounded 
                                        dense 
                                        class="mr-3 rounded-lg blue-grey darken-4" 
                                        :menu-props="{ offsetY: true, maxHeight: '300px' }"
                                        append-icon="mdi-calendar-month"
                                    ></v-text-field>
                                    </template>
                                    <v-date-picker
                                    v-model="editedEvent.startDate"
                                    no-title
                                    scrollable
                                    color="teal lighten-2"
                                    locale="es"
                                    >
                                    </v-date-picker>
                                </v-menu>
                            </v-stepper-content>

                            <ve-stepper-step
                            :complete="editedEvent.duration != ''"
                            step="3"
                            editable
                            icon="mdi-clock-outline"
                            edit-icon="mdi-clock-outline"
                            >
                                Duración
                                <div class="text-h6 teal--text text-lighten-2" v-if="editedEvent.duration != ''">
                                    <!-- <v-icon small left color="teal">mdi-calendar</v-icon> -->
                                    <span class="body-1 font-weight-bold">{{ durationHours }} h {{ durationMinutes }} min </span>
                                </div>
                            </ve-stepper-step>
                            <v-stepper-content step="3">
                                <v-row>
                                    <v-col cols="6" class="pt-4">
                                        <v-text-field
                                            v-model="durationHours"
                                            label="Horas"
                                            required
                                            outlined
                                            class="rounded-lg grey darken-4"
                                            rounded
                                            dark
                                            hide-details
                                            append-icon="mdi-alpha-h-circle-outline"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="6" class="pt-4">
                                        <v-text-field
                                            v-model="durationMinutes"
                                            label="Minutos"
                                            required
                                            outlined
                                            class="rounded-lg grey darken-4"
                                            rounded
                                            dark
                                            hide-details
                                            append-icon="mdi-alpha-m-circle-outline"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-stepper-content>
                            <ve-stepper-step
                            :complete="editedEvent.sectorId > 0"
                            step="4"
                            editable
                            icon="mdi-vector-square"
                            edit-icon="mdi-vector-square"
                            >
                                Sector
                                <div class="text-h6 teal--text text-lighten-2">
                                    <!-- <v-icon small left color="teal">mdi-calendar</v-icon> -->
                                    <span class="body-1 font-weight-bold">
                                        {{ sectors.find(s => s.id === editedEvent.sectorId)?.name }}
                                    </span>
                                </div>
                            </ve-stepper-step>
                            <v-stepper-content step="4">
                                <div>
                                    <v-autocomplete
                                        v-model="editedEvent.farmId"
                                        :items="farms"
                                        label="Finca"
                                        item-text="name"
                                        item-value="id"
                                        class="rounded-lg grey darken-4 mb-3"
                                        outlined
                                        hide-details
                                        rounded
                                        dark
                                        append-icon="mdi-vector-square"
                                    ></v-autocomplete>
                                    <v-autocomplete
                                        v-model="editedEvent.sectorId"
                                        :items="sectors"
                                        label="Sector"
                                        item-text="name"
                                        item-value="id"
                                        outlined
                                        hide-details
                                        class="rounded-lg grey darken-4 mb-6"
                                        rounded
                                        dark
                                        append-icon="mdi-group"
                                    ></v-autocomplete>
                                </div>
                            </v-stepper-content>

                            <ve-stepper-step
                            v-if="eventType.need?.includes('phytosanitaries')"
                            :complete="editedEvent.phytosanitaries[0].id > 0"
                            step="5a"
                            editable
                            icon="mdi-leaf"
                            edit-icon="mdi-leaf"
                            >
                                Fitosanitario
                                <div class="text-h6 teal--text text-lighten-2">
                                    <!-- <v-icon small left color="teal">mdi-calendar</v-icon> -->
                                    <span class="body-1 font-weight-bold">
                                        {{ editedEvent.phytosanitaries[0]?.name }}
                                    </span>
                                </div>
                            </ve-stepper-step>
                            <v-stepper-content step="5a" v-if="eventType.need?.includes('phytosanitaries')">
                                <div class="pt-2">
                                    <v-autocomplete
                                        v-model="editedEvent.phytosanitaries[0]"
                                        :items="phytosanitaries.filter(e => e.is_from_user)"
                                        label="Fitosanitario"
                                        item-text="name"
                                        item-value="id"
                                        class="rounded-lg grey darken-4 mb-3"
                                        outlined
                                        hide-details
                                        rounded
                                        dark
                                        append-icon="mdi-leaf"
                                        return-object
                                    ></v-autocomplete>
                                </div>
                                <div class="pt-2" v-if="['PhytosanitaryTreatment', 'NonChemicalTreatment'].includes(eventType.value)">
                                    <v-text-field
                                        v-model="editedEvent.phytosanitaries[0].Join_PhytosanitaryEvent.productCuantity"
                                        label="Cantidad de producto"
                                        class="rounded-lg grey darken-4 mb-3"
                                        outlined
                                        hide-details
                                        rounded
                                        dark
                                        suffix="l/ha"
                                    ></v-text-field>
                                    <v-text-field
                                        v-model="editedEvent.phytosanitaries[0].Join_PhytosanitaryEvent.solutionCuantity"
                                        label="Cantidad de caldo"
                                        class="rounded-lg grey darken-4 mb-3"
                                        outlined
                                        hide-details
                                        rounded
                                        dark
                                        suffix="l/ha"
                                    ></v-text-field>
                                    <v-autocomplete
                                        v-model="editedEvent.phytosanitaries[0].Join_PhytosanitaryEvent.plagueId"
                                        :items="plagues"
                                        label="Plaga"
                                        item-text="name"
                                        item-value="id"
                                        class="rounded-lg grey darken-4 mb-3"
                                        outlined
                                        hide-details
                                        rounded
                                        dark
                                        append-icon="mdi-bug"
                                    ></v-autocomplete>
                                    <v-autocomplete
                                        v-model="editedEvent.phytosanitaries[0].Join_PhytosanitaryEvent.treatmentReasonId"
                                        :items="treatmentReasons"
                                        label="Justificación de tratamiento"
                                        item-text="name"
                                        item-value="id"
                                        class="rounded-lg grey darken-4 mb-3"
                                        outlined
                                        hide-details
                                        rounded
                                        dark
                                        append-icon="mdi-comment-alert-outline"
                                    ></v-autocomplete>
                                    <v-text-field
                                        v-model="editedEvent.phytosanitaries[0].Join_PhytosanitaryEvent.batchNumber"
                                        label="Nº de lote"
                                        class="rounded-lg grey darken-4 mb-3"
                                        outlined
                                        hide-details
                                        rounded
                                        dark
                                        append-icon="mdi-numeric"
                                    ></v-text-field>
                                    <v-autocomplete
                                        v-model="editedEvent.phytosanitaries[0].Join_PhytosanitaryEvent.warehouseId"
                                        :items="warehouses"
                                        label="Almacén"
                                        item-text="name"
                                        item-value="id"
                                        class="rounded-lg grey darken-4 mb-3"
                                        outlined
                                        hide-details
                                        rounded
                                        dark
                                        append-icon="mdi-home-city-outline"
                                    ></v-autocomplete>
                                </div>
                                <div class="pt-2" v-if="eventType.value == 'WarehousePhytosanitaryTreatment'">
                                    <v-text-field
                                        v-model="editedEvent.phytosanitaries[0].Join_PhytosanitaryEvent.productCuantity"
                                        label="Cantidad de producto"
                                        class="rounded-lg grey darken-4 mb-3"
                                        outlined
                                        hide-details
                                        rounded
                                        dark
                                        suffix="l"
                                    ></v-text-field>
                                    <v-autocomplete
                                        v-model="editedEvent.phytosanitaries[0].Join_PhytosanitaryEvent.warehouseId"
                                        :items="warehouses"
                                        label="Almacén del fitosanitario"
                                        item-text="name"
                                        item-value="id"
                                        class="rounded-lg grey darken-4 mb-3"
                                        outlined
                                        hide-details
                                        rounded
                                        dark
                                        append-icon="mdi-home-city-outline"
                                    ></v-autocomplete>
                                    <v-divider class="my-5"></v-divider>
                                    <v-autocomplete
                                        v-model="editedEvent.phytosanitaries[0].Join_PhytosanitaryEvent.treatmentWarehouseId"
                                        :items="warehouses"
                                        label="Almacén tratado"
                                        item-text="name"
                                        item-value="id"
                                        class="rounded-lg grey darken-4 mb-3"
                                        outlined
                                        hide-details
                                        rounded
                                        dark
                                        append-icon="mdi-home-city-outline"
                                    ></v-autocomplete>
                                    <v-text-field
                                        v-model="editedEvent.phytosanitaries[0].Join_PhytosanitaryEvent.treatmentVolume"
                                        label="Volumen tratado"
                                        class="rounded-lg grey darken-4 mb-3"
                                        outlined
                                        hide-details
                                        rounded
                                        dark
                                        suffix="m3"
                                    ></v-text-field>
                                    <v-autocomplete
                                        v-model="editedEvent.phytosanitaries[0].Join_PhytosanitaryEvent.plagueId"
                                        :items="plagues"
                                        label="Plaga"
                                        item-text="name"
                                        item-value="id"
                                        class="rounded-lg grey darken-4 mb-3"
                                        outlined
                                        hide-details
                                        rounded
                                        dark
                                        append-icon="mdi-bug"
                                    ></v-autocomplete>
                                    <v-autocomplete
                                        v-model="editedEvent.phytosanitaries[0].Join_PhytosanitaryEvent.treatmentReasonId"
                                        :items="treatmentReasons"
                                        label="Justificación de tratamiento"
                                        item-text="name"
                                        item-value="id"
                                        class="rounded-lg grey darken-4 mb-3"
                                        outlined
                                        hide-details
                                        rounded
                                        dark
                                        append-icon="mdi-comment-alert-outline"
                                    ></v-autocomplete>
                                </div>
                                <div class="pt-2" v-if="eventType.value == 'TransportPhytosanitaryTreatment'">
                                    <v-text-field
                                        v-model="editedEvent.phytosanitaries[0].Join_PhytosanitaryEvent.productCuantity"
                                        label="Cantidad de fitosanitario"
                                        class="rounded-lg grey darken-4 mb-3"
                                        outlined
                                        hide-details
                                        rounded
                                        dark
                                        suffix="l"
                                    ></v-text-field>
                                    <v-autocomplete
                                        v-model="editedEvent.phytosanitaries[0].Join_PhytosanitaryEvent.warehouseId"
                                        :items="warehouses"
                                        label="Almacén del fitosanitario"
                                        item-text="name"
                                        item-value="id"
                                        class="rounded-lg grey darken-4 mb-3"
                                        outlined
                                        hide-details
                                        rounded
                                        dark
                                        append-icon="mdi-home-city-outline"
                                    ></v-autocomplete>
                                    <v-divider class="my-5"></v-divider>
                                    <v-autocomplete
                                        v-model="editedEvent.phytosanitaries[0].Join_PhytosanitaryEvent.treatmentMachineId"
                                        :items="machines"
                                        label="Medio de transporte tratado"
                                        item-text="name"
                                        item-value="id"
                                        class="rounded-lg grey darken-4 mb-3"
                                        outlined
                                        hide-details
                                        rounded
                                        dark
                                        append-icon="mdi-tractor"
                                    ></v-autocomplete>
                                    <v-text-field
                                        v-model="editedEvent.phytosanitaries[0].Join_PhytosanitaryEvent.treatmentVolume"
                                        label="Volumen tratado"
                                        class="rounded-lg grey darken-4 mb-3"
                                        outlined
                                        hide-details
                                        rounded
                                        dark
                                        suffix="m3"
                                    ></v-text-field>
                                    <v-autocomplete
                                        v-model="editedEvent.phytosanitaries[0].Join_PhytosanitaryEvent.plagueId"
                                        :items="plagues"
                                        label="Plaga"
                                        item-text="name"
                                        item-value="id"
                                        class="rounded-lg grey darken-4 mb-3"
                                        outlined
                                        hide-details
                                        rounded
                                        dark
                                        append-icon="mdi-bug"
                                    ></v-autocomplete>
                                    <v-autocomplete
                                        v-model="editedEvent.phytosanitaries[0].Join_PhytosanitaryEvent.treatmentReasonId"
                                        :items="treatmentReasons"
                                        label="Justificación de tratamiento"
                                        item-text="name"
                                        item-value="id"
                                        class="rounded-lg grey darken-4 mb-3"
                                        outlined
                                        hide-details
                                        rounded
                                        dark
                                        append-icon="mdi-comment-alert-outline"
                                    ></v-autocomplete>
                                </div>
                                <div class="pt-2" v-if="eventType.value == 'PostHarvestPhytosanitaryTreatment'">
                                    <v-text-field
                                        v-model="editedEvent.phytosanitaries[0].Join_PhytosanitaryEvent.productCuantity"
                                        label="Cantidad de fitosanitario"
                                        class="rounded-lg grey darken-4 mb-3"
                                        outlined
                                        hide-details
                                        rounded
                                        dark
                                        suffix="l"
                                    ></v-text-field>
                                    <v-autocomplete
                                        v-model="editedEvent.phytosanitaries[0].Join_PhytosanitaryEvent.warehouseId"
                                        :items="warehouses"
                                        label="Almacén del fitosanitario"
                                        item-text="name"
                                        item-value="id"
                                        class="rounded-lg grey darken-4 mb-3"
                                        outlined
                                        hide-details
                                        rounded
                                        dark
                                        append-icon="mdi-home-city-outline"
                                    ></v-autocomplete>
                                    <v-divider class="my-5"></v-divider>
                                    <v-autocomplete
                                        v-model="editedEvent.phytosanitaries[0].Join_PhytosanitaryEvent.treatmentHarvestId"
                                        :items="harvests.filter(e => e.is_from_user)"
                                        label="Postcosecha tratada"
                                        item-text="name"
                                        item-value="id"
                                        class="rounded-lg grey darken-4 mb-3"
                                        outlined
                                        hide-details
                                        rounded
                                        dark
                                        append-icon="mdi-basket-fill"
                                    ></v-autocomplete>
                                    <v-text-field
                                        v-model="editedEvent.phytosanitaries[0].Join_PhytosanitaryEvent.treatmentCuantity"
                                        label="Cantidad de postcosecha"
                                        class="rounded-lg grey darken-4 mb-3"
                                        outlined
                                        hide-details
                                        rounded
                                        dark
                                        suffix="t"
                                    ></v-text-field>
                                    <v-autocomplete
                                        v-model="editedEvent.phytosanitaries[0].Join_PhytosanitaryEvent.plagueId"
                                        :items="plagues"
                                        label="Plaga"
                                        item-text="name"
                                        item-value="id"
                                        class="rounded-lg grey darken-4 mb-3"
                                        outlined
                                        hide-details
                                        rounded
                                        dark
                                        append-icon="mdi-bug"
                                    ></v-autocomplete>
                                    <v-autocomplete
                                        v-model="editedEvent.phytosanitaries[0].Join_PhytosanitaryEvent.treatmentReasonId"
                                        :items="treatmentReasons"
                                        label="Justificación de tratamiento"
                                        item-text="name"
                                        item-value="id"
                                        class="rounded-lg grey darken-4 mb-3"
                                        outlined
                                        hide-details
                                        rounded
                                        dark
                                        append-icon="mdi-comment-alert-outline"
                                    ></v-autocomplete>
                                </div>
                                <div class="pt-2" v-if="eventType.value == 'SeedPhytosanitaryTreatment'">
                                    <v-text-field
                                        v-model="editedEvent.phytosanitaries[0].Join_PhytosanitaryEvent.productCuantity"
                                        label="Cantidad de fitosanitario"
                                        class="rounded-lg grey darken-4 mb-3"
                                        outlined
                                        hide-details
                                        rounded
                                        dark
                                        suffix="l"
                                    ></v-text-field>
                                    <v-autocomplete
                                        v-model="editedEvent.phytosanitaries[0].Join_PhytosanitaryEvent.warehouseId"
                                        :items="warehouses"
                                        label="Almacén del fitosanitario"
                                        item-text="name"
                                        item-value="id"
                                        class="rounded-lg grey darken-4 mb-3"
                                        outlined
                                        hide-details
                                        rounded
                                        dark
                                        append-icon="mdi-home-city-outline"
                                    ></v-autocomplete>
                                    <v-divider class="my-5"></v-divider>
                                    <v-autocomplete
                                        v-model="editedEvent.phytosanitaries[0].Join_PhytosanitaryEvent.treatmentSeedId"
                                        :items="seeds.filter(e => e.is_from_user)"
                                        label="Semilla tratada"
                                        item-text="name"
                                        item-value="id"
                                        class="rounded-lg grey darken-4 mb-3"
                                        outlined
                                        hide-details
                                        rounded
                                        dark
                                        append-icon="mdi-seed-outline"
                                    ></v-autocomplete>
                                    <v-text-field
                                        v-model="editedEvent.phytosanitaries[0].Join_PhytosanitaryEvent.treatmentCuantity"
                                        label="Cantidad de semilla"
                                        class="rounded-lg grey darken-4 mb-3"
                                        outlined
                                        hide-details
                                        rounded
                                        dark
                                        suffix="kg"
                                    ></v-text-field>
                                    <v-checkbox
                                        v-model="editedEvent.phytosanitaries[0].Join_PhytosanitaryEvent.treatedInConditioningCenter"
                                        label="Tratada en Centro de acondicionamiento"
                                        class="rounded-lg mb-3"
                                        outlined
                                        hide-details
                                        rounded
                                        dark
                                    ></v-checkbox>
                                    <v-menu
                                        ref="startDate"
                                        v-model="menus.sowingDate"
                                        :close-on-content-click="true"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                        dark
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="editedEvent.phytosanitaries[0].Join_PhytosanitaryEvent.sowingDate"
                                            label="Fecha"
                                            v-bind="attrs"
                                            v-on="on"
                                            readonly
                                            class="rounded-lg grey darken-4 mb-3"
                                            outlined
                                            hide-details
                                            rounded
                                            dark
                                            :menu-props="{ offsetY: true, maxHeight: '300px' }"
                                            append-icon="mdi-calendar-month"
                                        ></v-text-field>
                                        </template>
                                        <v-date-picker
                                        v-model="editedEvent.phytosanitaries[0].Join_PhytosanitaryEvent.sowingDate"
                                        no-title
                                        scrollable
                                        color="teal lighten-2"
                                        locale="es"
                                        >
                                        </v-date-picker>
                                    </v-menu>
                                    <v-text-field
                                        v-model="editedEvent.phytosanitaries[0].Join_PhytosanitaryEvent.sownArea"
                                        label="Área sembrada"
                                        class="rounded-lg grey darken-4 mb-3"
                                        outlined
                                        hide-details
                                        rounded
                                        dark
                                        suffix="ha"
                                    ></v-text-field>
                                </div>
                            </v-stepper-content>

                            <ve-stepper-step
                            v-if="eventType.need?.includes('fertilizers')"
                            :complete="editedEvent.fertilizers.length > 0"
                            step="5b"
                            editable
                            icon="mdi-auto-mode"
                            edit-icon="mdi-auto-mode"
                            >
                                Abono
                                <div class="text-h6 teal--text text-lighten-2">
                                    <!-- <v-icon small left color="teal">mdi-calendar</v-icon> -->
                                    <span class="body-1 font-weight-bold">
                                        {{ editedEvent.fertilizers[0]?.name }}
                                    </span>
                                </div>
                            </ve-stepper-step>
                            <v-stepper-content step="5b" v-if="eventType.need?.includes('fertilizers')">
                                <div class="pt-2">
                                    <v-autocomplete
                                        v-model="editedEvent.fertilizers[0]"
                                        :items="fertilizers.filter(e => e.is_from_user)"
                                        label="Abono"
                                        item-text="name"
                                        item-value="id"
                                        class="rounded-lg grey darken-4 mb-3"
                                        outlined
                                        hide-details
                                        rounded
                                        dark
                                        append-icon="mdi-auto-mode"
                                        return-object
                                    ></v-autocomplete>
                                </div>
                            </v-stepper-content>

                            <ve-stepper-step
                            v-if="eventType.need?.includes('harvests')"
                            :complete="editedEvent.harvests.length > 0"
                            step="5c"
                            editable
                            icon="mdi-basket-fill"
                            edit-icon="mdi-basket-fill"
                            >
                                Cosecha
                                <div class="text-h6 teal--text text-lighten-2">
                                    <span class="body-1 font-weight-bold">
                                        {{ editedEvent.harvests[0]?.name }}
                                    </span>
                                </div>
                            </ve-stepper-step>
                            <v-stepper-content step="5c" v-if="eventType.need?.includes('harvests')">
                                <div class="pt-2">
                                    <v-autocomplete
                                        v-model="editedEvent.harvests[0]"
                                        :items="harvests.filter(e => e.is_from_user)"
                                        label="Cosecha"
                                        item-text="name"
                                        item-value="id"
                                        class="rounded-lg grey darken-4 mb-3"
                                        outlined
                                        hide-details
                                        rounded
                                        dark
                                        append-icon="mdi-basket-fill"
                                        return-object
                                    ></v-autocomplete>
                                </div>
                            </v-stepper-content>

                            <ve-stepper-step
                            v-if="eventType.need?.includes('seeds')"
                            :complete="editedEvent.seeds.length > 0"
                            step="5d"
                            editable
                            icon="mdi-seed-outline"
                            edit-icon="mdi-seed-outline"
                            >
                                Semilla/Árbol
                                <div class="text-h6 teal--text text-lighten-2">
                                    <span class="body-1 font-weight-bold">
                                        {{ editedEvent.seeds[0]?.name }}
                                    </span>
                                </div>
                            </ve-stepper-step>
                            <v-stepper-content step="5d" v-if="eventType.need?.includes('seeds')">
                                <div class="pt-2">
                                    <v-autocomplete
                                        v-model="editedEvent.seeds[0]"
                                        :items="seeds.filter(e => e.is_from_user)"
                                        label="Semilla/Árbol"
                                        item-text="name"
                                        item-value="id"
                                        class="rounded-lg grey darken-4 mb-3"
                                        outlined
                                        hide-details
                                        rounded
                                        dark
                                        append-icon="mdi-seed-outline"
                                        return-object
                                    ></v-autocomplete>
                                </div>
                            </v-stepper-content>

                            <ve-stepper-step
                            :complete="editedEvent.staff.length > 0 || editedEvent.crew.length > 0 || editedEvent.externalStaff.length > 0"
                            step="6"
                            editable
                            icon="mdi-account-group-outline"
                            edit-icon="mdi-account-group-outline"
                            >
                                Personal
                                <div class="text-h6 teal--text text-lighten-2">
                                    <span class="body-1 font-weight-bold" v-for="(staff, idx) in editedEvent.staff">
                                        {{ idx > 0 ? ', ' : '' }}
                                        {{ staff.name }}
                                    </span>
                                    <span class="body-1 font-weight-bold" v-for="(crew, idx) in editedEvent.crew">
                                        {{ idx > 0 || editedEvent.staff.length >0 ? ', ' : '' }}
                                        {{ crew.name }}
                                    </span>
                                    <span class="body-1 font-weight-bold" v-for="(externalStaff, idx) in editedEvent.externalStaff">
                                        {{ idx > 0 || editedEvent.staff.length > 0 || editedEvent.crew.length > 0 ? ', ' : '' }}
                                        {{ externalStaff.name }}
                                    </span>
                                </div>
                            </ve-stepper-step>
                            <v-stepper-content step="6">
                                <v-sheet elevation="6" dark color="blue-grey darken-4">
                                    <v-tabs v-model="selectedTabStaff" dark color="teal lighten-2" class="d-flex justify-center rounded-lg blue-grey darken-4">
                                        <v-tab href="#staff" class="text-capitalize" style="width: 200px">
                                            <v-icon left>mdi-account</v-icon>
                                            Personal
                                        </v-tab>
                                        <v-tab href="#crew" class="text-capitalize" style="width: 200px">
                                            <v-icon left>mdi-account-switch</v-icon>
                                            Cuadrillas
                                        </v-tab>
                                        <v-tab href="#externalStaff" class="text-capitalize" style="width: 200px">
                                            <v-icon left>mdi-account-arrow-left</v-icon>
                                            Personal Externo
                                        </v-tab>
                                    </v-tabs>
                                </v-sheet>
                                <div class="pt-2 blue-grey darken-4">
                                    <v-tabs-items v-model="selectedTabStaff" dark class="blue-grey darken-4 pt-2">
                                        <v-tab-item value="staff">
                                            <v-autocomplete
                                                v-model="editedEvent.staff"
                                                :items="staff"
                                                label="Personal"
                                                item-text="name"
                                                item-value="id"
                                                multiple
                                                class="rounded-lg grey darken-4 mb-3"
                                                outlined
                                                hide-details
                                                rounded
                                                dark
                                                append-icon="mdi-account"
                                                return-object
                                            ></v-autocomplete>
                                        </v-tab-item>
                                        <v-tab-item value="crew">
                                            <v-autocomplete
                                                v-model="editedEvent.crew"
                                                :items="crew"
                                                label="Cuadrillas"
                                                item-text="name"
                                                item-value="id"
                                                multiple
                                                class="rounded-lg grey darken-4 mb-3"
                                                outlined
                                                hide-details
                                                rounded
                                                dark
                                                append-icon="mdi-account-switch"
                                                return-object
                                            ></v-autocomplete>
                                        </v-tab-item>
                                        <v-tab-item value="externalStaff">
                                            <v-autocomplete
                                                v-model="editedEvent.externalStaff"
                                                :items="externalStaff"
                                                label="Personal Externo"
                                                item-text="name"
                                                item-value="id"
                                                multiple
                                                class="rounded-lg grey darken-4 mb-3"
                                                outlined
                                                hide-details
                                                rounded
                                                dark
                                                append-icon="mdi-account-arrow-left"
                                                return-object
                                            ></v-autocomplete>
                                        </v-tab-item>
                                    </v-tabs-items>
                                    
                                </div>
                            </v-stepper-content>

                            <ve-stepper-step
                            :complete="editedEvent.machines.length > 0"
                            step="7"
                            editable
                            icon="mdi-tractor"
                            edit-icon="mdi-tractor"
                            >
                                Máquinas
                                <div class="text-h6 teal--text text-lighten-2">
                                    <span class="body-1 font-weight-bold" v-for="(machine, idx) in editedEvent.machines">
                                        {{ idx > 0 ? ', ' : '' }}
                                        {{ machine.name }}
                                    </span>
                                </div>
                            </ve-stepper-step>
                            <v-stepper-content step="7">
                                <div class="pt-2">
                                    <v-autocomplete
                                        v-model="editedEvent.machines"
                                        :items="machines"
                                        label="Máquinas"
                                        item-text="name"
                                        item-value="id"
                                        multiple
                                        class="rounded-lg grey darken-4 mb-3"
                                        outlined
                                        hide-details
                                        rounded
                                        dark
                                        append-icon="mdi-tractor"
                                        return-object
                                    ></v-autocomplete>
                                </div>
                            </v-stepper-content>

                            

                            <ve-stepper-step
                            :complete="true"
                            step="9"
                            editable
                            icon="mdi-comment-text-outline"
                            edit-icon="mdi-comment-text-outline"
                            >
                                Comentarios
                                <div class="text-h6 teal--text text-lighten-2">
                                    <p class="caption font-weight-bold mt-3" v-for="text in editedEvent.description?.split('\n')" style="line-height:2px;">
                                        {{ text }}
                                    </p>
                                </div>
                            </ve-stepper-step>
                            <v-stepper-content step="9">
                                <div>
                                    <v-textarea
                                    v-model="editedEvent.description"
                                    label="Comentarios"
                                    outlined
                                    hide-details
                                    class="rounded-lg grey darken-4 mt-2"
                                    rounded
                                    dark
                                    append-icon="mdi-image-text"
                                    >
                                    </v-textarea>
                                </div>
                            </v-stepper-content>
                        </ve-stepper>
                    </v-col>
                    <v-col v-if="this.event.id>0">
                        <v-btn
                            class="flex-grow-1 pa-6 elevation-0 rounded-lg red darken-4 white--text"
                            @click="remove"
                        >
                            Eliminar
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions class="d-flex">
                <v-btn
                    class="flex-grow-1 pa-6 elevation-0 rounded-lg"
                    @click="dialog = false"
                >
                    Cerrar
                </v-btn>
                <v-btn
                    class="flex-grow-1 pa-6 elevation-0 rounded-lg teal"
                    @click="save"
                >
                    Guardar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import VeStepper from '@/components/utils/VeStepper.vue';
import VeStepperStep from '@/components/utils/VeStepperStep.vue';

export default {
    name: 'DialogAddEventComponent',
    components: {
        VeStepper,
        VeStepperStep,
    },
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        event: {
            type: Object,
            default: () => ({
                id: null,
            }),
        },
    },
    data() {
        return {
            name: '',
            selectedStep: 0,
            editedEvent: {
                id: null,
                type: '',
                startDate: '',
                duration: '',
                description: '',
                status: '',
                staff: [],
                crew: [],
                externalStaff: [],
                machines: [],
                phytosanitaries: [
                    {
                        id: null,
                        name: '',
                        Join_PhytosanitaryEvent: {
                            productCuantity: 0,
                            solutionCuantity: 0,
                            plagueId: 0,
                            treatmentReasonId: 0,
                            batchNumber: '',
                            warehouseId: 0,
                        },
                    },
                ],
                fertilizers: [],
                harvests: [],
                seeds: [],
            },
            defaultEvent: {
                id: null,
                type: '',
                startDate: '',
                duration: '',
                description: '',
                status: '',
                staff: [],
                crew: [],
                externalStaff: [],
                machines: [],
                phytosanitaries: [
                    {
                        id: null,
                        name: '',
                        Join_PhytosanitaryEvent: {
                            productCuantity: 0,
                            solutionCuantity: 0,
                            plagueId: 0,
                            treatmentReasonId: 0,
                            batchNumber: '',
                            warehouseId: 0,
                        },
                    },
                ],
                fertilizers: [],
                harvests: [],
                seeds: [],
            },
            menus: {
                startDate: false,
                sowingDate: false
            },
            selectedTabStaff: 'staff',
        };
    },
    computed: {
        ...mapGetters(['farms', 'jobs', 'jobTypes', 'machines', 'staff', 'crew', 'externalStaff', 'phytosanitaries', 'fertilizers', 'harvests', 'seeds', 'plagues', 'treatmentReasons', 'warehouses']),
        dialog: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            },
        },
        sectors() {
            if (this.editedEvent.farmId) {
                return this.farms.find((farm) => farm.id === this.editedEvent.farmId).sectors;
            }
            return [];
        },
        tab: {
            get() {
                return this.editedEvent.status;
            },
            set(value) {
                this.editedEvent.status = value;
            },
        },
        durationHours: {
            get() {
                return this.editedEvent.duration?.split(':')[0];
            },
            set(value) {
                this.editedEvent.duration = `${value}:${this.durationMinutes ?? '00'}:00`;
            },
        },
        durationMinutes: {
            get() {
                return this.editedEvent.duration?.split(':')[1];
            },
            set(value) {
                this.editedEvent.duration = `${this.durationHours ?? '00'}:${value}:00`;
            },
        },
        eventType() {
            return this.jobTypes.find(t => t.value === this.editedEvent.type)
        },
        eventPhytosanitaries() {
            return this.editedEvent.phytosanitaries;
        },
    },
    watch: {
        dialog: {
            handler(newVal) {
                if (this.event.id) {
                    console.log('OK', this.event);
                    this.editedEvent = Object.assign({}, this.event);
                    this.editedEvent.volume = parseFloat(this.editedEvent.volume?.toFixed(2));
                    this.editedEvent.isForecast = false;
                } else {
                    console.log('NOK', this.event);
                    this.editedEvent = JSON.parse(JSON.stringify(this.defaultEvent));
                    this.editedEvent.sectorId = this.event.sectorId;
                    this.editedEvent.farmId = this.event.farmId;
                    this.editedEvent.startDate = this.event.startDate;
                    this.editedEvent.isForecast = false;
                }
            },
            deep: true,
        },
        eventPhytosanitaries() {
            if (!this.event.id) {
                console.log('NOOOK', this.editedEvent);
                this.editedEvent.phytosanitaries[0].Join_PhytosanitaryEvent = {...this.defaultEvent.phytosanitaries[0].Join_PhytosanitaryEvent};
                console.log('NOOOK2', this.editedEvent);
            }
        }
    },
    methods: {
        ...mapActions(['insertEvent', 'updateEvent', 'deleteEvent', 'getFarms', 'getJobs', 'getJobTypes', 'getMachines', 'getStaff', 'getCrew', 'getExternalStaff', 'getPhytosanitaries', 'getFertilizers', 'getHarvests', 'getSeeds', 'getPlagues', 'getTreatmentReasons', 'getWarehouses']),
        save() {
            if (this.editedEvent.id) {
                this.updateEvent(this.editedEvent);
            } else {
                this.insertEvent(this.editedEvent);
            }
            this.dialog = false;
        },
        remove() {
            this.deleteEvent(this.editedEvent.id);
            this.dialog = false;
        },
    },
    created() {
        if (this.farms.length === 0) {
            this.getFarms();
        }
        if (this.jobs.length === 0) {
            this.getJobs();
        }
        if (this.jobTypes.length === 0) {
            this.getJobTypes();
        }
        if (this.machines.length === 0) {
            this.getMachines();
        }
        if (this.staff.length === 0) {
            this.getStaff();
        }
        if (this.crew.length === 0) {
            this.getCrew();
        }
        if (this.externalStaff.length === 0) {
            this.getExternalStaff();
        }
        if (this.phytosanitaries.length === 0) {
            this.getPhytosanitaries();
        }
        if (this.fertilizers.length === 0) {
            this.getFertilizers();
        }
        if (this.harvests.length === 0) {
            this.getHarvests();
        }
        if (this.seeds.length === 0) {
            this.getSeeds();
        }
        if (this.plagues.length === 0) {
            this.getPlagues();
        }
        if (this.treatmentReasons.length === 0) {
            this.getTreatmentReasons();
        }
        if (this.warehouses.length === 0) {
            this.getWarehouses();
        }
    },
};
</script>

<style scoped>

</style>    
  