<template>
    <v-container fluid class="fill-height blue-grey darken-4 pa-0 d-flex align-start">
        <v-app-bar color="blue-grey darken-4" dark app fixed flat>
            <v-app-bar-nav-icon>
                <template v-slot:default>
                    <v-img :src="require('@/assets/logo_leaf.png')" max-height="35" contain></v-img>
                </template>
            </v-app-bar-nav-icon>
            <v-toolbar-title>Calendario de cultivos</v-toolbar-title>
            <div class="no-gutters d-flex justify-start ml-10" style="width: 700px">
                <v-autocomplete 
                label="Año" 
                filled 
                hide-details 
                rounded 
                dense 
                class="mr-3 rounded-lg blue-grey darken-4" 
                :menu-props="{ offsetY: true, maxHeight: '300px' }"
                prepend-inner-icon="mdi-calendar-month"
                >
                    <template v-slot:append>
                        <v-icon class="mt-1" small>mdi-menu-down</v-icon>
                    </template>
                </v-autocomplete>
                <v-autocomplete 
                label="Finca" 
                filled 
                hide-details 
                rounded 
                dense 
                class="mr-3 rounded-lg blue-grey darken-4" 
                :menu-props="{ offsetY: true, maxHeight: '300px' }"
                prepend-inner-icon="mdi-group"
                >
                    <template v-slot:append>
                        <v-icon class="mt-1" small>mdi-menu-down</v-icon>
                    </template>
                </v-autocomplete>
                <v-autocomplete 
                label="Sector" 
                filled 
                hide-details 
                rounded 
                dense 
                class="mr-3 rounded-lg blue-grey darken-4" 
                :menu-props="{ offsetY: true, maxHeight: '300px' }"
                prepend-inner-icon="mdi-select-group"
                >
                    <template v-slot:append>
                        <v-icon class="mt-1" small>mdi-menu-down</v-icon>
                    </template>
                </v-autocomplete>
                <v-autocomplete 
                label="Cultivo" 
                filled 
                hide-details 
                rounded 
                dense 
                class="mr-3 rounded-lg blue-grey darken-4" 
                :menu-props="{ offsetY: true, maxHeight: '300px' }"
                prepend-inner-icon="mdi-leaf"
                >
                    <template v-slot:append>
                        <v-icon class="mt-1" small>mdi-menu-down</v-icon>
                    </template>
                </v-autocomplete>
            </div>
            <v-spacer></v-spacer>
            <v-btn class="px-6 py-5 rounded-lg teal darken-2 text-none teal--text text--lighten-4" text @click="campaignForm()">
                <v-icon left small>mdi-plus</v-icon>
                <span class="ml-2">Añadir cultivo</span>
            </v-btn>
        </v-app-bar>
        <v-row class="fill-height my-0 no-gutters">
            <v-col cols="4" class="py-0">
                <v-card tile dark class="fill-height blue-grey darken-4 headers-card">
                    <table width="100%" cellspacing="0" class="sectors-table">
                        <tr>
                            <td class="header">
                                Campo
                            </td>
                            <!-- <td class="header">
                                Cultivo
                            </td>
                            <td class="header">
                                Año
                            </td> -->
                        </tr>
                        <template v-for="(farm, farmIdx) in farms">
                            <tr>
                                <td colspan="3" :key="'farm-' + farmIdx" class="py-2 grey--text text--lighten-2">
                                    {{ farm.name }} <!-- Asumiendo que tu objeto farm tiene una propiedad 'name' -->
                                </td>
                            </tr>
                            <tr v-for="(sector, sectorIdx) in farm.sectors" :key="`header-sector-${sectorIdx}-${farmIdx}`">
                                <td>
                                    <v-list-item>
                                        <v-list-item-avatar tile size="60">
                                            <ThumbnailMap :coordinates="sector.coordinates" :id="sector.id"></ThumbnailMap>
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                            <v-list-item-title>{{ sector.name }} <v-chip label x-small class="ml-5">{{ sector.area }} ha</v-chip></v-list-item-title>
                                            <v-list-item-subtitle>{{ sector.year }}</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </td>
                                <!-- <td>
                                    {{ sector.cropType }}
                                </td>
                                <td>
                                    2023
                                </td> -->
                            </tr>
                        </template>
                    </table>
                </v-card>
            </v-col>
            <v-col cols="8" class="py-0">
                <v-card tile dark class="fill-height blue-grey darken-4 table-container elevation-5 month-container" @scroll="checkScroll">
                    <table :width="12 * years.length * cellWidth" cellspacing="0">
                        <tr class="double-row">
                            <td class="header" :width="cellWidth * 12" v-for="year in years" :colspan="12"
                                :key="'year-' + year">
                                {{ year }}
                            </td>
                        </tr>
                        <tr class="double-row">
                            <template v-for="year in years">
                                <td class="header" :width="cellWidth" v-for="month in [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]"
                                    :key="'y-' + year + '-m-' + month"
                                    :class="{ 'teal darken-2 today': getCurrentDate().month == month && getCurrentDate().year == year }">
                                    {{ months.find(m => m.id == month).name }}
                                </td>
                                <!-- <td class="header" :width="cellWidth" v-for="month in [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]"
                                    :key="'y-' + year + '-m-' + month">
                                    {{ month }}
                                </td> -->
                            </template>
                        </tr>
                    </table>
                    <div class="events-table">
                        <template v-for="(farm, farmIdx) in farms">
                            <div class="grid-row"  :style="{ width: cellWidth * 12 * years.length + 'px' }">
                                <div class="grid-cell" :key="'farm-' + farmIdx" :style="{ width: cellWidth * 12 * years.length + 'px' }">
                                </div>
                            </div>
                            <div v-for="(sector, sectorIdx) in farm.sectors" :key="`content-sector-${sectorIdx}-${farmIdx}`" class="grid-row"
                                :style="`grid-template-columns: repeat(${12 * years.length}, 1fr); width: ${cellWidth * 12 * years.length}px`">
                                <template v-for="year in years">
                                    <div class="grid-cell" v-for="month in [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]"
                                    :class="{ 'teal darken-4': getCurrentDate().month == month && getCurrentDate().year == year }">


                                        <div v-for="event in getEvents(sector, year, month)"
                                        :style="'width: ' + (event.durationMonths * cellWidth) + 'px; height: 67px; z-index: 2; grid-column: span ' + event.durationMonths + ';'"
                                        class="my-1 card teal darken-1" @click="dialog = true; editedCampaign = event.campaign;"
                                        >
                                            <v-row class="pa-0 ma-0 d-flex justify-center pt-1">
                                                <v-col cols="1" class="d-flex justify-center align-center">
                                                    <v-icon small>mdi-leaf</v-icon>
                                                </v-col>
                                                <v-col cols="11" class="d-flex flex-column align-start justify-center px-0 pt-2 ma-0 event-info" :style="event.durationMonths > 4 ? 'display: flex !important; opacity: 1;' : ''">
                                                    <b style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                                                        {{ seeds.find(s => s.id == event.campaign.seedId)?.name }} 
                                                    </b>
                                                    <span style="font-size: 11px;"> 
                                                        {{ event.campaign.startMonth.toString().padStart(2, '0') }}/{{ event.campaign.startYear }} - {{ event.campaign.endMonth.toString().padStart(2, '0') }}/{{ event.campaign.endYear }} 
                                                    </span>
                                                </v-col>
                                            </v-row>
                                        </div>
                                        <div class="add-button d-flex justify-center align-center teal darken-3" @click="campaignForm(sector, year, month)">
                                            <v-icon dense>mdi-plus</v-icon>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </template>
                    </div>
                </v-card>
            </v-col>
        </v-row>
        <DialogCampaignFormComponent v-model="dialog" :campaign="editedCampaign"></DialogCampaignFormComponent>
    </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ThumbnailMap from '../components/ThumbnailMap.vue'
import DialogCampaignFormComponent from '../components/DialogCampaignFormComponent.vue'

Date.prototype.getDayOfYear = function () {
    var start = new Date(this.getFullYear(), 0, 0);
    var diff = this - start;
    var oneDay = 1000 * 60 * 60 * 24;
    return Math.floor(diff / oneDay);
}

export default {
    name: 'CropCalendarView',
    components: {
        ThumbnailMap,
        DialogCampaignFormComponent
    },
    data() {
        return {
            dialog: false,
            editedCampaign: {
                sector: null,
                month: null,
                year: null,
            },
            cellWidth: 70,
            years: [2022, 2023, 2024],
            ready: false,
            lastScrollLeft: 0,
        };
    },
    computed: {
        ...mapGetters(['farms', 'months', 'activityTypes', 'campaigns', 'seeds']),
        events() {
            return this.campaigns.map(campaign => {
                const yearsDiff = campaign.endYear - campaign.startYear;
                const monthsDiff = campaign.endMonth - campaign.startMonth + 12 * yearsDiff;
                return {
                    campaign,
                    durationMonths: monthsDiff < 1 ? 1 : monthsDiff,
                    sectorId: campaign.sectorId,
                }
            });
        },
    },
    watch: {
        events() {
            this.reloadTable();
        },
        farms() {
            this.reloadTable();
        },
        campaigns() {
            this.reloadTable();
        },
    },
    methods: {
        ...mapActions(['getFarms', 'getCampaigns', 'getSeeds']),
        checkScroll(event) {
            const container = event.target;
            const currentScrollLeft = container.scrollLeft;

            // Determinar la dirección del desplazamiento
            if (currentScrollLeft > this.lastScrollLeft) {
                // Desplazamiento hacia la derecha
                if (container.scrollLeft + container.offsetWidth >= container.scrollWidth - 500) {
                    this.loadNextYear();
                }
            } else {
                // Desplazamiento hacia la izquierda
                // if (container.scrollLeft <= 100) {
                //     this.loadPreviousYear();
                // }
            }

            // Actualizar la última posición de desplazamiento para la próxima vez
            this.lastScrollLeft = currentScrollLeft;
        },
        loadNextYear() {
            this.years.push(this.years[this.years.length - 1] + 1);
        },
        loadPreviousYear() {
            this.years.unshift(this.years[0] - 1);
        },
        getEvents(sector, year, month) {
            return this.events.filter(event => event.sectorId === sector.id && event.campaign.startYear === year && event.campaign.startMonth === month)
        },
        getCurrentDate() {
            const date = new Date();
            return {
                month: date.getMonth() + 1,
                year: date.getFullYear(),
            };
        },
        setRowHeights() {
            const sectorsRows = Array.from(document.querySelectorAll('.sectors-table tr'));
            const eventsRows = Array.from(document.querySelectorAll('.events-table .grid-row'));
            sectorsRows.forEach((sectorsRow, index) => {
                if (index > 0) {
                    const eventsRow = eventsRows[index - 1];
                    if (!eventsRow) return;
                    const maxHeight = Math.max(sectorsRow.clientHeight, eventsRow.clientHeight);
                    sectorsRow.style.height = `${maxHeight}px`;
                    eventsRow.style.height = `${maxHeight}px`;
                }
            });
        },
        reloadTable() {
            this.$nextTick(() => {
                this.setRowHeights();
            });
            const currentMonth = new Date().getMonth() + 1;
            this.$nextTick(() => {
                const element = document.getElementsByClassName("grid-cell")[currentMonth + 20];
                element?.scrollIntoView({ behavior: 'smooth' });
                setTimeout(() => {
                    this.ready = true;
                }, 1000);
            });
        },
        campaignForm(sector, year, month) {
            this.editedCampaign = {
                id: null,
                startMonth: month,
                startYear: year,
                endMonth: month,
                endYear: year,
                sectorId: sector?.id,
                sector
            };
            this.dialog = true;
        },
    },
    created() {
        if (this.farms.length === 0) {
            this.getFarms(); 
        }
        if (this.campaigns.length === 0) {
            this.getCampaigns(); 
        }
        if (this.seeds.length === 0) {
            this.getSeeds(); 
        }
        this.reloadTable();  
        
        const currentYear = new Date().getFullYear();
        this.years = [currentYear - 1, currentYear, currentYear + 1];
    },
    mounted() {

    },
};
</script>

<style scoped>
.table-container {
    overflow-x: auto;
}

table td {
    border: 2px solid #202020;
    border-right: none;
    border-bottom: none;
    padding: 0 10px;
    font-size: 13px;
}

table tr:last-child td {
    border-bottom: 2px solid #202020;
    padding-bottom: 1px;
}

.header {
    height: 50px;
    color: #98bddd;
}

.double-row td {
    height: 25px;
    padding: 0 !important;
    text-align: center;
}

.double-row:last-child td {
    border-bottom: none;
}

.grid-row {
    display: grid;
}

.grid-cell {
    border: 2px solid #202020;
    border-right: none;
    border-bottom: none;
    font-size: 13px;
    position: relative;
}

.events-table .grid-row:last-child .grid-cell {
    border-bottom: 2px solid #202020;
    /* padding-bottom: 1px; */
}

.card {
    /* margin: 10px; */
    padding: 0;
    border: 1px solid #575757 !important;
    border-radius: 5px;
    transition: all .3s ease;
    position: absolute;
    cursor: pointer;
    /* z-index: 5 !important;
    width: 180px !important; */

    /* box-shadow: 0 0 2px #ddd; */
}

/* .card:hover {
    width: 180px !important;
    z-index: 5 !important;
} */

.event-info {
    display: none !important;
    opacity: 0;
    animation: fade-out .0s ease;
}

/* .card:hover .event-info {
    display: flex !important;
    opacity: 1;
    animation: stop .4s ease;
} */


@keyframes stop {
    0% {
        opacity: 0;
    }
    75% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}


@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fade-out {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.add-button {
    height: 100%;
    width: 100%;
    cursor: pointer;
    opacity: 0;
    position: absolute;
    top: 0;
}

.add-button:hover {
    opacity: 1;
}

.add-button>.v-icon {
    display: none;
}

.add-button:hover>.v-icon {
    display: block;
}

.forecast{
    background: repeating-linear-gradient(
      45deg,
      /* rgba(0, 0, 0, 0) 20px,
      rgba(0, 0, 0, 0) 40px, */
      rgba(84, 110, 122, 0.7) 20px,
      rgba(84, 110, 122, 0.7) 40px,
      rgba(223, 108, 79, 0.7) 40px,
      rgba(223, 108, 79, 0.7) 60px
    );
}
</style>    
  