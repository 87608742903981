<template>
    <v-container fluid class="fill-height blue-grey darken-4 px-3 py-0">
        <v-row class="grey darken-4">
            <v-col cols="12" class="mt-3 d-flex justify-center">
                <div style="width:600px;">
                    <v-text-field
                    v-model="filters.search"
                    label="Buscar trabajador externo"
                    class="rounded-lg mb-3"
                    clearable
                    outlined
                    hide-details
                    rounded
                    dark
                    append-icon="mdi-magnify"
                    >
                    </v-text-field>
                </div>
            </v-col>
            <v-col cols="12" class="px-0 py-3 overflow-y-auto d-flex justify-center" style="height: calc(100vh - 53px);">
                <v-overlay :value="loading" :absolute="true">
                    <v-progress-circular
                        indeterminate
                        size="64"
                    ></v-progress-circular>
                </v-overlay>
                <v-simple-table
                dark
                style="width: 600px"
                >
                <template v-slot:default>
                    <thead class="teal darken-3">
                        <tr class="">
                            <th class="text-left">
                                Nombre
                            </th>
                            <th class="text-center">
                                Acciones
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                        v-for="externalStaff in filteredExternalStaff"
                        :key="externalStaff.id"
                        >
                            <td>{{ externalStaff.name }}</td>
                            <td width="120px" class="text-center">
                                <v-btn icon small class="mr-2">
                                    <v-icon small color="grey lighten-2" @click="edit(externalStaff)">mdi-pencil</v-icon>
                                </v-btn>
                                <v-btn icon small>
                                    <v-icon small color="grey lighten-2" @click="remove(externalStaff.id)">mdi-delete</v-icon>
                                </v-btn>
                            </td>
                        </tr>
                    </tbody>
                    </template>
                </v-simple-table>
            </v-col>
        </v-row>
        <v-dialog 
        v-model="dialogEdit"
        max-width="600px"
        scrollable
        >
            <v-card dark>
                <v-card-title>
                    <!-- TODO: Editar/Nuevo -->
                    <span class="headline">Editar trabajador externo</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field
                                    v-model="editedExternalStaff.name"
                                    label="Nombre completo"
                                    required
                                    class="rounded-lg mb-3"
                                    outlined
                                    hide-details
                                    rounded
                                    dark
                                    append-icon="mdi-account"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions class="d-flex">
                    <v-btn
                        class="flex-grow-1 pa-6 elevation-0 rounded-lg"
                        @click="close"
                    >
                        Cerrar
                    </v-btn>
                    <v-btn
                        class="flex-grow-1 pa-6 elevation-0 rounded-lg teal"
                        @click="save"
                    >
                        Guardar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
// import api from '@/services/api'


export default {
    name: 'ExternalStaffView',
    data() {
        return {
            loading: false,
            dialogEdit: false,
            filters: {
                search: '',
            },
            editedExternalStaff: {
                id: null,
                name: '',
            },        
            // externalStaff: [
            //     {
            //         id: 1,
            //         name: 'Juan Reina',
            //     },
            //     {
            //         id: 2,
            //         name: 'Felipe Sancho',
            //     },
                    
            // ],
        };
    },
    computed: {
        ...mapGetters([
            'externalStaff',
        ]),
        filteredExternalStaff() {
            return this.externalStaff.filter(externalStaff => {
                return externalStaff.name.toLowerCase().includes(this.filters.search.toLowerCase())
            })
        },
    },
    watch: {

    },
    methods: {
        ...mapActions([
            'getExternalStaff',
            'insertExternalStaff',
            'updateExternalStaff',
            'deleteExternalStaff',
        ]),
        close() {
            this.dialogEdit = false
        },
        edit(externalStaff) {
            if (externalStaff) {
                this.editedExternalStaff = Object.assign({}, externalStaff)
            } else {
                this.editedExternalStaff = {
                    id: null,
                    name: '',
                }
            }
            this.dialogEdit = true
        },
        save() {
            if (this.editedExternalStaff.id) {
                this.updateExternalStaff(this.editedExternalStaff)
            } else {
                this.insertExternalStaff(this.editedExternalStaff)
            }
            this.close()
        },
        remove(id) {
            this.deleteExternalStaff(id)
        }
    },
    created() {
        this.getExternalStaff()
    },
    mounted() {

    },
};
</script>