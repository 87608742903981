import api from '@/services/api'

export default {
    namespaced: false,
    state: {
        seeds: [],
    },
    getters: {
        seeds: state => state.seeds,
    },
    mutations: {
        setSeeds(state, seeds) {
            state.seeds = seeds
        },
    },
    actions: {
        async getSeeds({ commit, dispatch }) {
            const response = await api.get(`/seeds`)
            if (response.status === 200) {
                commit('setSeeds', response.data.seeds)
            } else if (response.status === 401 || response.status === 403) {
                dispatch('goToLogin')
            }
        },
        
        async insertSeed({ commit, dispatch }, seed) {
            const response = await api.post(`/seeds`, { seed })
            if (response.status === 201) {
                dispatch('getSeeds')
            } else if (response.status === 401 || response.status === 403) {
                // dispatch('goToLogin')
            }
        },
        async updateSeed({ commit, dispatch }, seed) {
            const response = await api.put(`/seeds/${seed.id}`, { seed })
            if (response.status === 200) {
                dispatch('getSeeds')
            } else if (response.status === 401 || response.status === 403) {
                // dispatch('goToLogin')
            }
        },
        async deleteSeed({ commit, dispatch }, jobId) {
            const response = await api.delete(`/seeds/${jobId}`)
            if (response.status === 204) {
                dispatch('getSeeds')
            } else if (response.status === 401 || response.status === 403) {
                // dispatch('goToLogin')
            }
        },
        async addSeedsToUser({ commit, dispatch }, seeds) {
            const response = await api.put(`/seeds/addToUser`, { seeds })
            if (response.status === 200) {
                dispatch('getSeeds')
            } else if (response.status === 401 || response.status === 403) {
                // dispatch('goToLogin')
            }
        },
    },
};