import api from '@/services/api'

export default {
    namespaced: false,
    state: {
        phytosanitaries: [],
    },
    getters: {
        phytosanitaries: state => state.phytosanitaries,
    },
    mutations: {
        setPhytosanitaries(state, phytosanitaries) {
            state.phytosanitaries = phytosanitaries
        },
    },
    actions: {
        async getPhytosanitaries({ commit, dispatch }) {
            const response = await api.get(`/phytosanitaries`)
            if (response.status === 200) {
                commit('setPhytosanitaries', response.data.phytosanitaries)
            } else if (response.status === 401 || response.status === 403) {
                dispatch('goToLogin')
            }
        },
        
        async insertPhytosanitary({ commit, dispatch }, phytosanitary) {
            const response = await api.post(`/phytosanitaries`, { phytosanitary })
            if (response.status === 201) {
                dispatch('getPhytosanitaries')
            } else if (response.status === 401 || response.status === 403) {
                // dispatch('goToLogin')
            }
        },
        async updatePhytosanitary({ commit, dispatch }, phytosanitary) {
            const response = await api.put(`/phytosanitaries/${phytosanitary.id}`, { phytosanitary })
            if (response.status === 200) {
                dispatch('getPhytosanitaries')
            } else if (response.status === 401 || response.status === 403) {
                // dispatch('goToLogin')
            }
        },
        async deletePhytosanitary({ commit, dispatch }, jobId) {
            const response = await api.delete(`/phytosanitaries/${jobId}`)
            if (response.status === 204) {
                dispatch('getPhytosanitaries')
            } else if (response.status === 401 || response.status === 403) {
                // dispatch('goToLogin')
            }
        },
        async addPhytosanitariesToUser({ commit, dispatch }, phytosanitaries) {
            const response = await api.put(`/phytosanitaries/addToUser`, { phytosanitaries })
            if (response.status === 200) {
                dispatch('getPhytosanitaries')
            } else if (response.status === 401 || response.status === 403) {
                // dispatch('goToLogin')
            }
        },
    },
};