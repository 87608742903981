<template>
    <v-container fluid class="fill-height blue-grey darken-4 pa-0 d-flex align-start">
        <v-app-bar color="blue-grey darken-4" dark app fixed flat>
            <v-app-bar-nav-icon>
                <template v-slot:default>
                    <v-img :src="require('@/assets/logo_leaf.png')" max-height="35" contain></v-img>
                </template>
            </v-app-bar-nav-icon>
            <v-toolbar-title>Calendario de actividades</v-toolbar-title>
            <div class="no-gutters d-flex justify-start ml-10" style="width: 700px">
                <v-autocomplete 
                label="Año" 
                filled 
                hide-details 
                rounded 
                dense 
                class="mr-3 rounded-lg blue-grey darken-4" 
                :menu-props="{ offsetY: true, maxHeight: '300px' }"
                prepend-inner-icon="mdi-calendar-month"
                >
                    <template v-slot:append>
                        <v-icon class="mt-1" small>mdi-menu-down</v-icon>
                    </template>
                </v-autocomplete>
                <v-autocomplete 
                label="Finca" 
                filled 
                hide-details 
                rounded 
                dense 
                class="mr-3 rounded-lg blue-grey darken-4" 
                :menu-props="{ offsetY: true, maxHeight: '300px' }"
                prepend-inner-icon="mdi-group"
                >
                    <template v-slot:append>
                        <v-icon class="mt-1" small>mdi-menu-down</v-icon>
                    </template>
                </v-autocomplete>
                <v-autocomplete 
                label="Sector" 
                filled 
                hide-details 
                rounded 
                dense 
                class="mr-3 rounded-lg blue-grey darken-4" 
                :menu-props="{ offsetY: true, maxHeight: '300px' }"
                prepend-inner-icon="mdi-select-group"
                >
                    <template v-slot:append>
                        <v-icon class="mt-1" small>mdi-menu-down</v-icon>
                    </template>
                </v-autocomplete>
                <v-autocomplete 
                label="Cultivo" 
                filled 
                hide-details 
                rounded 
                dense 
                class="mr-3 rounded-lg blue-grey darken-4" 
                :menu-props="{ offsetY: true, maxHeight: '300px' }"
                prepend-inner-icon="mdi-leaf"
                >
                    <template v-slot:append>
                        <v-icon class="mt-1" small>mdi-menu-down</v-icon>
                    </template>
                </v-autocomplete>
            </div>
            <v-spacer></v-spacer>
            <!-- <v-btn class="px-6 py-5 rounded-lg teal darken-2 text-none teal--text text--lighten-4" flat>
                <v-icon left small>mdi-download</v-icon>
                <span class="ml-2">Descargar</span>
            </v-btn> -->
        </v-app-bar>
        <v-row class="fill-height my-0 no-gutters">
            <v-col cols="4" class="py-0">
                <v-card tile dark class="fill-height blue-grey darken-4 headers-card">
                    <table width="100%" cellspacing="0" class="sectors-table">
                        <tr>
                            <td class="header">
                                Campo
                            </td>
                            <!-- <td class="header">
                                Cultivo
                            </td>
                            <td class="header">
                                Año
                            </td> -->
                        </tr>
                        <template v-for="(farm, farmIdx) in farms">
                            <tr>
                                <td colspan="3" :key="'farm-' + farmIdx" class="py-2 grey--text text--lighten-2">
                                    {{ farm.name }} <!-- Asumiendo que tu objeto farm tiene una propiedad 'name' -->
                                </td>
                            </tr>
                            <tr v-for="(sector, sectorIdx) in farm.sectors" :key="`header-sector-${sectorIdx}-${farmIdx}`">
                                <td>
                                    <v-list-item>
                                        <v-list-item-avatar tile size="60">
                                            <ThumbnailMap :coordinates="sector.coordinates" :id="sector.id"></ThumbnailMap>
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                            <v-list-item-title>{{ sector.name }} <v-chip label x-small class="ml-5">{{ sector.area }} ha</v-chip></v-list-item-title>
                                            <v-list-item-subtitle>{{ sector.year }}</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </td>
                                <!-- <td>
                                    {{ sector.cropType }}
                                </td>
                                <td>
                                    2023
                                </td> -->
                            </tr>
                        </template>
                    </table>
                </v-card>
            </v-col>
            <v-col cols="8" class="py-0">
                <v-card tile dark class="fill-height blue-grey darken-4 table-container elevation-5">
                    <table :width="365 * 25" cellspacing="0">
                        <tr class="double-row">
                            <td class="header" :width="25 * month.days" v-for="month in months" :colspan="month.days"
                                :key="'mes-' + month.name">
                                {{ month.name }}
                            </td>
                        </tr>
                        <tr class="double-row">
                            <template v-for="month in months">
                                <td class="header" width="25" v-for="day in month.days"
                                    :key="'m-' + month.name + '-dr-' + day"
                                    :class="{ 'teal darken-2 today': getCurrentDate().day == day && getCurrentDate().month == month.id }">
                                    {{ day }}
                                </td>
                            </template>
                        </tr>
                        <!-- <tr v-for="sector in farms[0].sectors" class="header-row" style="overflow: hidden;">
                            <td class="header pa-0" width="25" v-for="n in 60" :key="'s' + sector.id + '-n' + n"
                                style="overflow: hidden;">
                                <v-card v-if="n == 3 && sector.id == 2" width="25" class="orange d-flex justify-center my-2"
                                    height="50px"><v-icon small>mdi-sprinkler-variant</v-icon></v-card>
                                <v-card v-if="n == 3 && sector.id == 2" :width="25 * 4" class="orange d-flex justify-center my-2"
                                    height="50px"><v-icon small>mdi-sprinkler-variant</v-icon></v-card>
                            </td>
                        </tr> -->
                    </table>
                    <div class="events-table">
                        <template v-for="(farm, farmIdx) in farms">
                            <div class="grid-row"  :style="{ width: 25 * 365 + 'px' }">
                                <div class="grid-cell" :key="'farm-' + farmIdx" :style="{ width: 25 * 365 + 'px' }">
                                </div>
                            </div>
                            <div v-for="(sector, sectorIdx) in farm.sectors" :key="`content-sector-${sectorIdx}-${farmIdx}`" class="grid-row"
                                style="grid-template-columns: repeat(365, 1fr);" :style="{ width: 25 * 365 + 'px' }">
                                <template v-for="month in months">
                                    <div class="grid-cell" v-for="day in month.days"
                                        :class="{ 'teal darken-4': getCurrentDate().day == day && getCurrentDate().month == month.id }">
                                        <div v-for="event in getEvents(sector, day, month)"
                                            :style="'width: 25px; height: 57px; z-index: 2; position: relative; grid-column: span 1;'"
                                            class="my-1 card" :class="getEventColor(event)" @click="dialog = true; editedEvent = event;"
                                            >
                                            <v-row class="pa-0 ma-0 d-flex justify-center">
                                                <v-col cols="3"
                                                    class="d-flex justify-center align-center py-5">
                                                    <v-icon small>{{ jobTypes.find(type => type.value == event.type).icon }}</v-icon>
                                                </v-col>
                                                <v-col cols="9" class="d-flex flex-column justify-center px-0 py-2 ma-0 event-info">
                                                    <b class="mb-1">{{ (event.isForecast ? 'Consejo de ' : '') + jobTypes.find(type => type.value == event.type).name }} </b>
                                                    <span style="font-size: 11px;"> 
                                                        <span style="width: 150px;" class="text-break">{{ event.startDate.substring(0, 10) }} </span>
                                                        <span v-if="event.duration.split(':').reduce((a,b) => a +b, 0) > 0">({{ event.duration.split(':')[0] }}h {{ event.duration.split(':')[1] }}m)</span>
                                                    </span>
                                                </v-col>
                                            </v-row>
                                        </div>
                                        <div class="add-button d-flex justify-center align-center teal darken-3" @click="addEvent(sector, day, month.id)">
                                            <v-icon dense>mdi-plus</v-icon>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </template>
                    </div>
                </v-card>
            </v-col>
        </v-row>
        <DialogAddEventComponent v-model="dialog" :event="editedEvent"></DialogAddEventComponent>
    </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ThumbnailMap from '../components/ThumbnailMap.vue'
import DialogAddEventComponent from '../components/calendar/DialogAddEventComponent.vue'

Date.prototype.getDayOfYear = function () {
    var start = new Date(this.getFullYear(), 0, 0);
    var diff = this - start;
    var oneDay = 1000 * 60 * 60 * 24;
    return Math.floor(diff / oneDay);
}

export default {
    name: 'CalendarView',
    components: {
        ThumbnailMap,
        DialogAddEventComponent
    },
    data() {
        return {
            dialog: false,
            editedEvent: {
                sector: null,
                day: null,
                month: null,
                year: null,
            }
        };
    },
    computed: {
        ...mapGetters(['farms', 'months', 'jobTypes']),
        events() {
            return this.farms.reduce((events, farm) => {
                farm.sectors.forEach(sector => {
                    sector.events?.forEach(event => {
                        events.push({
                            ...event,
                            day: new Date(event.startDate).getDate(),
                            month: new Date(event.startDate).getMonth() + 1,
                            farmId: farm.id,
                            sectorId: sector.id,
                        });
                    });
                });
                return events;
            }, []);
        },
    },
    watch: {
        events() {
            this.reloadTable();
        },
        farms() {
            this.reloadTable();
        }
    },
    methods: {
        ...mapActions(['getFarms']),
        getEvents(sector, day, month) {
            return this.events.filter(event => event.sectorId === sector.id && event.day === day && event.month === month.id)
        },
        getCurrentDate() {
            const date = new Date();
            return {
                day: date.getDate(),
                month: date.getMonth() + 1,
                year: date.getFullYear(),
            };
        },
        setRowHeights() {
            const sectorsRows = Array.from(document.querySelectorAll('.sectors-table tr'));
            const eventsRows = Array.from(document.querySelectorAll('.events-table .grid-row'));
            sectorsRows.forEach((sectorsRow, index) => {
                if (index > 0) {
                    const eventsRow = eventsRows[index - 1];
                    if (!eventsRow) return;
                    const maxHeight = Math.max(sectorsRow.clientHeight, eventsRow.clientHeight);
                    sectorsRow.style.height = `${maxHeight}px`;
                    eventsRow.style.height = `${maxHeight}px`;
                }
            });
        },
        reloadTable() {
            this.$nextTick(() => {
                this.setRowHeights();
            });
        },
        addEvent(sector, day, month) {
            this.editedEvent = {
                id: null,
                sectorId: sector.id,
                farmId: sector.farmId,
                startDate: `${new Date().getFullYear()}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`,
                duration: '',
                type: '',
                isForecast: true,
                status: '',
            };
            this.dialog = true;
        },
        getEventColor(event) {
            const now = new Date();
            const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
            const isFuture = new Date(event.startDate) > today;
            const isForecast = event.isForecast;

            if (isForecast && isFuture) {
                return 'forecast';
            }
            if (isForecast && !isFuture) {
                return 'blue-grey darken-1';
            }
            if (!isForecast) {
                if (event.status === 'in_progress') {
                    return 'warning';
                } else if (event.status === 'completed') {
                    return 'teal darken-2';
                } else if (event.status === 'planned') {
                    return 'blue';
                }
                return 'blue-grey darken-1';
            }
            return 'orange';
        },
    },
    created() {
        if (this.farms.length === 0) {
            this.getFarms(); 
        }
        this.reloadTable();   
    },
    mounted() {
        this.reloadTable();
        let julianDay = new Date().getDayOfYear();
        // let julianDay = 20;
        // if (julianDay >= 365) {
        //     julianDay = julianDay - 24;
        // }
        setTimeout(() => {
            const element = document.getElementsByClassName("grid-cell")[julianDay];
            const xPosition = element?.offsetLeft;
            document.getElementsByClassName("table-container")[0].scroll({
                left: xPosition + 200,
                top: 0,
                behavior: 'smooth'
            })
            // .scrollLeft = xPosition - 100;
        }, 500);

    },
};
</script>

<style scoped>
.table-container {
    overflow-x: auto;
}

table td {
    border: 2px solid #202020;
    border-right: none;
    border-bottom: none;
    padding: 0 10px;
    font-size: 13px;
}

table tr:last-child td {
    border-bottom: 2px solid #202020;
    padding-bottom: 1px;
}

.header {
    height: 50px;
    color: #98bddd;
}

.double-row td {
    height: 25px;
    padding: 0 !important;
    text-align: center;
}

.double-row:last-child td {
    border-bottom: none;
}

/* .header-row {
    height: 50px;
} */

.grid-row {
    display: grid;
}

/* .grid-header {
    background-color: #ddd;
    font-weight: bold;
} */

.grid-cell {
    border: 2px solid #202020;
    border-right: none;
    border-bottom: none;
    font-size: 13px;
    width: 25px;
    position: relative;
}

.events-table .grid-row:last-child .grid-cell {
    border-bottom: 2px solid #202020;
    /* padding-bottom: 1px; */
}

.card {
    /* margin: 10px; */
    padding: 0;
    border: 1px solid #575757 !important;
    border-radius: 5px;
    transition: all .3s ease;
    cursor: pointer;
    /* box-shadow: 0 0 2px #ddd; */
}

.card:hover {
    width: 250px !important;
    z-index: 5 !important;
}

.event-info {
    display: none !important;
    opacity: 0;
    animation: fade-out .0s ease;
    /* transition: all .3s ease; */
}

.event-info b{
    line-height: 12px;
}

.card:hover .event-info {
    display: flex !important;
    opacity: 1;
    animation: stop .4s ease;
}


@keyframes stop {
    0% {
        opacity: 0;
    }
    75% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}


@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fade-out {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.add-button {
    height: 100%;
    width: 100%;
    cursor: pointer;
    opacity: 0;
    position: absolute;
    top: 0;
}

.add-button:hover {
    opacity: 1;
}

.add-button>.v-icon {
    display: none;
}

.add-button:hover>.v-icon {
    display: block;
}

.forecast{
    background: repeating-linear-gradient(
      45deg,
      /* rgba(0, 0, 0, 0) 20px,
      rgba(0, 0, 0, 0) 40px, */
      rgba(84, 110, 122, 0.7) 20px,
      rgba(84, 110, 122, 0.7) 40px,
      rgba(223, 108, 79, 0.7) 40px,
      rgba(223, 108, 79, 0.7) 60px
    );
}
</style>    
  