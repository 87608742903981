import api from '@/services/api'

export default {
    namespaced: false,
    state: {
        fertilizers: [],
    },
    getters: {
        fertilizers: state => state.fertilizers,
    },
    mutations: {
        setFertilizers(state, fertilizers) {
            state.fertilizers = fertilizers
        },
    },
    actions: {
        async getFertilizers({ commit, dispatch }) {
            const response = await api.get(`/fertilizers`)
            if (response.status === 200) {
                commit('setFertilizers', response.data.fertilizers)
            } else if (response.status === 401 || response.status === 403) {
                dispatch('goToLogin')
            }
        },
        
        async insertFertilizer({ commit, dispatch }, fertilizer) {
            const response = await api.post(`/fertilizers`, { fertilizer })
            if (response.status === 201) {
                dispatch('getFertilizers')
            } else if (response.status === 401 || response.status === 403) {
                // dispatch('goToLogin')
            }
        },
        async updateFertilizer({ commit, dispatch }, fertilizer) {
            const response = await api.put(`/fertilizers/${fertilizer.id}`, { fertilizer })
            if (response.status === 200) {
                dispatch('getFertilizers')
            } else if (response.status === 401 || response.status === 403) {
                // dispatch('goToLogin')
            }
        },
        async deleteFertilizer({ commit, dispatch }, jobId) {
            const response = await api.delete(`/fertilizers/${jobId}`)
            if (response.status === 204) {
                dispatch('getFertilizers')
            } else if (response.status === 401 || response.status === 403) {
                // dispatch('goToLogin')
            }
        },
        async addFertilizersToUser({ commit, dispatch }, fertilizers) {
            const response = await api.put(`/fertilizers/addToUser`, { fertilizers })
            if (response.status === 200) {
                dispatch('getFertilizers')
            } else if (response.status === 401 || response.status === 403) {
                // dispatch('goToLogin')
            }
        },
    },
};