import api from '@/services/api'

export default {
    namespaced: false,
    state: {
        warehouses: [],
    },
    getters: {
        warehouses: state => state.warehouses,
    },
    mutations: {
        setWarehouses(state, warehouses) {
            state.warehouses = warehouses
        },
    },
    actions: {
        async getWarehouses({ commit, dispatch }) {
            const response = await api.get(`/warehouses`)
            if (response.status === 200) {
                commit('setWarehouses', response.data.warehouses)
            } else if (response.status === 401 || response.status === 403) {
                dispatch('goToLogin')
            }
        },
        
        async insertWarehouse({ commit, dispatch }, warehouse) {
            const response = await api.post(`/warehouses`, { warehouse })
            if (response.status === 201) {
                dispatch('getWarehouses')
            } else if (response.status === 401 || response.status === 403) {
                // dispatch('goToLogin')
            }
        },
        async updateWarehouse({ commit, dispatch }, warehouse) {
            const response = await api.put(`/warehouses/${warehouse.id}`, { warehouse })
            if (response.status === 200) {
                dispatch('getWarehouses')
            } else if (response.status === 401 || response.status === 403) {
                // dispatch('goToLogin')
            }
        },
        async deleteWarehouse({ commit, dispatch }, warehouseId) {
            const response = await api.delete(`/warehouses/${warehouseId}`)
            if (response.status === 204) {
                dispatch('getWarehouses')
            } else if (response.status === 401 || response.status === 403) {
                // dispatch('goToLogin')
            }
        },
    },
};