import api from '@/services/api'

export default {
    namespaced: false,
    state: {
        notebooks: [],
    },
    getters: {
        notebooks: state => state.notebooks,
    },
    mutations: {
        setNotebooks(state, notebooks) {
            state.notebooks = notebooks
        },
    },
    actions: {
        async getNotebooks({ commit, dispatch }) {
            const response = await api.get(`/notebooks`)
            if (response.status === 200) {
                commit('setNotebooks', response.data.notebooks)
            } else if (response.status === 401 || response.status === 403) {
                dispatch('goToLogin')
            }
        },
        
        async insertNotebook({ commit, dispatch }, notebook) {
            const response = await api.post(`/notebooks`, { notebook })
            if (response.status === 201) {
                dispatch('getNotebooks')
            } else if (response.status === 401 || response.status === 403) {
                // dispatch('goToLogin')
            }
        },
        async updateNotebook({ commit, dispatch }, notebook) {
            const response = await api.put(`/notebooks/${notebook.id}`, { notebook })
            if (response.status === 200) {
                dispatch('getNotebooks')
            } else if (response.status === 401 || response.status === 403) {
                // dispatch('goToLogin')
            }
        },
        async deleteNotebook({ commit, dispatch }, notebookId) {
            const response = await api.delete(`/notebooks/${notebookId}`)
            if (response.status === 204) {
                dispatch('getNotebooks')
            } else if (response.status === 401 || response.status === 403) {
                // dispatch('goToLogin')
            }
        },
    },
};