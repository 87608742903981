import api from '@/services/api'

export default {
    namespaced: false,
    state: {
        plagues: [],
    },
    getters: {
        plagues: state => state.plagues,
    },
    mutations: {
        setPlagues(state, plagues) {
            state.plagues = plagues
        },
    },
    actions: {
        async getPlagues({ commit, dispatch }) {
            const response = await api.get(`/plagues`)
            if (response.status === 200) {
                commit('setPlagues', response.data.plagues)
            } else if (response.status === 401 || response.status === 403) {
                dispatch('goToLogin')
            }
        },
    },
};