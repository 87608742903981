<template>
    <v-container fluid class="fill-height blue-grey darken-4 px-3 py-0">
        <v-container fluid class="pa-0 align-self-start">
            <v-app-bar color="blue-grey darken-4" dark app fixed flat>
                <v-app-bar-nav-icon>
                    <template v-slot:default>
                        <v-img :src="require('@/assets/logo_leaf.png')" max-height="35" contain></v-img>
                    </template>
                </v-app-bar-nav-icon>
                <v-toolbar-title>Máquinas</v-toolbar-title>
                
                <v-spacer></v-spacer>
                <!-- <v-btn class="px-6 py-5 rounded-lg teal darken-2 text-none teal--text text--lighten-4" text>
                    <v-icon left small>mdi-download</v-icon>
                    <span class="ml-2">Descargar</span>
                </v-btn> -->
                <v-btn class="ml-2 px-6 py-5 rounded-lg teal darken-2 text-none teal--text text--lighten-4" text @click="edit()">
                    <v-icon left small>mdi-plus</v-icon>
                    <span class="ml-2">Nueva máquina</span>
                </v-btn>
            </v-app-bar>
            <v-row class="grey darken-4">
                
                <v-col cols="12" class="px-0 py-3 overflow-y-auto" style="max-height: calc(100vh - 53px);">
                    <v-overlay :value="loading" :absolute="true">
                        <v-progress-circular
                            indeterminate
                            size="64"
                        ></v-progress-circular>
                    </v-overlay>
                    <v-simple-table
                    dark
                    >
                        <template v-slot:default>
                        <thead class="teal darken-3">
                            <tr>
                                <th class="text-left">
                                    Foto
                                </th>
                                <th class="text-left">
                                    Nombre
                                </th>
                                <th class="text-left">
                                    Descripción
                                </th>
                                <th class="text-left">
                                    Tipo
                                </th>
                                <th class="text-left">
                                    Marca
                                </th>
                                <th class="text-left">
                                    Modelo
                                </th>
                                <th class="text-left">
                                    Estado de propiedad
                                </th>
                                <th class="text-left">
                                    Fecha de compra
                                </th>
                                <th class="text-left">
                                    Coste de compra
                                </th>
                                <th class="text-left">
                                    Matrícula
                                </th>
                                <th class="text-left">
                                    Última inspección
                                </th>
                                <th class="text-left">
                                    Último seguro
                                </th>
                                <th class="text-left">
                                    Nº de registro
                                </th>
                                <th>
                                    Acciones
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                            v-for="machine in machines"
                            :key="machine.id"
                            >
                                <td>
                                    <v-avatar size="40" color="grey lighten-1" tile class="rounded-lg">
                                        <img v-if="machine.photo" :src="machine.photo" />
                                        <v-icon v-else>mdi-tractor</v-icon>
                                    </v-avatar>
                                    <!-- <img width="50" class="rounded-lg" :src="machine.photo" /> -->
                                </td>
                                <td>{{ machine.name }}</td>
                                <td>{{ machine.description }}</td>
                                <td>
                                    <v-chip small class="teal rounded-lg" label >
                                        {{ machineTypes.find(t => t.value == machine.type).name }}
                                    </v-chip>
                                </td>
                                <td>{{ machine.brand }}</td>
                                <td>{{ machine.model }}</td>
                                <td>{{ ownershipStatuses.find(o => o.value === machine.ownershipStatus).name }}</td>
                                <td>{{ machine.purchaseDate.substring(0, 10) }}</td>
                                <td class="text-right pr-10">{{ machine.purchaseCost }}€</td>
                                <td>
                                    <LicensePlateComponent :value="machine.licensePlate" width="90px" />    
                                </td>
                                <td>{{ machine.lastInspectionDate.substring(0, 10) }}</td>
                                <td>{{ machine.lastInsurance }}</td>
                                <td>{{ machine.registrationNumber }}</td>
                                <td>
                                    <v-btn icon small class="mr-2">
                                        <v-icon small color="grey lighten-2" @click="edit(machine)">mdi-pencil</v-icon>
                                    </v-btn>
                                    <v-btn icon small>
                                        <v-icon small color="grey lighten-2" @click="remove(machine.id)">mdi-delete</v-icon>
                                    </v-btn>
                                </td>
                            </tr>
                        </tbody>
                        </template>
                    </v-simple-table>
                </v-col>
            </v-row>
            <v-dialog 
            v-model="dialogEdit"
            max-width="800px"
            scrollable
            >
                <v-card dark>
                    <v-card-title>
                        <span class="headline">Editar máquina</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <v-text-field
                                        v-model="editedMachine.name"
                                        label="Nombre"
                                        required
                                        class="rounded-lg mb-3"
                                        outlined
                                        hide-details
                                        rounded
                                        dark
                                        append-icon="mdi-form-textbox"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-text-field
                                        v-model="editedMachine.description"
                                        label="Descripción"
                                        required
                                        class="rounded-lg mb-3"
                                        outlined
                                        hide-details
                                        rounded
                                        dark
                                        append-icon="mdi-text-box"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-select
                                        v-model="editedMachine.type"
                                        :items="machineTypes"
                                        item-text="name"
                                        item-value="value"
                                        label="Tipo"
                                        required
                                        class="rounded-lg mb-3"
                                        outlined
                                        hide-details
                                        rounded
                                        dark
                                        append-icon="mdi-tractor"
                                    ></v-select>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-text-field
                                        v-model="editedMachine.brand"
                                        label="Marca"
                                        required
                                        class="rounded-lg mb-3"
                                        outlined
                                        hide-details
                                        rounded
                                        dark
                                        append-icon="mdi-domain"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-text-field
                                        v-model="editedMachine.model"
                                        label="Modelo"
                                        required
                                        class="rounded-lg mb-3"
                                        outlined
                                        hide-details
                                        rounded
                                        dark
                                        append-icon="mdi-alpha-m-box"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-select
                                        v-model="editedMachine.ownershipStatus"
                                        :items="ownershipStatuses"
                                        item-text="name"
                                        item-value="value"
                                        label="Estado de propiedad"
                                        required
                                        class="rounded-lg mb-3"
                                        outlined
                                        hide-details
                                        rounded
                                        dark
                                        append-icon="mdi-certificate"
                                    ></v-select>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-text-field
                                        v-model="editedMachine.purchaseDate"
                                        label="Fecha de compra"
                                        required
                                        class="rounded-lg mb-3"
                                        outlined
                                        hide-details
                                        rounded
                                        dark
                                        append-icon="mdi-calendar"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-text-field
                                        v-model="editedMachine.purchaseCost"
                                        label="Coste de compra"
                                        required
                                        class="rounded-lg mb-3"
                                        outlined
                                        hide-details
                                        rounded
                                        dark
                                        append-icon="mdi-cash"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-text-field
                                        v-model="editedMachine.licensePlate"
                                        label="Matrícula"
                                        required
                                        class="rounded-lg mb-3"
                                        outlined
                                        hide-details
                                        rounded
                                        dark
                                        append-icon="mdi-table-row"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-text-field
                                        v-model="editedMachine.lastInspectionDate"
                                        label="Última inspección"
                                        required
                                        class="rounded-lg mb-3"
                                        outlined
                                        hide-details
                                        rounded
                                        dark
                                        append-icon="mdi-calendar-check"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-text-field
                                        v-model="editedMachine.lastInsurance"
                                        label="Último seguro"
                                        required
                                        class="rounded-lg mb-3"
                                        outlined
                                        hide-details
                                        rounded
                                        dark
                                        append-icon="mdi-shield-check"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-text-field
                                        v-model="editedMachine.registrationNumber"
                                        label="Nº de registro"
                                        required
                                        class="rounded-lg mb-3"
                                        outlined
                                        hide-details
                                        rounded
                                        dark
                                        append-icon="mdi-fingerprint"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions class="d-flex">
                        <v-btn
                            class="flex-grow-1 pa-6 elevation-0 rounded-lg"
                            @click="close"
                        >
                            Cerrar
                        </v-btn>
                        <v-btn
                            class="flex-grow-1 pa-6 elevation-0 rounded-lg teal"
                            @click="save"
                        >
                            Guardar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-container>
    </v-container>
</template>

<script>
import LicensePlateComponent from '../components/LicensePlateComponent.vue'
import { mapGetters, mapActions } from 'vuex'
// import api from '@/services/api'

export default {
    name: 'MachinesView',
    components: {
        LicensePlateComponent
    },
    props: {

    },
    data() {
        return {
            loading: false,
            editedMachine: null,
            dialogEdit: false,
            editedMachine: {
                id: null,
                name: 'Máquina 1',
                description: 'Máquina 1',
                type: 'tractor',
                brand: 'John Deere',
                model: 'Modelo 1',
                ownershipStatus: 'Owned',
                purchaseDate: '2020-01-01',
                purchaseCost: 100000,
                licensePlate: 'ABC123',
                lastInspectionDate: '2020-01-01',
                lastInsurance: '2020-01-01',
                registrationNumber: '123456',
                photo: 'https://picsum.photos/200/200',
            },
            ownershipStatuses: [
                {
                    name: 'Propio',
                    value: 'Owned',
                },
                {
                    name: 'Alquilado',
                    value: 'Rented',
                },
                {
                    name: 'Desconocido',
                    value: 'Unknown',
                },
            ],
            machineTypes: [
                {
                    name: 'Tractor',
                    value: 'tractor',
                },
                {
                    name: 'Cosechadora',
                    value: 'harvester',
                },
                {
                    name: 'Sembradora',
                    value: 'seeder',
                },
                {
                    name: 'Abonadora',
                    value: 'fertilizer',
                },
                {
                    name: 'Pulverizadora',
                    value: 'sprayer',
                },
                {
                    name: 'Remolque',
                    value: 'trailer',
                },
                {
                    name: 'Cisterna',
                    value: 'tank',
                },
                {
                    name: 'Otra',
                    value: 'other',
                }
            ],
            // machines: [
            //     {
            //         id: 1,
            //         name: 'Máquina 1',
            //         description: 'Máquina 1',
            //         type: 'tractor',
            //         brand: 'Kubota',
            //         model: 'Modelo 1',
            //         ownershipStatus: 'Owned',
            //         purchaseDate: '2020-01-01',
            //         purchaseCost: 100000,
            //         licensePlate: 'ABC123',
            //         lastInspectionDate: '2020-01-01',
            //         lastInsurance: '2020-01-01',
            //         registrationNumber: '123456',
            //         photo: 'https://picsum.photos/200/200',
            //     },
            //     {
            //         id: 2,
            //         name: 'Máquina 2',
            //         description: 'Máquina 2',
            //         type: 'tractor',
            //         brand: 'John Deere',
            //         model: 'Modelo 1',
            //         ownershipStatus: 'Unknown',
            //         purchaseDate: '2020-01-01',
            //         purchaseCost: 100000,
            //         licensePlate: 'ABC123',
            //         lastInspectionDate: '2020-01-01',
            //         lastInsurance: '2020-01-01',
            //         registrationNumber: '123456',
            //         photo: null,
            //     },
            //     {
            //         id: 3,
            //         name: 'Máquina 3',
            //         description: 'Máquina 3',
            //         type: 'tractor',
            //         brand: 'New Holland',
            //         model: 'Modelo 1',
            //         ownershipStatus: 'Owned',
            //         purchaseDate: '2020-01-01',
            //         purchaseCost: 100000,
            //         licensePlate: 'ABC123',
            //         lastInspectionDate: '2020-01-01',
            //         lastInsurance: '2020-01-01',
            //         registrationNumber: '123456',
            //         photo: null,
            //     },
            //     {
            //         id: 4,
            //         name: 'Máquina 4',
            //         description: 'Máquina 4',
            //         type: 'tractor',
            //         brand: 'John Deere',
            //         model: 'Modelo 1',
            //         ownershipStatus: 'Rented',
            //         purchaseDate: '2020-01-01',
            //         purchaseCost: 100000,
            //         licensePlate: 'ABC123',
            //         lastInspectionDate: '2020-01-01',
            //         lastInsurance: '2020-01-01',
            //         registrationNumber: '123456',
            //         photo: 'https://picsum.photos/202/202',
            //     },
            //     {
            //         id: 5,
            //         name: 'Máquina 5',
            //         description: 'Máquina 5',
            //         type: 'tractor',
            //         brand: 'New Holland',
            //         model: 'Modelo 1',
            //         ownershipStatus: 'Rented',
            //         purchaseDate: '2020-01-01',
            //         purchaseCost: 100000,
            //         licensePlate: 'ABC123',
            //         lastInspectionDate: '2020-01-01',
            //         lastInsurance: '2020-01-01',
            //         registrationNumber: '123456',
            //         photo: 'https://picsum.photos/201/201',
            //     },
            // ],
        };
    },
    computed: {
        ...mapGetters([
            'machines',
        ]),
    },
    watch: {

    },
    methods: {
        ...mapActions([
            'getMachines',
            'insertMachine',
            'updateMachine',
            'deleteMachine',
        ]),
        close() {
            this.dialogEdit = false
        },
        edit(machine) {
            if (machine) {
                this.editedMachine = Object.assign({}, machine)
            } else {
                this.editedMachine = {
                    id: null,
                    name: '',
                    description: '',
                    type: 'tractor',
                    brand: '',
                    model: '',
                    ownershipStatus: 'Owned',
                    purchaseDate: '',
                    purchaseCost: '',
                    licensePlate: '',
                    lastInspectionDate: '',
                    lastInsurance: '',
                    registrationNumber: '',
                    photo: 'https://picsum.photos/200/200',
                }
            }
            this.dialogEdit = true
        },
        save() {
            if (this.editedMachine.id) {
                this.updateMachine(this.editedMachine)
            } else {
                this.insertMachine(this.editedMachine)
            }
            this.close()
        },
        remove(id) {
            this.deleteMachine(id)
        }
    },
    created() {
        this.getMachines()
    },
};
</script>