import api from '@/services/api'

export default {
    namespaced: false,
    state: {
        crops: [],
    },
    getters: {
        crops: state => state.crops,
    },
    mutations: {
        setCrops(state, crops) {
            state.crops = crops
        },
    },
    actions: {
        async getCrops({ commit, dispatch }) {
            const response = await api.get(`/crops`)
            if (response.status === 200) {
                commit('setCrops', response.data.crops)
            } else if (response.status === 401 || response.status === 403) {
                dispatch('goToLogin')
            }
        },
    },
};