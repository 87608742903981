<template>
    <v-container fluid class="fill-height blue-grey darken-4 px-3 py-0">
        <v-container fluid class="pa-0 align-self-start">
            <v-app-bar color="blue-grey darken-4" dark app fixed flat>
                <v-app-bar-nav-icon>
                    <template v-slot:default>
                        <v-img :src="require('@/assets/logo_leaf.png')" max-height="35" contain></v-img>
                    </template>
                </v-app-bar-nav-icon>
                <v-toolbar-title>Cuadernos</v-toolbar-title>
                
                <v-spacer></v-spacer>
                <!-- <v-btn class="px-6 py-5 rounded-lg teal darken-2 text-none teal--text text--lighten-4" flat>
                    <v-icon left small>mdi-download</v-icon>
                    <span class="ml-2">Descargar</span>
                </v-btn> -->
                <v-btn class="ml-2 px-6 py-5 rounded-lg teal darken-2 text-none teal--text text--lighten-4" text @click="form()">
                    <v-icon left small>mdi-plus</v-icon>
                    <span class="ml-2">Nuevo cuaderno</span>
                </v-btn>
            </v-app-bar>
            <v-row class="grey darken-4">
                <v-col cols="12" class="mt-3 d-flex justify-center">
                    <div style="width:900px;">
                        <v-text-field
                        v-model="filters.search"
                        label="Buscar cuaderno"
                        class="rounded-lg mb-3"
                        clearable
                        outlined
                        hide-details
                        rounded
                        dark
                        append-icon="mdi-magnify"
                        >
                        </v-text-field>
                    </div>
                </v-col>
                <v-col cols="12" class="px-0 py-3 overflow-y-auto d-flex justify-center" style="height: calc(100vh - 53px);">
                    <v-overlay :value="loading" :absolute="true">
                        <v-progress-circular
                            indeterminate
                            size="64"
                        ></v-progress-circular>
                    </v-overlay>
                    <v-simple-table
                    dark
                    style="width: 1400px"
                    >
                    <template v-slot:default>
                        <thead class="teal darken-3">
                            <tr>
                                <th class="text-left">
                                    Fecha de creación
                                </th>
                                <th class="text-left">
                                    Fecha de inicio
                                </th>
                                <th class="text-left">
                                    Fecha de fin
                                </th>
                                <th class="text-left">
                                    Nombre
                                </th>
                                <th class="text-left">
                                    Finca
                                </th>
                                <!-- <th class="text-left">
                                    Cultivos
                                </th> -->
                                <th class="text-left">
                                    Campañas
                                </th>
                                <th class="text-left">
                                    Tipo
                                </th>
                                <th>
                                    Acciones
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                            v-for="notebook in filteredNotebooks"
                            :key="notebook.id"
                            >
                                <td>{{ notebook.createdAt }}</td>
                                <td>{{ notebook.startDate }}</td>
                                <td>{{ notebook.endDate }}</td>
                                <td>{{ notebook.name }}</td>
                                <td>
                                    <v-chip v-for="farm in notebook.farms" :key="farm.id" class="mr-2 teal darken-1 rounded-lg" small label>
                                        {{ farm?.name }}
                                    </v-chip>
                                </td>
                                <!-- <td>
                                    <v-chip v-for="seed in notebook.seeds" :key="seed.id" class="mr-2 teal darken-1 rounded-lg" small label>
                                        {{ seed.name }}
                                    </v-chip>
                                </td> -->
                                <td>
                                    <v-chip v-for="campaign in notebook.campaigns" :key="campaign.id" class="ma-1 teal darken-1 rounded-lg" small label>
                                        {{ seeds.find(s => s.id === campaign.seedId)?.name }} [{{ campaign?.name }}]
                                    </v-chip>
                                </td>
                                <td>{{ notebookTypes.find(t => t.value === notebook.type)?.name }}</td>
                                <td>
                                    <v-btn icon small class="mr-2">
                                        <v-icon small color="grey lighten-2" @click="download(notebook)">mdi-download</v-icon>
                                    </v-btn>
                                    <v-btn icon small>
                                        <v-icon small color="grey lighten-2" @click="remove(notebook.id)">mdi-delete</v-icon>
                                    </v-btn>
                                </td>
                            </tr>
                        </tbody>
                        </template>
                    </v-simple-table>
                </v-col>
            </v-row>
            <v-dialog 
            v-model="dialogForm"
            max-width="600px"
            scrollable
            >
                <v-card dark>
                    <v-card-title>
                        <span class="headline">Nuevo cuaderno</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12">
                                    <v-text-field
                                        v-model="editedNotebook.name"
                                        label="Nombre completo"
                                        required
                                        filled 
                                        hide-details 
                                        rounded 
                                        dense 
                                        class="mr-3 rounded-lg blue-grey darken-4" 
                                        dark
                                        append-icon="mdi-form-textbox"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-menu
                                        ref="startDate"
                                        v-model="menus.startDate"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                        dark
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="editedNotebook.startDate"
                                            label="Fecha inicio"
                                            v-bind="attrs"
                                            v-on="on"
                                            readonly
                                            filled 
                                            hide-details 
                                            rounded 
                                            dense 
                                            class="mr-3 rounded-lg blue-grey darken-4" 
                                            :menu-props="{ offsetY: true, maxHeight: '300px' }"
                                            append-icon="mdi-calendar-month"
                                        ></v-text-field>
                                        </template>
                                        <v-date-picker
                                        v-model="editedNotebook.startDate"
                                        no-title
                                        scrollable
                                        color="teal lighten-2"
                                        locale="es"
                                        >
                                        </v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols="12">
                                    <v-menu
                                        ref="endDate"
                                        v-model="menus.endDate"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                        dark
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="editedNotebook.endDate"
                                            label="Fecha fin"
                                            v-bind="attrs"
                                            v-on="on"
                                            readonly
                                            filled 
                                            hide-details 
                                            rounded 
                                            dense 
                                            class="mr-3 rounded-lg blue-grey darken-4" 
                                            :menu-props="{ offsetY: true, maxHeight: '300px' }"
                                            append-icon="mdi-calendar-month"
                                        ></v-text-field>
                                        </template>
                                        <v-date-picker
                                        v-model="editedNotebook.endDate"
                                        no-title
                                        scrollable
                                        color="teal lighten-2"
                                        locale="es"
                                        >
                                        </v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols="12">
                                    <v-select
                                        v-model="editedNotebook.type"
                                        :items="notebookTypes"
                                        item-text="name"
                                        item-value="value"
                                        label="Tipo"
                                        required
                                        filled 
                                        hide-details 
                                        rounded 
                                        dense 
                                        class="mr-3 rounded-lg blue-grey darken-4" 
                                        dark
                                        append-icon="mdi-format-list-bulleted-type"
                                        disabled
                                    ></v-select>
                                </v-col>
                                <v-col cols="12">
                                    <v-autocomplete
                                        v-model="editedNotebook.farms"
                                        :items="farms"
                                        item-text="name"
                                        item-value="id"
                                        label="Finca"
                                        required
                                        filled 
                                        hide-details 
                                        rounded 
                                        dense 
                                        class="mr-3 rounded-lg blue-grey darken-4" 
                                        dark
                                        append-icon="mdi-format-list-bulleted-type"
                                    ></v-autocomplete>
                                </v-col>
                                <!-- <v-col cols="12">
                                    <v-autocomplete
                                        v-model="editedNotebook.seeds"
                                        :items="seeds"
                                        item-text="name"
                                        item-value="id"
                                        label="Cultivos"
                                        multiple
                                        required
                                        filled 
                                        hide-details 
                                        rounded 
                                        dense 
                                        class="mr-3 rounded-lg blue-grey darken-4" 
                                        dark
                                        append-icon="mdi-format-list-bulleted-type"
                                    ></v-autocomplete>
                                </v-col> -->
                                <v-col cols="12">
                                    <v-autocomplete
                                        v-model="editedNotebook.campaigns"
                                        :items="filteredCampaigns"
                                        item-text="name"
                                        item-value="id"
                                        label="Campañas"
                                        multiple
                                        required
                                        filled 
                                        hide-details 
                                        rounded 
                                        dense 
                                        class="mr-3 rounded-lg blue-grey darken-4" 
                                        dark
                                        append-icon="mdi-format-list-bulleted-type"
                                    >
                                        <template v-slot:item="{ item, index }">
                                            
                                                <span>{{ seeds.find(s => s.id === item.seedId)?.name }}
                                                    <v-chip
                                                        label
                                                        color="teal darken-2"
                                                        dark
                                                        small
                                                        class="rounded-lg ml-2"
                                                    >
                                                        {{ item?.name }}
                                                    </v-chip>
                                                </span>
                                            <!-- <span
                                                v-if="index === 1"
                                                class="grey--text caption"
                                            >(+{{ editedNotebook.campaigns.length - 1 }} others)</span> -->
                                        </template>
                                        <template
                                            v-slot:selection="{ item, index }"
                                        >
                                            <span>{{ seeds.find(s => s.id === item.seedId)?.name }}
                                                <v-chip
                                                    label
                                                    color="teal darken-2"
                                                    dark
                                                    small
                                                    class="rounded-lg ml-2"
                                                >
                                                    {{ item?.name }})
                                                </v-chip>
                                            </span>
                                            <!-- <span
                                                v-if="index === 1"
                                                class="grey--text caption"
                                            >(+{{ editedNotebook.campaigns.length - 1 }} others)</span> -->
                                        </template>
                                    </v-autocomplete>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions class="d-flex">
                        <v-btn
                            class="flex-grow-1 pa-6 elevation-0 rounded-lg"
                            @click="close"
                        >
                            Cerrar
                        </v-btn>
                        <v-btn
                            class="flex-grow-1 pa-6 elevation-0 rounded-lg teal"
                            @click="save"
                        >
                            Guardar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-container>
    </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
// import api from '@/services/api'


export default {
    name: 'NotebooksView',
    components: {
    },
    props: {

    },
    data() {
        return {
            loading: false,
            dialogForm: false,
            menus: {
                startDate: false,
                endDate: false,
            },
            filters: {
                search: '',
            },
            editedNotebook: {
                id: null,
                startDate: '',
                endDate: '',
                name: '',
                type: 'Exploitation',
                seeds: [],
                farms: [],
                campaigns: [],
            },        
            notebookTypes: [
                { 
                    name: 'Explotación', 
                    value: 'Exploitation' 
                },
                {
                    name: 'Fertilización',
                    value: 'Fertilize',
                },
            ],
        };
    },
    computed: {
        ...mapGetters([
            'notebooks',
            'seeds',
            'farms',
            'campaigns',
        ]),
        filteredNotebooks() {
            return this.notebooks.filter(notebook => {
                return notebook.name.toLowerCase().includes(this.filters.search.toLowerCase())
            })
        },
        filteredCampaigns() {
            return this.campaigns.filter(campaign => {
                return this.farms.filter(f => f.id === this.editedNotebook.farms).map(f => f.sectors).flat().map(s => s.id).includes(campaign.sectorId)
            })
        },
    },
    watch: {

    },
    methods: {
        ...mapActions([
            'getNotebooks',
            'insertNotebook',
            'updateNotebook',
            'deleteNotebook',
            'getSeeds',
            'getFarms',
            'getCampaigns',
        ]),
        close() {
            this.dialogForm = false
        },
        form(notebook) {
            if (notebook) {
                this.editedNotebook = Object.assign({}, notebook)
            } else {
                const startDate = new Date()
                const endDate = new Date(startDate);
                endDate.setFullYear(startDate.getFullYear() + 1);
                this.editedNotebook = {
                    id: null,
                    startDate: startDate.toISOString().substring(0, 10),
                    endDate: endDate.toISOString().substring(0, 10),
                    name: '',
                    type: 'Exploitation',
                    seeds: [],
                    farms: [],
                    campaigns: [],
                }
            }
            this.dialogForm = true
        },
        download(notebook) {
            // if (notebook) {
            //     this.editedNotebook = Object.assign({}, notebook)
            // } else {
            //     this.editedNotebook = {
            //         id: null,
            //         name: '',
            //         type: 'Fertilize',
            //     }
            // }
            // this.dialogForm = true
            window.open(`http://localhost:3050/excel/${notebook.farms[0].id}`, "_blank");
        },
        save() {
            if (this.editedNotebook.id) {
                this.updateNotebook(this.editedNotebook)
            } else {
                this.insertNotebook(this.editedNotebook)
            }
            this.close()
        },
        remove(id) {
            this.deleteNotebook(id)
        }
    },
    created() {
        this.getNotebooks()
        if (!this.seeds.length) {
            this.getSeeds()
        }
        if (!this.farms.length) {
            this.getFarms()
        }
        if (!this.campaigns.length) {
            this.getCampaigns()
        }
    },
    mounted() {

    },
};
</script>