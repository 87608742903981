<template>
    <v-container fluid class="fill-height blue-grey darken-4 px-3 py-0">
        <v-row class="grey darken-4">
            <v-col cols="12" class="mt-3 d-flex justify-center">
                <div style="width:900px;">
                    <v-text-field
                    v-model="filters.search"
                    label="Buscar cuadrilla"
                    class="rounded-lg mb-3"
                    clearable
                    outlined
                    hide-details
                    rounded
                    dark
                    append-icon="mdi-magnify"
                    >
                    </v-text-field>
                </div>
            </v-col>
            <v-col cols="12" class="px-0 py-3 overflow-y-auto d-flex justify-center" style="height: calc(100vh - 53px);">
                <v-overlay :value="loading" :absolute="true">
                    <v-progress-circular
                        indeterminate
                        size="64"
                    ></v-progress-circular>
                </v-overlay>
                <v-simple-table
                dark
                style="width: 900px"
                >
                <template v-slot:default>
                    <thead class="teal darken-3">
                        <tr class="">
                            <th class="text-left">
                                Nombre
                            </th>
                            <th class="text-left">
                                Trabajadores
                            </th>
                            <th>
                                Acciones
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                        v-for="crew in filteredCrew"
                        :key="crew.id"
                        >
                            <td>{{ crew.name }}</td>
                            <td>
                                <v-chip
                                    v-for="item in crew.staff"
                                    :key="item"
                                    class="mr-2 rounded-lg"
                                    :color="randomColor()"
                                    small
                                    dark
                                    label
                                >
                                    {{ staff.find(s => s.id === item).name }}
                                </v-chip>
                            </td>
                            <td width="100px">
                                <v-btn icon small class="mr-2">
                                    <v-icon small color="grey lighten-2" @click="edit(crew)">mdi-pencil</v-icon>
                                </v-btn>
                                <v-btn icon small>
                                    <v-icon small color="grey lighten-2" @click="remove(crew.id)">mdi-delete</v-icon>
                                </v-btn>
                            </td>
                        </tr>
                    </tbody>
                    </template>
                </v-simple-table>
            </v-col>
        </v-row>
        <v-dialog 
        v-model="dialogEdit"
        max-width="900px"
        scrollable
        >
            <v-card dark>
                <v-card-title>
                    <!-- TODO: Editar/Nuevo -->
                    <span class="headline">Editar cuadrilla</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field
                                    v-model="editedCrew.name"
                                    label="Nombre completo"
                                    required
                                    class="rounded-lg mb-3"
                                    outlined
                                    hide-details
                                    rounded
                                    dark
                                    append-icon="mdi-account"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-autocomplete
                                    v-model="editedCrew.staff"
                                    :items="staff"
                                    item-text="name"
                                    item-value="id"
                                    label="Trabajadores"
                                    required
                                    class="rounded-lg mb-3"
                                    outlined
                                    multiple
                                    hide-details
                                    rounded
                                    dark
                                    append-icon="mdi-account-multiple"
                                >
                                    <template v-slot:selection="{ item, index }">
                                        <v-chip
                                            label
                                            :color="randomColor()"
                                        >
                                            <span>{{ item.name }}</span>
                                        </v-chip>
                                    </template>
                                </v-autocomplete>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions class="d-flex">
                    <v-btn
                        class="flex-grow-1 pa-6 elevation-0 rounded-lg"
                        @click="close"
                    >
                        Cerrar
                    </v-btn>
                    <v-btn
                        class="flex-grow-1 pa-6 elevation-0 rounded-lg teal"
                        @click="save"
                    >
                        Guardar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
// import api from '@/services/api'


export default {
    name: 'CrewView',
    data() {
        return {
            loading: false,
            dialogEdit: false,
            filters: {
                search: '',
            },
            editedCrew: {
                id: null,
                name: '',
                staff: [],
            },        
            // crew: [
            //     {
            //         id: 1,
            //         name: 'Cuadrilla 1',
            //         staff: [1, 2],
            //     },
            //     {
            //         id: 2,
            //         name: 'Cuadrilla 2',
            //         staff: [3, 4],
            //     },
                    
            // ],
            // staff: [
            //     {
            //         id: 1,
            //         name: 'Juan Panamera',
            //         dniOrNif: '12345678A',
            //         staffType: 'Owner',
            //         qualificationLevel: 'Qualified',
            //         drivingLicenseNumber: '12345678',
            //         ropoNumber: '12345678',
            //         isAdvisor: true,
            //         email: 'jpanamera@gmail.com',
            //         phone: '123456789',
            //         address: 'Calle Falsa 123',
            //         city: 'Madrid',
            //         postalCode: '28000',
            //         province: 'Madrid',
            //         country: 'España',
            //         comments: 'Comentarios de prueba',
            //         photo: 'https://picsum.photos/200/200'
            //     },
            //     {
            //         id: 2,
            //         name: 'Paco Panamera',
            //         dniOrNif: '87654321A',
            //         staffType: 'Employee',
            //         qualificationLevel: 'Qualified',
            //         drivingLicenseNumber: '87654321',
            //         ropoNumber: '87654321',
            //         isAdvisor: false,
            //         email: 'ppanamera@gmail.com',
            //         phone: '987654321',
            //         address: 'Calle Falsa 321',
            //         city: 'Madrid',
            //         postalCode: '28000',
            //         province: 'Madrid',
            //         country: 'España',
            //         comments: 'Comentarios de prueba',
            //         photo: 'https://picsum.photos/200/200'
            //     },
            //     {
            //         id: 3,
            //         name: 'Pepe Panamera',
            //         dniOrNif: '12345678B',
            //         staffType: 'Employee',
            //         qualificationLevel: 'Qualified',
            //         drivingLicenseNumber: '12345678',
            //         ropoNumber: '12345678',
            //         isAdvisor: false,
            //         email: 'pepepanamera@gmail.com',
            //         phone: '123456789',
            //         address: 'Calle Falsa 123',
            //         city: 'Madrid',
            //         postalCode: '28000',
            //         province: 'Madrid',
            //         country: 'España',
            //         comments: 'Comentarios de prueba',
            //         photo: 'https://picsum.photos/200/200'
            //     },
            //     {
            //         id: 4,
            //         name: 'Ángel Panamera',
            //         dniOrNif: '87654321B',
            //         staffType: 'Employee',
            //         qualificationLevel: 'Qualified',
            //         drivingLicenseNumber: '87654321',
            //         ropoNumber: '87654321',
            //         isAdvisor: true,
            //         email: 'apanamera@gmail.com',
            //         phone: '987654321',
            //         address: 'Calle Falsa 321',
            //         city: 'Madrid',
            //         postalCode: '28000',
            //         province: 'Madrid',
            //         country: 'España',
            //         comments: 'Comentarios de prueba',
            //         photo: 'https://picsum.photos/200/200'
            //     },
            // ],
        };
    },
    computed: {
        ...mapGetters([
            'crew',
            'staff',
        ]),
        filteredCrew() {
            return this.crew.filter(crew => {
                return crew.name.toLowerCase().includes(this.filters.search.toLowerCase())
            })
        },
    },
    watch: {

    },
    methods: {
        ...mapActions([
            'getCrew',
            'insertCrew',
            'updateCrew',
            'deleteCrew',
            'getStaff',
        ]),
        randomColor() {
            const colors = ['red', 'green', 'blue', 'yellow', 'purple', 'pink', 'orange', 'cyan', 'teal', 'lime', 'amber', 'brown', 'grey', 'blue-grey']
            return colors[Math.floor(Math.random() * colors.length)]
        },
        close() {
            this.dialogEdit = false
        },
        edit(crew) {
            if (crew) {
                this.editedCrew = Object.assign({}, crew)
            } else {
                this.editedCrew = {
                    id: null,
                    name: '',
                    staff: [],
                }
            }
            this.dialogEdit = true
        },
        save() {
            if (this.editedCrew.id) {
                this.updateCrew(this.editedCrew)
            } else {
                this.insertCrew(this.editedCrew)
            }
            this.close()
        },
        remove(id) {
            this.deleteCrew(id)
        }
    },
    created() {
        // TODO: borrar para producción
        this.staff = this.staff.map(staff => {
            staff.photo = `https://api.dicebear.com/7.x/adventurer/svg?seed=${staff.id}`
            staff.initials = staff.name.split(' ').map(n => n[0].toUpperCase()).join('')
            return staff
        })
        this.getStaff()
        this.getCrew()
    },
    mounted() {

    },
};
</script>