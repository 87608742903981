import api from '@/services/api'

export default {
    namespaced: false,
    state: {
        machines: [],
    },
    getters: {
        machines: state => state.machines,
    },
    mutations: {
        setMachines(state, machines) {
            state.machines = machines
        },
    },
    actions: {
        async getMachines({ commit, dispatch }) {
            const response = await api.get(`/machines`)
            if (response.status === 200) {
                commit('setMachines', response.data.machines)
            } else if (response.status === 401 || response.status === 403) {
                dispatch('goToLogin')
            }
        },
        
        async insertMachine({ commit, dispatch }, machine) {
            const response = await api.post(`/machines`, { machine })
            if (response.status === 201) {
                dispatch('getMachines')
            } else if (response.status === 401 || response.status === 403) {
                // dispatch('goToLogin')
            }
        },
        async updateMachine({ commit, dispatch }, machine) {
            const response = await api.put(`/machines/${machine.id}`, { machine })
            if (response.status === 200) {
                dispatch('getMachines')
            } else if (response.status === 401 || response.status === 403) {
                // dispatch('goToLogin')
            }
        },
        async deleteMachine({ commit, dispatch }, machineId) {
            const response = await api.delete(`/machines/${machineId}`)
            if (response.status === 204) {
                dispatch('getMachines')
            } else if (response.status === 401 || response.status === 403) {
                // dispatch('goToLogin')
            }
        },
    },
};