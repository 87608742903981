import api from '@/services/api'

export default {
    namespaced: false,
    state: {
        fertilizationMethods: [],
    },
    getters: {
        fertilizationMethods: state => state.fertilizationMethods,
    },
    mutations: {
        setFertilizationMethods(state, fertilizationMethods) {
            state.fertilizationMethods = fertilizationMethods
        },
    },
    actions: {
        async getFertilizationMethods({ commit, dispatch }) {
            const response = await api.get(`/fertilizationMethods`)
            if (response.status === 200) {
                commit('setFertilizationMethods', response.data.fertilizationMethods)
            } else if (response.status === 401 || response.status === 403) {
                dispatch('goToLogin')
            }
        },
    },
};