<template>
    <v-container fluid class="fill-height blue-grey darken-4 px-3 py-0">
        <v-container fluid class="pa-0 align-self-start">
            <v-app-bar color="blue-grey darken-4" dark app fixed flat>
                <v-app-bar-nav-icon>
                    <template v-slot:default>
                        <v-img :src="require('@/assets/logo_leaf.png')" max-height="35" contain></v-img>
                    </template>
                </v-app-bar-nav-icon>
                <v-toolbar-title>Trabajos</v-toolbar-title>
                
                <v-spacer></v-spacer>
                <!-- <v-btn class="px-6 py-5 rounded-lg teal darken-2 text-none teal--text text--lighten-4" flat>
                    <v-icon left small>mdi-download</v-icon>
                    <span class="ml-2">Descargar</span>
                </v-btn> -->
                <v-btn class="ml-2 px-6 py-5 rounded-lg teal darken-2 text-none teal--text text--lighten-4" text @click="edit()">
                    <v-icon left small>mdi-plus</v-icon>
                    <span class="ml-2">Nuevo trabajo</span>
                </v-btn>
            </v-app-bar>
            <v-row class="grey darken-4">
                <v-col cols="12" class="mt-3 d-flex justify-center">
                    <div style="width:900px;">
                        <v-text-field
                        v-model="filters.search"
                        label="Buscar trabajo"
                        class="rounded-lg mb-3"
                        clearable
                        outlined
                        hide-details
                        rounded
                        dark
                        append-icon="mdi-magnify"
                        >
                        </v-text-field>
                    </div>
                </v-col>
                <v-col cols="12" class="px-0 py-3 overflow-y-auto d-flex justify-center" style="height: calc(100vh - 53px);">
                    <v-overlay :value="loading" :absolute="true">
                        <v-progress-circular
                            indeterminate
                            size="64"
                        ></v-progress-circular>
                    </v-overlay>
                    <v-simple-table
                    dark
                    style="width: 900px"
                    >
                    <template v-slot:default>
                        <thead class="teal darken-3">
                            <tr>
                                <th class="text-left">
                                    Nombre
                                </th>
                                <th class="text-left">
                                    Tipo
                                </th>
                                <th>
                                    Acciones
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                            v-for="job in filteredJobs"
                            :key="job.id"
                            >
                                <td>{{ job.name }}</td>
                                <td>
                                    <v-chip label small dark class="rounded-lg" color="teal darken-1">
                                        <v-icon small left>{{ jobTypes.find(t => t.value === job.type).icon }}</v-icon>
                                        {{ jobTypes.find(t => t.value === job.type).name }}
                                    </v-chip>
                                    <!-- <v-icon small left>{{ jobTypes.find(t => t.value === job.type).icon }}</v-icon>
                                    {{ jobTypes.find(t => t.value === job.type).name }} -->
                                </td>
                                <td>
                                    <v-btn icon small class="mr-2">
                                        <v-icon small color="grey lighten-2" @click="edit(job)">mdi-pencil</v-icon>
                                    </v-btn>
                                    <v-btn icon small>
                                        <v-icon small color="grey lighten-2" @click="remove(job.id)">mdi-delete</v-icon>
                                    </v-btn>
                                </td>
                            </tr>
                        </tbody>
                        </template>
                    </v-simple-table>
                </v-col>
            </v-row>
            <v-dialog 
            v-model="dialogEdit"
            max-width="600px"
            scrollable
            >
                <v-card dark>
                    <v-card-title>
                        <!-- TODO: Editar/Nuevo -->
                        <span class="headline">Editar trabajo</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12">
                                    <v-text-field
                                        v-model="editedJob.name"
                                        label="Nombre completo"
                                        required
                                        class="rounded-lg mb-3"
                                        outlined
                                        hide-details
                                        rounded
                                        dark
                                        append-icon="mdi-form-textbox"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-select
                                        v-model="editedJob.type"
                                        :items="jobTypes"
                                        item-text="name"
                                        item-value="value"
                                        label="Tipo"
                                        required
                                        class="rounded-lg mb-3"
                                        outlined
                                        hide-details
                                        rounded
                                        dark
                                        append-icon="mdi-format-list-bulleted-type"
                                    ></v-select>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions class="d-flex">
                        <v-btn
                            class="flex-grow-1 pa-6 elevation-0 rounded-lg"
                            @click="close"
                        >
                            Cerrar
                        </v-btn>
                        <v-btn
                            class="flex-grow-1 pa-6 elevation-0 rounded-lg teal"
                            @click="save"
                        >
                            Guardar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-container>
    </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
// import api from '@/services/api'


export default {
    name: 'JobsView',
    components: {
    },
    props: {

    },
    data() {
        return {
            loading: false,
            editedJob: null,
            dialogEdit: false,
            filters: {
                search: '',
            },
            editedJob: {
                id: null,
                name: '',
                type: 'Fertilize',
            },
        };
    },
    computed: {
        ...mapGetters([
            'jobs',
            'jobTypes',
        ]),
        filteredJobs() {
            return this.jobs.filter(job => {
                return job.name.toLowerCase().includes(this.filters.search.toLowerCase())
            })
        },
    },
    watch: {

    },
    methods: {
        ...mapActions([
            'getJobs',
            'insertJob',
            'updateJob',
            'deleteJob',
        ]),
        close() {
            this.dialogEdit = false
        },
        edit(job) {
            if (job) {
                this.editedJob = Object.assign({}, job)
            } else {
                this.editedJob = {
                    id: null,
                    name: '',
                    type: 'Fertilize',
                }
            }
            this.dialogEdit = true
        },
        save() {
            if (this.editedJob.id) {
                this.updateJob(this.editedJob)
            } else {
                this.insertJob(this.editedJob)
            }
            this.close()
        },
        remove(id) {
            this.deleteJob(id)
        }
    },
    created() {
        this.getJobs()
    },
    mounted() {

    },
};
</script>