import api from '@/services/api'

export default {
    namespaced: false,
    state: {
        harvestPurposes: [],
    },
    getters: {
        harvestPurposes: state => state.harvestPurposes,
    },
    mutations: {
        setHarvestPurposes(state, harvestPurposes) {
            state.harvestPurposes = harvestPurposes
        },
    },
    actions: {
        async getHarvestPurposes({ commit, dispatch }) {
            const response = await api.get(`/harvestPurposes`)
            if (response.status === 200) {
                commit('setHarvestPurposes', response.data.harvestPurposes)
            } else if (response.status === 401 || response.status === 403) {
                dispatch('goToLogin')
            }
        },
    },
};