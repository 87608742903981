import api from '@/services/api'

export default {
    namespaced: false,
    state: {
        clients: [],
    },
    getters: {
        clients: state => state.clients,
    },
    mutations: {
        setClients(state, clients) {
            state.clients = clients
        },
    },
    actions: {
        async getClients({ commit, dispatch }) {
            const response = await api.get(`/clients`)
            if (response.status === 200) {
                commit('setClients', response.data.clients)
            } else if (response.status === 401 || response.status === 403) {
                dispatch('goToLogin')
            }
        },
        
        async insertClient({ commit, dispatch }, client) {
            const response = await api.post(`/clients`, { client })
            if (response.status === 201) {
                dispatch('getClients')
            } else if (response.status === 401 || response.status === 403) {
                // dispatch('goToLogin')
            }
        },
        async updateClient({ commit, dispatch }, client) {
            const response = await api.put(`/clients/${client.id}`, { client })
            if (response.status === 200) {
                dispatch('getClients')
            } else if (response.status === 401 || response.status === 403) {
                // dispatch('goToLogin')
            }
        },
        async deleteClient({ commit, dispatch }, clientId) {
            const response = await api.delete(`/clients/${clientId}`)
            if (response.status === 204) {
                dispatch('getClients')
            } else if (response.status === 401 || response.status === 403) {
                // dispatch('goToLogin')
            }
        },
    },
};