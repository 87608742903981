<template>
    <v-container fluid class="fill-height blue-grey darken-4 px-3 py-0">
        <v-row class="grey darken-4">
            <v-col cols="12" class="mt-3 d-flex justify-center">
                <div style="width:900px;">
                    <v-text-field
                    v-model="filters.search"
                    label="Buscar trabajador"
                    class="rounded-lg mb-3"
                    clearable
                    outlined
                    hide-details
                    rounded
                    dark
                    append-icon="mdi-magnify"
                    >
                    </v-text-field>
                </div>
            </v-col>
            <v-col cols="12" class="px-0 py-3 overflow-y-auto d-flex justify-center" style="height: calc(100vh - 53px);">
                <v-overlay :value="loading" :absolute="true">
                    <v-progress-circular
                        indeterminate
                        size="64"
                    ></v-progress-circular>
                </v-overlay>
                <v-simple-table
                dark
                style="width: 1200px"
                >
                <template v-slot:default>
                    <thead class="teal darken-3">
                        <tr class="">
                            <th class="text-left">
                                Foto
                            </th>
                            <th class="text-left">
                                Nombre
                            </th>
                            <th class="text-left">
                                DNI/NIF
                            </th>
                            <th class="text-left">
                                Tipo
                            </th>
                            <th class="text-left">
                                Nivel de cualificación
                            </th>
                            <!-- <th class="text-left">
                                Nº de carnet de conducir
                            </th>
                            <th class="text-left">
                                Nº de ROPA
                            </th> -->
                            <th class="text-left">
                                Asesor
                            </th>
                            <th class="text-left">
                                Email
                            </th>
                            <th class="text-left">
                                Teléfono
                            </th>
                            <!-- <th class="text-left">
                                Dirección
                            </th>
                            <th class="text-left">
                                Ciudad
                            </th>
                            <th class="text-left">
                                Código postal
                            </th>
                            <th class="text-left">
                                Provincia
                            </th>
                            <th class="text-left">
                                País
                            </th>
                            <th class="text-left">
                                Comentarios
                            </th> -->
                            <th>
                                Acciones
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                        v-for="staff in filteredStaff"
                        :key="staff.id"
                        >
                            <td>
                                <v-avatar size="40px" :color="`${randomColor()} lighten-2`" tile class="rounded-lg">
                                    {{ staff.initials  }}
                                    <!-- <img :src="staff.photo" alt="Avatar"> -->
                                </v-avatar>
                            </td>
                            <td>{{ staff.name }}</td>
                            <td>{{ staff.dniOrNif }}</td>
                            <td>{{ staffTypes.find(t => t.value === staff.staffType).name }}</td>
                            <td>{{ qualificationLevels.find(l => l.value === staff.qualificationLevel).name }}</td>
                            <!-- <td>{{ staff.drivingLicenseNumber }}</td> -->
                            <!-- <td>{{ staff.ropoNumber }}</td> -->
                            <td class="text-center">
                                <v-icon v-if="staff.isAdvisor" dense color="teal lighten-1">mdi-check-decagram</v-icon>
                            </td>
                            <td>{{ staff.email }}</td>
                            <td>{{ staff.phone }}</td>
                            <!-- <td>{{ staff.address }}</td>
                            <td>{{ staff.city }}</td>
                            <td>{{ staff.postalCode }}</td>
                            <td>{{ staff.province }}</td>
                            <td>{{ staff.country }}</td>
                            <td>{{ staff.comments }}</td> -->
                            <td>
                                <v-btn icon small class="mr-2">
                                    <v-icon small color="grey lighten-2" @click="edit(staff)">mdi-pencil</v-icon>
                                </v-btn>
                                <v-btn icon small>
                                    <v-icon small color="grey lighten-2" @click="remove(staff.id)">mdi-delete</v-icon>
                                </v-btn>
                            </td>
                        </tr>
                    </tbody>
                    </template>
                </v-simple-table>
            </v-col>
        </v-row>
        <v-dialog 
        v-model="dialogEdit"
        max-width="900px"
        scrollable
        >
            <v-card dark>
                <v-card-title>
                    <!-- TODO: Editar/Nuevo -->
                    <span class="headline">{{ editedStaff.id>0 ? 'Editar empleado' : 'Nuevo empleado' }}</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" md="6">
                                <v-text-field
                                    v-model="editedStaff.name"
                                    label="Nombre completo"
                                    required
                                    class="rounded-lg mb-3"
                                    outlined
                                    hide-details
                                    rounded
                                    dark
                                    append-icon="mdi-account-circle-outline"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-text-field
                                    v-model="editedStaff.dniOrNif"
                                    label="DNI/NIF"
                                    required
                                    class="rounded-lg mb-3"
                                    outlined
                                    hide-details
                                    rounded
                                    dark
                                    append-icon="mdi-account-badge-horizontal-outline"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-select
                                    v-model="editedStaff.staffType"
                                    :items="staffTypes"
                                    item-text="name"
                                    item-value="value"
                                    label="Tipo de empleado"
                                    required
                                    class="rounded-lg mb-3"
                                    outlined
                                    hide-details
                                    rounded
                                    dark
                                    append-icon="mdi-account-badge-horizontal-outline"
                                ></v-select>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-select
                                    v-model="editedStaff.qualificationLevel"
                                    :items="qualificationLevels"
                                    item-text="name"
                                    item-value="value"
                                    label="Nivel de cualificación"
                                    required
                                    class="rounded-lg mb-3"
                                    outlined
                                    hide-details
                                    rounded
                                    dark
                                    append-icon="mdi-account-badge-horizontal-outline"
                                ></v-select>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-text-field
                                    v-model="editedStaff.drivingLicenseNumber"
                                    label="Nº de carnet de conducir"
                                    required
                                    class="rounded-lg mb-3"
                                    outlined
                                    hide-details
                                    rounded
                                    dark
                                    append-icon="mdi-car"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-text-field
                                    v-model="editedStaff.ropoNumber"
                                    label="Nº de ROPA"
                                    required
                                    class="rounded-lg mb-3"
                                    outlined
                                    hide-details
                                    rounded
                                    dark
                                    append-icon="mdi-account-badge-horizontal-outline"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-checkbox
                                    v-model="editedStaff.isAdvisor"
                                    label="¿Es un asesor?"
                                    class="rounded-lg mb-3"
                                    dark
                                    on-icon="mdi-check-decagram"
                                    off-icon="mdi-decagram-outline"
                                    color="teal"
                                ></v-checkbox>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-text-field
                                    v-model="editedStaff.email"
                                    label="Email"
                                    required
                                    class="rounded-lg mb-3"
                                    outlined
                                    hide-details
                                    rounded
                                    dark
                                    append-icon="mdi-email"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-text-field
                                    v-model="editedStaff.phone"
                                    label="Teléfono"
                                    required
                                    class="rounded-lg mb-3"
                                    outlined
                                    hide-details
                                    rounded
                                    dark
                                    append-icon="mdi-phone"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-text-field
                                    v-model="editedStaff.address"
                                    label="Dirección"
                                    required
                                    class="rounded-lg mb-3"
                                    outlined
                                    hide-details
                                    rounded
                                    dark
                                    append-icon="mdi-map-marker"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-text-field
                                    v-model="editedStaff.city"
                                    label="Ciudad"
                                    required
                                    class="rounded-lg mb-3"
                                    outlined
                                    hide-details
                                    rounded
                                    dark
                                    append-icon="mdi-city"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-text-field
                                    v-model="editedStaff.postalCode"
                                    label="Código postal"
                                    required
                                    class="rounded-lg mb-3"
                                    outlined
                                    hide-details
                                    rounded
                                    dark
                                    append-icon="mdi-postage-stamp"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-text-field
                                    v-model="editedStaff.province"
                                    label="Provincia"
                                    required
                                    class="rounded-lg mb-3"
                                    outlined
                                    hide-details
                                    rounded
                                    dark
                                    append-icon="mdi-city-variant-outline"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-text-field
                                    v-model="editedStaff.country"
                                    label="País"
                                    required
                                    class="rounded-lg mb-3"
                                    outlined
                                    hide-details
                                    rounded
                                    dark
                                    append-icon="mdi-earth"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-textarea
                                    v-model="editedStaff.comments"
                                    label="Comentarios"
                                    required
                                    class="rounded-lg mb-3"
                                    outlined
                                    hide-details
                                    rounded
                                    dark
                                    append-icon="mdi-comment-text-outline"
                                ></v-textarea>
                            </v-col>
                            <v-col cols="12" md="6" class="d-flex justify-center align-center">
                                <v-badge bottom right overlap color="transparent">
                                    <v-avatar size="120px" :color="`${randomColor()} lighten-2`" tile class="rounded-xl" style="font-size: 35px;">
                                        <v-img v-if="editedStaff.uploadedPhoto" :src="imageUrl" alt="Avatar" class="grey lighten-2" style="width: 120px; height: 120px; object-fit: cover;" ></v-img>
                                        <span v-else>{{ editedStaff.initials }}</span>
                                    </v-avatar>
                                    <template v-slot:badge>
                                        <v-btn small class="ml-n5 mt-n5" color="teal" fab @click="onButtonClick">
                                            <v-icon color="grey lighten-2">mdi-camera</v-icon>
                                        </v-btn>
                                        <v-file-input
                                            v-model="editedStaff.uploadedPhoto"
                                            ref="fileInput"
                                            show-size
                                            accept="image/*"
                                            v-show="false"
                                        ></v-file-input>
                                    </template>
                                </v-badge>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions class="d-flex">
                    <v-btn
                        class="flex-grow-1 pa-6 elevation-0 rounded-lg"
                        @click="close"
                    >
                        Cerrar
                    </v-btn>
                    <v-btn
                        class="flex-grow-1 pa-6 elevation-0 rounded-lg teal"
                        @click="save"
                    >
                        Guardar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
// import api from '@/services/api'
import '@johnmmackey/weather-underground-icons/dist/wu-icons-style.min.css'


export default {
    name: 'StaffView',
    data() {
        return {
            loading: false,
            dialogEdit: false,
            filters: {
                search: '',
            },
            editedStaff: {
                id: null,
                name: '',
                dniOrNif: '',
                staffType: '',
                qualificationLevel: '',
                drivingLicenseNumber: '',
                ropoNumber: '',
                isAdvisor: false,
                email: '',
                phone: '',
                address: '',
                city: '',
                postalCode: '',
                province: '',
                country: '',
                comments: '',
                photo: '',
                uploadedPhoto: null,
            },        
            staffTypes: [
            // 'Owner', 'Employee', 'ExternalCompany', 'Unknown'
                { name: 'Propietario', value: 'Owner' },
                { name: 'Empleado', value: 'Employee' },
                { name: 'Empresa externa', value: 'ExternalCompany' },
                { name: 'Desconocido', value: 'Unknown' },
            ],
            qualificationLevels: [
            // 'Basic', 'Qualified', 'Exterminator', 'Pilot'
                {
                    name: 'Básico',
                    value: 'Basic',
                },
                {
                    name: 'Cualificado',
                    value: 'Qualified',
                },
                {
                    name: 'Fumigador',
                    value: 'Exterminator',
                },
                {
                    name: 'Piloto',
                    value: 'Pilot',
                },
            ],
            // staff: [
            //     {
            //         id: 1,
            //         name: 'Juan Panamera',
            //         dniOrNif: '12345678A',
            //         staffType: 'Owner',
            //         qualificationLevel: 'Qualified',
            //         drivingLicenseNumber: '12345678',
            //         ropoNumber: '12345678',
            //         isAdvisor: true,
            //         email: 'jpanamera@gmail.com',
            //         phone: '123456789',
            //         address: 'Calle Falsa 123',
            //         city: 'Madrid',
            //         postalCode: '28000',
            //         province: 'Madrid',
            //         country: 'España',
            //         comments: 'Comentarios de prueba',
            //         photo: 'https://picsum.photos/200/200'
            //     },
            //     {
            //         id: 2,
            //         name: 'Paco Panamera',
            //         dniOrNif: '87654321A',
            //         staffType: 'Employee',
            //         qualificationLevel: 'Qualified',
            //         drivingLicenseNumber: '87654321',
            //         ropoNumber: '87654321',
            //         isAdvisor: false,
            //         email: 'ppanamera@gmail.com',
            //         phone: '987654321',
            //         address: 'Calle Falsa 321',
            //         city: 'Madrid',
            //         postalCode: '28000',
            //         province: 'Madrid',
            //         country: 'España',
            //         comments: 'Comentarios de prueba',
            //         photo: 'https://picsum.photos/200/200'
            //     },
            //     {
            //         id: 3,
            //         name: 'Pepe Panamera',
            //         dniOrNif: '12345678B',
            //         staffType: 'Employee',
            //         qualificationLevel: 'Qualified',
            //         drivingLicenseNumber: '12345678',
            //         ropoNumber: '12345678',
            //         isAdvisor: false,
            //         email: 'pepepanamera@gmail.com',
            //         phone: '123456789',
            //         address: 'Calle Falsa 123',
            //         city: 'Madrid',
            //         postalCode: '28000',
            //         province: 'Madrid',
            //         country: 'España',
            //         comments: 'Comentarios de prueba',
            //         photo: 'https://picsum.photos/200/200'
            //     },
            //     {
            //         id: 4,
            //         name: 'Ángel Panamera',
            //         dniOrNif: '87654321B',
            //         staffType: 'Employee',
            //         qualificationLevel: 'Qualified',
            //         drivingLicenseNumber: '87654321',
            //         ropoNumber: '87654321',
            //         isAdvisor: true,
            //         email: 'apanamera@gmail.com',
            //         phone: '987654321',
            //         address: 'Calle Falsa 321',
            //         city: 'Madrid',
            //         postalCode: '28000',
            //         province: 'Madrid',
            //         country: 'España',
            //         comments: 'Comentarios de prueba',
            //         photo: 'https://picsum.photos/200/200'
            //     },
            // ],
        };
    },
    computed: {
        ...mapGetters([
            'staff',
        ]),
        filteredStaff() {
            return this.staff.filter(staff => {
                return staff.name.toLowerCase().includes(this.filters.search.toLowerCase())
            })
        },
        imageUrl() {
            if (!this.editedStaff.uploadedPhoto) {
                return null;
            }
            console.log('this.editedStaff.uploadedPhoto', this.editedStaff.uploadedPhoto)

            const file = this.editedStaff.uploadedPhoto;
            return URL.createObjectURL(file);
        },
        updloadedPhoto() {
            return this.editedStaff.uploadedPhoto
        }
    },
    watch: {
        imageUrl() {
            console.log('imageUrl', this.imageUrl)
        }
    },
    methods: {
        ...mapActions([
            'getStaff',
            'insertStaff',
            'updateStaff',
            'deleteStaff',
        ]),
        randomColor() {
            const colors = ['red', 'green', 'blue', 'yellow', 'purple', 'pink', 'orange', 'cyan', 'teal', 'lime', 'amber', 'brown', 'grey', 'blue-grey']
            return colors[Math.floor(Math.random() * colors.length)]
        },
        onButtonClick() {
            this.$refs.fileInput.$refs.input.click();
        },
        close() {
            this.dialogEdit = false
        },
        edit(staff) {
            if (staff) {
                this.editedStaff = Object.assign({}, staff)
            } else {
                this.editedStaff = {
                    id: null,
                    name: '',
                    dniOrNif: '',
                    staffType: '',
                    qualificationLevel: '',
                    drivingLicenseNumber: '',
                    ropoNumber: '',
                    isAdvisor: false,
                    email: '',
                    phone: '',
                    address: '',
                    city: '',
                    postalCode: '',
                    province: '',
                    country: '',
                    comments: '',
                    photo: '',
                    uploadedPhoto: null,
                }
            }
            this.dialogEdit = true
        },
        save() {
            if (this.editedStaff.id) {
                this.updateStaff(this.editedStaff)
            } else {
                this.insertStaff(this.editedStaff)
            }
            this.close()
        },
        remove(id) {
            this.deleteStaff(id)
        }
    },
    created() {
        // TODO: borrar para producción
        this.staff = this.staff.map(staff => {
            staff.photo = `https://api.dicebear.com/7.x/adventurer/svg?seed=${staff.id}`
            staff.initials = staff.name.split(' ').map(n => n[0].toUpperCase()).join('')
            return staff
        })
        this.getStaff()
    },
    mounted() {

    },
};
</script>