<template>
    <v-dialog
        v-model="dialog"
        :max-width="advancedOptions ? '900px' : '500px'"
        scrollable
    >
        <v-card
            class="mx-auto blue-grey darken-4 rounded-lg px-3 py-6"
            dark
            v-if="!advancedOptions"
        >
            <v-system-bar color="transparent" class="mb-3">
                <v-card-title>{{ farm ? 'Editar' : 'Añadir' }} finca</v-card-title>
                <v-spacer></v-spacer>
                <v-btn  class="mt-n4 pl-1" text plain icon @click="dialog = false">
                    <v-icon color="grey lighten-2" @click="close">mdi-close</v-icon>
                </v-btn>
            </v-system-bar>
            <v-card-text>
                <v-text-field
                    v-model="editedFarm.name"
                    label="Nombre de la finca"
                    outlined
                    hide-details
                    class="rounded-lg grey darken-4 mt-4"
                    rounded
                    dark
                    prepend-inner-icon="mdi-group"
                ></v-text-field>
                <v-switch
                    v-model="advancedOptions"
                    label="Opciones avanzadas"
                    class="mt-6"
                    color="teal lighten-2"
                    hide-details
                    dark
                ></v-switch>
            </v-card-text>
            <v-card-actions v-if="farm" class="d-flex justify-center">
                <v-tooltip
                top
                :color="farm.sectors.length > 0 ? '' : 'transparent'"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on">
                            <v-btn
                            class="flex-grow-1 pa-6 elevation-0 rounded-lg elevation-0"
                            color="red darken-3"
                            @click="remove"
                            :disabled="farm.sectors.length > 0"
                            text
                            >
                                Eliminar
                            </v-btn>
                        </div>
                    </template>

                    <span v-if="farm.sectors.length > 0">Debes eliminar primero todos los sectores asociados a esta finca</span>
                </v-tooltip>
            </v-card-actions>
            <v-card-actions class="d-flex">
                <v-btn
                    class="flex-grow-1 pa-6 elevation-0 rounded-lg elevation-0"
                    @click="close"
                >
                    Cerrar
                </v-btn>
                <v-btn
                    class="flex-grow-1 pa-6 elevation-0 rounded-lg teal elevation-0"
                    @click="save"
                >
                    Guardar
                </v-btn>
            </v-card-actions>
        </v-card>
        <v-card 
        class="mx-auto blue-grey darken-4 rounded-lg px-3 py-6"
        dark
        v-if="advancedOptions"
        >
            <v-system-bar color="transparent" class="mb-3">
                <v-card-title>{{ farm ? 'Editar' : 'Añadir' }} finca</v-card-title>
                <v-spacer></v-spacer>
                <v-btn  class="mt-n4 pl-1" text plain icon @click="dialog = false">
                    <v-icon color="grey lighten-2" @click="close">mdi-close</v-icon>
                </v-btn>
            </v-system-bar>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="12" class="pa-0 mt-4">
                            <v-subheader class="py-0 my-0">
                                Datos principales
                            </v-subheader> 
                            <v-divider class="mt-n2 mb-4"></v-divider>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                v-model="editedFarm.name"
                                label="Nombre o empresa"
                                required
                                class="rounded-lg mb-3"
                                outlined
                                hide-details
                                rounded
                                dark
                                append-icon="mdi-account-circle-outline"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                v-model="editedFarm.nif"
                                label="DNI/NIF"
                                required
                                class="rounded-lg mb-3"
                                outlined
                                hide-details
                                rounded
                                dark
                                append-icon="mdi-card-account-details-outline"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                v-model="editedFarm.companyType"
                                label="Tipo de empresa"
                                required
                                class="rounded-lg mb-3"
                                outlined
                                hide-details
                                rounded
                                dark
                                append-icon="mdi-domain"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                v-model="editedFarm.email"
                                label="Email"
                                required
                                class="rounded-lg mb-3"
                                outlined
                                hide-details
                                rounded
                                dark
                                append-icon="mdi-email"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                v-model="editedFarm.phone"
                                label="Teléfono"
                                required
                                class="rounded-lg mb-3"
                                outlined
                                hide-details
                                rounded
                                dark
                                append-icon="mdi-phone"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                v-model="editedFarm.address"
                                label="Dirección"
                                required
                                class="rounded-lg mb-3"
                                outlined
                                hide-details
                                rounded
                                dark
                                append-icon="mdi-map-marker"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                v-model="editedFarm.city"
                                label="Ciudad"
                                required
                                class="rounded-lg mb-3"
                                outlined
                                hide-details
                                rounded
                                dark
                                append-icon="mdi-city"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                v-model="editedFarm.postalCode"
                                label="Código postal"
                                required
                                class="rounded-lg mb-3"
                                outlined
                                hide-details
                                rounded
                                dark
                                append-icon="mdi-postage-stamp"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                v-model="editedFarm.province"
                                label="Provincia"
                                required
                                class="rounded-lg mb-3"
                                outlined
                                hide-details
                                rounded
                                dark
                                append-icon="mdi-city-variant-outline"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                v-model="editedFarm.country"
                                label="País"
                                required
                                class="rounded-lg mb-3"
                                outlined
                                hide-details
                                rounded
                                dark
                                append-icon="mdi-earth"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-badge bottom right overlap color="transparent">
                                <template v-slot:badge>
                                    <v-btn small class="ml-n5 mt-n5" color="teal" fab @click="onButtonClick">
                                        <v-icon color="grey lighten-2">mdi-camera</v-icon>
                                    </v-btn>
                                    <v-file-input
                                        v-model="editedFarm.picture"
                                        ref="fileInput"
                                        show-size
                                        accept="image/*"
                                        v-show="false"
                                    ></v-file-input>
                                </template>
                                <v-avatar size="120px" :color="`grey lighten-2`" tile class="rounded-xl" style="font-size: 35px;">
                                    <v-img v-if="editedFarm.picture" :src="imageUrl" alt="Avatar" class="grey lighten-2" style="width: 120px; height: 120px; object-fit: cover;" ></v-img>
                                    <span v-else>{{ editedFarm.initials }}</span>
                                </v-avatar>
                            </v-badge>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" class="pa-0 mt-4">
                            <v-subheader class="py-0 my-0">
                                Número de registro
                            </v-subheader> 
                            <v-divider class="mt-n2 mb-4"></v-divider>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                v-model="editedFarm.nationalRegistry"
                                label="Nº de registro nacional"
                                class="rounded-lg mb-3"
                                outlined
                                hide-details
                                rounded
                                dark
                                append-icon="mdi-badge-account-horizontal-outline"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                v-model="editedFarm.autonomicRegistry"
                                label="Nº de registro autonómico"
                                class="rounded-lg mb-3"
                                outlined
                                hide-details
                                rounded
                                dark
                                append-icon="mdi-badge-account-outline"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" class="pa-0 mt-4">
                            <v-subheader class="py-0 my-0">
                                Titular o representante
                            </v-subheader> 
                            <v-divider class="mt-n2 mb-4"></v-divider>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                v-model="editedFarm.representative_owner.name"
                                label="Nombre o empresa"
                                class="rounded-lg mb-3"
                                outlined
                                hide-details
                                rounded
                                dark
                                append-icon="mdi-account-circle-outline"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                v-model="editedFarm.representative_owner.nif"
                                label="DNI/NIF"
                                class="rounded-lg mb-3"
                                outlined
                                hide-details
                                rounded
                                dark
                                append-icon="mdi-card-account-details-outline"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                v-model="editedFarm.representative_owner.representationType"
                                label="Tipo de representación"
                                class="rounded-lg mb-3"
                                outlined
                                hide-details
                                rounded
                                dark
                                append-icon="mdi-police-badge-outline"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                v-model="editedFarm.representative_owner.email"
                                label="Email"
                                class="rounded-lg mb-3"
                                outlined
                                hide-details
                                rounded
                                dark
                                append-icon="mdi-email"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                v-model="editedFarm.representative_owner.phone"
                                label="Teléfono"
                                class="rounded-lg mb-3"
                                outlined
                                hide-details
                                rounded
                                dark
                                append-icon="mdi-phone"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                v-model="editedFarm.representative_owner.address"
                                label="Dirección"
                                class="rounded-lg mb-3"
                                outlined
                                hide-details
                                rounded
                                dark
                                append-icon="mdi-map-marker"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                v-model="editedFarm.representative_owner.city"
                                label="Ciudad"
                                class="rounded-lg mb-3"
                                outlined
                                hide-details
                                rounded
                                dark
                                append-icon="mdi-city"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                v-model="editedFarm.representative_owner.postalCode"
                                label="Código postal"
                                class="rounded-lg mb-3"
                                outlined
                                hide-details
                                rounded
                                dark
                                append-icon="mdi-postage-stamp"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                v-model="editedFarm.representative_owner.province"
                                label="Provincia"
                                class="rounded-lg mb-3"
                                outlined
                                hide-details
                                rounded
                                dark
                                append-icon="mdi-city-variant-outline"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                v-model="editedFarm.representative_owner.country"
                                label="País"
                                class="rounded-lg mb-3"
                                outlined
                                hide-details
                                rounded
                                dark
                                append-icon="mdi-earth"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" class="pa-0 mt-4">
                            <v-subheader class="py-0 my-0">
                                Asesor en gestión integrada de plagas
                            </v-subheader> 
                            <v-divider class="mt-n2 mb-4"></v-divider>
                        </v-col>

                        <v-col cols="12" md="6">
                            <v-text-field
                                v-model="editedFarm.advisor.name"
                                label="Nombre"
                                required
                                class="rounded-lg mb-3"
                                outlined
                                hide-details
                                rounded
                                dark
                                append-icon="mdi-account-circle-outline"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                v-model="editedFarm.advisor.nif"
                                label="DNI/NIF"
                                required
                                class="rounded-lg mb-3"
                                outlined
                                hide-details
                                rounded
                                dark
                                append-icon="mdi-card-account-details-outline"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                v-model="editedFarm.advisor.ropoNumber"
                                label="Nº de ROPA"
                                required
                                class="rounded-lg mb-3"
                                outlined
                                hide-details
                                rounded
                                dark
                                append-icon="mdi-pound-box-outline"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-autocomplete
                                v-model="editedFarm.advisor.preventiveMeasures"
                                :items="preventiveMeasures"
                                item-text="text"
                                item-value="value"
                                label="Medidas preventivas"
                                multiple
                                required
                                class="rounded-lg mb-3"
                                outlined
                                hide-details
                                rounded
                                dark
                                append-icon="mdi-safety-goggles"
                            ></v-autocomplete>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" class="pa-0 mt-4">
                            <v-subheader class="py-0 my-0">
                                Datos de interés fitosanitario (Ley Agrícola)
                            </v-subheader> 
                            <v-divider class="mt-n2 mb-4"></v-divider>
                        </v-col>
                        <v-col cols="12" md="6">
                            <!-- Faucet icon -->
                            <v-select
                                v-model="editedFarm.phytosanitary_info.hasWaterHumanConsumptionOnFarm"
                                :items="phytosanitaryItems"
                                item-text="text"
                                item-value="value"
                                label="¿Hay pozos o masas de agua para consumo humano en la finca?"
                                required
                                class="rounded-lg mb-3"
                                outlined
                                hide-details
                                rounded
                                dark
                                append-icon="mdi-water"
                            ></v-select>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-select
                                v-model="editedFarm.phytosanitary_info.hasWaterHumanConsumptionNearby"
                                :items="phytosanitaryItems"
                                item-text="text"
                                item-value="value"
                                label="¿Hay pozos o masas de agua para consumo humano cercanos?"
                                required
                                class="rounded-lg mb-3"
                                outlined
                                hide-details
                                rounded
                                dark
                                append-icon="mdi-faucet"
                            ></v-select>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                v-if="editedFarm.phytosanitary_info.hasWaterHumanConsumptionNearby == 'YES'"
                                v-model="editedFarm.phytosanitary_info.distanceToWaterHumanConsumption"
                                label="Distancia hasta los pozos o masas de agua"
                                class="rounded-lg mb-3"
                                outlined
                                hide-details
                                rounded
                                dark
                                append-icon="mdi-ruler"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-select
                                v-model="editedFarm.phytosanitary_info.bordersUrbanAreaOrPublicRoad"
                                :items="phytosanitaryItems"
                                item-text="text"
                                item-value="value"
                                label="¿Limita con zona urbana o carretera pública?"
                                required
                                class="rounded-lg mb-3"
                                outlined
                                hide-details
                                rounded
                                dark
                                append-icon="mdi-road-variant"
                            ></v-select>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-btn
                                class="flex-grow-1 pa-6 elevation-0 rounded-lg red darken-4"
                                @click="remove"
                            >
                                Eliminar
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions class="d-flex">

                <v-btn
                    class="flex-grow-1 pa-6 elevation-0 rounded-lg"
                    @click="close"
                >
                    Cerrar
                </v-btn>
                <v-btn
                    class="flex-grow-1 pa-6 elevation-0 rounded-lg teal"
                    @click="save"
                >
                    Guardar
                </v-btn>
            </v-card-actions>
        </v-card>
        
    </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'

export default {
    name: 'EditFarmDialogComponent',
    components: {
    },
    props: {
        value: {
            type: Boolean,
            default: false
        },
        farm: {
            type: Object,
            default: null
        }
    },
    data: () => ({
        advancedOptions: true,
        editedFarm: {
            name: '',
            nif: '',
            companyType: '',
            email: '',
            phone: '',
            address: '',
            city: '',
            postalCode: '',
            province: '',
            country: '',
            picture: '',
            nationalRegistry: '',
            autonomicRegistry: '',
        },
        defaultFarm: {
            name: '',
            nif: '',
            companyType: '',
            email: '',
            phone: '',
            address: '',
            city: '',
            postalCode: '',
            province: '',
            country: '',
            picture: '',
            nationalRegistry: '',
            autonomicRegistry: '',
            representative_owner: {
                name: '',
                nif: '',
                representationType: '',
                email: '',
                phone: '',
                address: '',
                city: '',
                postalCode: '',
                province: '',
                country: '',
            },
            advisor: {
                name: '',
                nif: '',
                ropoNumber: '',
                preventiveMeasures: [],
            },
            phytosanitary_info: {
                hasWaterHumanConsumptionOnFarm: 'UNKNOWN',
                hasWaterHumanConsumptionNearby: 'UNKNOWN',
                distanceToWaterHumanConsumption: 0,
                bordersUrbanAreaOrPublicRoad: 'UNKNOWN',
            },
        },
        phytosanitaryItems: [
            {
                text: 'SI',
                value: 'YES'
            },
            {
                text: 'NO',
                value: 'NO'
            },
            {
                text: 'DESCONOCIDO',
                value: 'UNKNOWN'
            }
        ],
        preventiveMeasures: [
            {
                text: 'Rotación de cultivos',
                value: 'ROTATION_OF_CROPS'
            },
            {
                text: 'Utilización de técnicas de cultivo adecuadas (técnica de la falsa siembra, fechas y densidades de siembra, baja dosis de siembra, mínimo laboreo, poda y siembra directa, etc.)',
                value: 'USE_OF_APPROPRIATE_CULTIVATION_TECHNIQUES'
            },
            {
                text: 'Utilización de variedades resistentes',
                value: 'USE_OF_RESISTANT_VARIETIES'
            },
            {
                text: 'Utilización de prácticas de fertilización adecuadas',
                value: 'USE_OF_APPROPRIATE_FERTILIZATION_PRACTICES'
            },
            {
                text: 'Prevención de la propagación de organismos nocivos mediante medidas profilácticas (limpieza periódica de maquinaria y equipos, etc.)',
                value: 'PREVENTION_OF_SPREAD_OF_HARMFUL_ORGANISMS_THROUGH_PROPHYLACTIC_MEASURES'
            },
            {
                text: 'Protección de los organismos beneficiosos (con medidas fitosanitarias específicas, utilizando infraestructuras ecológicas que aumenten la biodiversidad, estableciendo márgenes multifuncionales para la mejora de la biodiversidad y fomento de la fauna útil, etc.)',
                value: 'PROTECTION_OF_BENEFICIAL_ORGANISMS'
            },
            {
                text: 'Monitoreo de plagas, enfermedades y malas hierbas.',
                value: 'MONITORING_OF_PESTS_DISEASES_AND_WEEDS'
            },
            {
                text: 'Establecimiento de umbrales de intervención fitosanitaria.',
                value: 'ESTABLISHMENT_OF_PEST_CONTROL_THRESHOLDS'
            },
            {
                text: 'Aplicación de medios no químicos (captura masiva, confusión sexual, lucha biológica, prácticas culturales para la desinfección de suelos, etc.).',
                value: 'USE_OF_NON_CHEMICAL_MEANS'
            },
            {
                text: 'Aplicación de productos fitosanitarios de bajo riesgo.',
                value: 'USE_OF_LOW_RISK_PESTICIDES'
            },
            {
                text: 'Seguimiento de una estrategia anti-resistencias en el uso de productos fitosanitarios.',
                value: 'FOLLOW_UP_OF_AN_ANTI_RESISTANCE_STRATEGY'
            },
            {
                text: 'Seguimiento de la efectividad de la intervención fitosanitaria.',
                value: 'MONITORING_OF_THE_EFFECTIVENESS_OF_PEST_CONTROL'
            },
            {
                text: 'Asesoramiento por un asesor en GIP o pertenencia a ATRIA, ADV o figuras equivalentes.',
                value: 'ADVICE_FROM_A_GIP_ADVISOR'
            },
            {
                text: 'Inscripción en un sistema de avisos fitosanitarios (Estaciones de avisos) o mediante DSS (Decision Support System/ ayudas a la toma de decisión).',
                value: 'REGISTRATION_IN_A_PLANT_HEALTH_WARNING_SYSTEM'
            },
        ],
    }),
    computed: {
        dialog: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit('input', val)
            }
        },
    },
    watch: {
        farm: {
            immediate: true,
            handler(newValue) {
                this.editedFarm = {...newValue}
                if (!this.editedFarm.representative_owner) {
                    this.editedFarm.representative_owner = this.defaultFarm.representative_owner
                }
                if (!this.editedFarm.advisor) {
                    this.editedFarm.advisor = this.defaultFarm.advisor
                }
                if (!this.editedFarm.phytosanitary_info) {
                    this.editedFarm.phytosanitary_info = this.defaultFarm.phytosanitary_info
                }
            }
        }
    },
    methods: {
        ...mapActions(['insertFarm', 'deleteFarm', 'updateFarm']),
        onButtonClick() {
            this.$refs.fileInput.$refs.input.click();
        },
        close() {
            this.dialog = false
        },
        save() {
            if (this.farm) {
                this.updateFarm(this.editedFarm)
            } else {
                this.insertFarm(this.editedFarm)
            }
            this.dialog = false
        },
        remove() {
            this.deleteFarm(this.farm.id)
            this.dialog = false
        },
    },
}
</script>

<style scoped>

</style>
  