import api from '@/services/api'

export default {
    namespaced: false,
    state: {
        campaigns: [],
    },
    getters: {
        campaigns: state => state.campaigns,
    },
    mutations: {
        setCampaigns(state, campaigns) {
            state.campaigns = campaigns
        },
    },
    actions: {
        async getCampaigns({ commit, dispatch }) {
            const response = await api.get(`/campaigns`)
            if (response.status === 200) {
                commit('setCampaigns', response.data.campaigns)
            } else if (response.status === 401 || response.status === 403) {
                dispatch('goToLogin')
            }
        },
        
        async insertCampaign({ commit, dispatch }, campaign) {
            const response = await api.post(`/campaigns`, { campaign })
            if (response.status === 201) {
                dispatch('getCampaigns')
            } else if (response.status === 401 || response.status === 403) {
                // dispatch('goToLogin')
            }
        },
        async updateCampaign({ commit, dispatch }, campaign) {
            const response = await api.put(`/campaigns/${campaign.id}`, { campaign })
            if (response.status === 200) {
                dispatch('getCampaigns')
            } else if (response.status === 401 || response.status === 403) {
                // dispatch('goToLogin')
            }
        },
        async deleteCampaign({ commit, dispatch }, campaignId) {
            const response = await api.delete(`/campaigns/${campaignId}`)
            if (response.status === 204) {
                dispatch('getCampaigns')
            } else if (response.status === 401 || response.status === 403) {
                // dispatch('goToLogin')
            }
        },
    },
};