<template>
    <v-container fluid class="fill-height blue-grey darken-4 px-3 py-0">
        <v-container fluid class="pa-0 align-self-start">
            <v-app-bar color="blue-grey darken-4" dark app fixed flat>
                <v-app-bar-nav-icon>
                    <template v-slot:default>
                        <v-img :src="require('@/assets/logo_leaf.png')" max-height="35" contain></v-img>
                    </template>
                </v-app-bar-nav-icon>
                <v-toolbar-title>Clientes</v-toolbar-title>
                
                <v-spacer></v-spacer>
                <!-- <v-btn class="px-6 py-5 rounded-lg teal darken-2 text-none teal--text text--lighten-4" flat>
                    <v-icon left small>mdi-download</v-icon>
                    <span class="ml-2">Descargar</span>
                </v-btn> -->
                <v-btn class="ml-2 px-6 py-5 rounded-lg teal darken-2 text-none teal--text text--lighten-4" text @click="edit()">
                    <v-icon left small>mdi-plus</v-icon>
                    <span class="ml-2">Nuevo cliente</span>
                </v-btn>
            </v-app-bar>
            <v-row class="grey darken-4">
                
                <v-col cols="12" class="px-0 py-3 overflow-y-auto" style="max-height: calc(100vh - 53px);">
                    <v-overlay :value="loading" :absolute="true">
                        <v-progress-circular
                            indeterminate
                            size="64"
                        ></v-progress-circular>
                    </v-overlay>
                    <v-simple-table
                    dark
                    >
                    <template v-slot:default>
                        <thead class="teal darken-3">
                            <tr>
                                <th class="text-left">
                                    Foto
                                </th>
                                <th class="text-left">
                                    Nombre
                                </th>
                                <th class="text-left">
                                    NIF
                                </th>
                                <th class="text-left">
                                    Nº RGSEAA
                                </th>
                                <th class="text-left">
                                    Email
                                </th>
                                <th class="text-left">
                                    Teléfono
                                </th>
                                <th class="text-left">
                                    Dirección
                                </th>
                                <th class="text-left">
                                    Ciudad
                                </th>
                                <th class="text-left">
                                    Código postal
                                </th>
                                <th class="text-left">
                                    Provincia
                                </th>
                                <th class="text-left">
                                    País
                                </th>
                                <th class="text-left">
                                    Comentarios
                                </th>
                                <th>
                                    Acciones
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                            v-for="client in clients"
                            :key="client.id"
                            >
                                <td class="pt-2 pb-0"><img width="50" class="rounded-lg" :src="client.photo" /></td>
                                <td>{{ client.fullName }}</td>
                                <td>{{ client.nif }}</td>
                                <td>{{ client.rgseaaNumber }}</td>
                                <td>{{ client.email }}</td>
                                <td>{{ client.phone }}</td>
                                <td>{{ client.address }}</td>
                                <td>{{ client.city }}</td>
                                <td>{{ client.postalCode }}</td>
                                <td>{{ client.province }}</td>
                                <td>{{ client.country }}</td>
                                <td>{{ client.comments }}</td>
                                <td>
                                    <v-btn icon small class="mr-2">
                                        <v-icon small color="grey lighten-2" @click="edit(client)">mdi-pencil</v-icon>
                                    </v-btn>
                                    <v-btn icon small>
                                        <v-icon small color="grey lighten-2" @click="remove(client.id)">mdi-delete</v-icon>
                                    </v-btn>
                                </td>
                            </tr>
                        </tbody>
                        </template>
                    </v-simple-table>
                </v-col>
            </v-row>
            <v-dialog 
            v-model="dialogEdit"
            max-width="800px"
            scrollable
            >
                <v-card dark>
                    <v-card-title>
                        <!-- TODO: Editar/Nuevo -->
                        <span class="headline">Editar trabajador</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <v-text-field
                                        v-model="editedClient.fullName"
                                        label="Nombre completo"
                                        required
                                        class="rounded-lg mb-3"
                                        outlined
                                        hide-details
                                        rounded
                                        dark
                                        append-icon="mdi-form-textbox"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-text-field
                                        v-model="editedClient.nif"
                                        label="NIF/CIF"
                                        required
                                        class="rounded-lg mb-3"
                                        outlined
                                        hide-details
                                        rounded
                                        dark
                                        append-icon="mdi-card-account-details"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-text-field
                                        v-model="editedClient.rgseaaNumber"
                                        label="Nº RGSEAA"
                                        required
                                        class="rounded-lg mb-3"
                                        outlined
                                        hide-details
                                        rounded
                                        dark
                                        append-icon="mdi-pound"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-text-field
                                        v-model="editedClient.email"
                                        label="Email"
                                        required
                                        class="rounded-lg mb-3"
                                        outlined
                                        hide-details
                                        rounded
                                        dark
                                        append-icon="mdi-email"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-text-field
                                        v-model="editedClient.phone"
                                        label="Teléfono"
                                        required
                                        class="rounded-lg mb-3"
                                        outlined
                                        hide-details
                                        rounded
                                        dark
                                        append-icon="mdi-phone"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-textarea
                                        v-model="editedClient.address"
                                        label="Dirección"
                                        required
                                        class="rounded-lg mb-3"
                                        outlined
                                        hide-details
                                        rounded
                                        dark
                                        append-icon="mdi-map-marker"
                                    ></v-textarea>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-text-field
                                        v-model="editedClient.city"
                                        label="Ciudad"
                                        required
                                        class="rounded-lg mb-3"
                                        outlined
                                        hide-details
                                        rounded
                                        dark
                                        append-icon="mdi-city"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-text-field
                                        v-model="editedClient.postalCode"
                                        label="Código postal"
                                        required
                                        class="rounded-lg mb-3"
                                        outlined
                                        hide-details
                                        rounded
                                        dark
                                        append-icon="mdi-post"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-text-field
                                        v-model="editedClient.province"
                                        label="Provincia"
                                        required
                                        class="rounded-lg mb-3"
                                        outlined
                                        hide-details
                                        rounded
                                        dark
                                        append-icon="mdi-map-marker-radius"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-text-field
                                        v-model="editedClient.country"
                                        label="País"
                                        required
                                        class="rounded-lg mb-3"
                                        outlined
                                        hide-details
                                        rounded
                                        dark
                                        append-icon="mdi-earth"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-text-field
                                        v-model="editedClient.comments"
                                        label="Comentarios"
                                        required
                                        class="rounded-lg mb-3"
                                        outlined
                                        hide-details
                                        rounded
                                        dark
                                        append-icon="mdi-comment-text-multiple"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions class="d-flex">
                        <v-btn
                            class="flex-grow-1 pa-6 elevation-0 rounded-lg"
                            @click="close"
                        >
                            Cerrar
                        </v-btn>
                        <v-btn
                            class="flex-grow-1 pa-6 elevation-0 rounded-lg teal"
                            @click="save"
                        >
                            Guardar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-container>
    </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
// import api from '@/services/api'

export default {
    name: 'ClientsView',
    components: {
    },
    props: {

    },
    data() {
        return {
            loading: false,
            editedClient: null,
            dialogEdit: false,
            editedClient: {
                id: null,
                fullName: 'Nombre completo',
                nif: '12345678A',
                rgseaaNumber: '123456789',
                email: 'juan@gmail.com',
                phone: '123456789',
                address: 'Calle',
                city: 'Ciudad',
                postalCode: 'Código postal',
                province: 'Provincia',
                country: 'País',
                comments: 'Comentarios',
                photo: 'https://picsum.photos/200/200'
            },
            ownershipStatuses: [
                {
                    name: 'Propio',
                    value: 'Owned',
                },
                {
                    name: 'Alquilado',
                    value: 'Rented',
                },
                {
                    name: 'Desconocido',
                    value: 'Unknown',
                },
            ],
            // clients: [
            // {
            //     id: 1,
            //     fullName: 'Juan Pardo García',
            //     nif: '12345678A',
            //     rgseaaNumber: '123456789',
            //     email: 'juan@gmail.com',
            //     phone: '123456789',
            //     address: 'Calle Juan',
            //     city: 'Madrid',
            //     postalCode: '28001',
            //     province: 'Madrid',
            //     country: 'España',
            //     comments: 'Comentarios de Juan',
            //     photo: 'https://picsum.photos/200/200'
            // },
            // {
            //     id: 2,
            //     fullName: 'Pedro García Rodríguez', 
            //     nif: '12345678B',
            //     rgseaaNumber: '123456789',
            //     email: 'pedro@gmail.com',
            //     phone: '123456789',
            //     address: 'Calle Pedro',
            //     city: 'Madrid',
            //     postalCode: '28001',
            //     province: 'Madrid',
            //     country: 'España',
            //     comments: 'Comentarios de Pedro',
            //     photo: 'https://picsum.photos/201/201'
            // },
            // {
            //     id: 3,
            //     fullName: 'Ana Torres García',
            //     nif: '12345678C',
            //     rgseaaNumber: '123456789',
            //     email: 'ana@gmail.com',
            //     phone: '123456789',
            //     address: 'Calle Ana',
            //     city: 'Madrid',
            //     postalCode: '28001',
            //     province: 'Madrid',
            //     country: 'España',
            //     comments: 'Comentarios de Ana',
            //     photo: 'https://picsum.photos/202/202'
            // },
            // {
            //     id: 4,
            //     fullName: 'Maria Castro López',
            //     nif: '12345678D',
            //     rgseaaNumber: '123456789',
            //     email: 'maria@gmail.com',
            //     phone: '123456789',
            //     address: 'Calle Maria',
            //     city: 'Madrid',
            //     postalCode: '28001',
            //     province: 'Madrid',
            //     country: 'España',
            //     comments: 'Comentarios de Maria',
            //     photo: 'https://picsum.photos/203/203'
            // }
            // ],
        };
    },
    computed: {
        ...mapGetters([
            'clients',
        ]),
    },
    watch: {

    },
    methods: {
        ...mapActions([
            'getClients',
            'insertClient',
            'updateClient',
            'deleteClient',
        ]),
        close() {
            this.dialogEdit = false
        },
        edit(client) {
            if (client) {
                this.editedClient = Object.assign({}, client)
            } else {
                this.editedClient = {
                    id: null,
                    fullName: 'Nombre completo',
                    nif: '12345678A',
                    rgseaaNumber: '123456789',
                    email: '',
                    phone: '',
                    address: '',
                    city: '',
                    postalCode: '',
                    province: '',
                    country: '',
                    comments: '',
                    photo: null
                }
            }
            this.dialogEdit = true
        },
        save() {
            if (this.editedClient.id) {
                this.updateClient(this.editedClient)
            } else {
                this.insertClient(this.editedClient)
            }
            this.close()
        },
        remove(id) {
            this.deleteClient(id)
        }
    },
    created() {
        // TODO: borrar para producción
        this.clients = this.clients.map(client => {
            client.photo = `https://api.dicebear.com/7.x/adventurer/svg?seed=${client.id}`
            return client
        })
        this.getClients()
    },
    mounted() {

    },
};
</script>