import Vue from "vue";
import Vuex from "vuex";
import auth from './auth'
import sectors from './sectors'
import farms from './farms'
import images from './images'
import notifications from './notifications'
import tasks from './tasks'
import events from './events'
import crew from './crew'
import externalStaff from './externalStaff'
import jobs from './jobs'
import machines from './machines'
import staff from './staff'
import warehouses from './warehouses'
import clients from "./clients";
import phytosanitaries from "./phytosanitaries";
import fertilizers from "./fertilizers";
import seeds from "./seeds";
import harvests from "./harvests";
import crops from "./crops";
import fertilizationMethods from "./fertilizationMethods";
import fertilizationTypes from "./fertilizationTypes";
import harvestPurposes from "./harvestPurposes";
import phytosanitaryMeasureTypes from "./phytosanitaryMeasureTypes";
import plagues from "./plagues";
import treatmentReasons from "./treatmentReasons";
import vegetableLeftoverDestinations from "./vegetableLeftoverDestinations";
import notebooks from "./notebooks";
import campaigns from "./campaigns";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        auth,
        sectors,
        farms,
        images,
        notifications,
        tasks,
        events,
        crew,
        externalStaff,
        jobs,
        machines,
        staff,
        warehouses,
        clients,
        phytosanitaries,
        fertilizers,
        seeds,
        harvests,
        crops,
        fertilizationMethods,
        fertilizationTypes,
        harvestPurposes,
        phytosanitaryMeasureTypes,
        plagues,
        treatmentReasons,
        vegetableLeftoverDestinations,
        notebooks,
        campaigns,
    },
    state: {
        months: [
            { id: 1, name: 'Enero', abrev: 'ene.', days: 31 },
            { id: 2, name: 'Febrero', abrev: 'feb.', days: 28 },
            { id: 3, name: 'Marzo', abrev: 'mar.', days: 31 },
            { id: 4, name: 'Abril', abrev: 'abr.', days: 30 },
            { id: 5, name: 'Mayo', abrev: 'may.', days: 31 },
            { id: 6, name: 'Junio', abrev: 'jun.', days: 30 },
            { id: 7, name: 'Julio', abrev: 'jul.', days: 31 },
            { id: 8, name: 'Agosto', abrev: 'ago.', days: 31 },
            { id: 9, name: 'Septiembre', abrev: 'sep.', days: 30 },
            { id: 10, name: 'Octubre', abrev: 'oct.', days: 31 },
            { id: 11, name: 'Noviembre', abrev: 'nov.', days: 30 },
            { id: 12, name: 'Diciembre', abrev: 'dic.', days: 31 },
        ],
        weatherIcons: {
            1000: "wu-clear",
            1003: "wu-partlycloudy",
            1006: "wu-cloudy",
            1009: "wu-cloudy",
            1030: "wu-fog",
            1063: "wu-chancerain",
            1066: "wu-snow",
            1069: "wu-snow",
            1072: "wu-chancerain",
            1087: "wu-tstorms",
            1114: "wu-snow",
            1117: "wu-snow",
            1135: "wu-fog",
            1147: "wu-fog",
            1150: "wu-chancerain",
            1153: "wu-chancerain",
            1168: "wu-chancerain",
            1171: "wu-chancerain",
            1180: "wu-chancerain",
            1183: "wu-rain",
            1186: "wu-rain",
            1189: "wu-rain",
            1192: "wu-rain",
            1195: "wu-rain",
            1198: "wu-rain",
            1201: "wu-rain",
            1204: "wu-snow",
            1207: "wu-snow",
            1210: "wu-snow",
            1213: "wu-snow",
            1216: "wu-snow",
            1219: "wu-snow",
            1222: "wu-snow",
            1225: "wu-snow",
            1237: "wu-snow",
            1240: "wu-chancerain",
            1243: "wu-rain",
            1246: "wu-rain",
            1249: "wu-snow",
            1252: "wu-snow",
            1255: "wu-snow",
            1258: "wu-snow",
            1261: "wu-snow",
            1264: "wu-snow",
            1273: "wu-tstorms",
            1276: "wu-tstorms",
            1279: "wu-snow",
            1282: "wu-snow"
        },
        ndviColors: [
            {
                ndvi: "0.95 - 1.00",
                color: "#007d47",
                text: "Vegetación extrema"
            },
            {
                ndvi: "0.90 - 0.95",
                color: "#009757",
                text: "Vegetación densa"
            },
            {
                ndvi: "0.85 - 0.90",
                color: "#14aa60",
                text: "Vegetación densa"
            },
            {
                ndvi: "0.80 - 0.85",
                color: "#53bd6c",
                text: "Vegetación densa"
            },
            {
                ndvi: "0.75 - 0.80",
                color: "#77ca6f",
                text: "Vegetación densa"
            },
            {
                ndvi: "0.70 - 0.75",
                color: "#9bd873",
                text: "Vegetación densa"
            },
            {
                ndvi: "0.65 - 0.70",
                color: "#b9e383",
                text: "Vegetación densa"
            },
            {
                ndvi: "0.60 - 0.65",
                color: "#d5ef94",
                text: "Vegetación densa"
            },
            {
                ndvi: "0.55 - 0.60",
                color: "#eaf7ac",
                text: "Vegetación moderada"
            },
            {
                ndvi: "0.50 - 0.55",
                color: "#fdfec2",
                text: "Vegetación moderada"
            },
            {
                ndvi: "0.45 - 0.50",
                color: "#ffebab",
                text: "Vegetación moderada"
            },
            {
                ndvi: "0.40 - 0.45",
                color: "#ffe093",
                text: "Vegetación moderada"
            },
            {
                ndvi: "0.35 - 0.40",
                color: "#ffc67d",
                text: "Vegetación escasa"
            },
            {
                ndvi: "0.30 - 0.35",
                color: "#ffab69",
                text: "Vegetación escasa"
            },
            {
                ndvi: "0.25 - 0.30",
                color: "#ff8d5a",
                text: "Vegetación escasa"
            },
            {
                ndvi: "0.20 - 0.25",
                color: "#fe6c4a",
                text: "Vegetación escasa"
            },
            {
                ndvi: "0.15 - 0.20",
                color: "#ef4c3a",
                text: "Suelo abierto"
            },
            {
                ndvi: "0.10 - 0.15",
                color: "#e02d2c",
                text: "Suelo abierto"
            },
            {
                ndvi: "0.05 - 0.10",
                color: "#c5142a",
                text: "Suelo abierto"
            },
            {
                ndvi: "-1.00 - 0.05",
                color: "#ad0028",
                text: "Suelo abierto"
            },
            {
                ndvi: "Nubes",
                color: "#FFFFFF",
                text: ""
            },
        ],
    },
    getters: {
        months: state => state.months,
        weatherIcons: state => state.weatherIcons,
        ndviColors: state => state.ndviColors,
    },
    mutations: {
    },
    actions: {
        goToLogin() {
            localStorage.removeItem('token')
            window.location.href = '/login'
        },
        goHome() {
            window.location.href = '/home'
        },
    },
});
