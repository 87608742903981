import api from '@/services/api'

export default {
    namespaced: false,
    state: {
        crew: [],
    },
    getters: {
        crew: state => state.crew,
    },
    mutations: {
        setCrew(state, crew) {
            state.crew = crew
        },
    },
    actions: {
        async getCrew({ commit, dispatch }) {
            const response = await api.get(`/crew`)
            if (response.status === 200) {
                commit('setCrew', response.data.crew)
            } else if (response.status === 401 || response.status === 403) {
                dispatch('goToLogin')
            }
        },
        
        async insertCrew({ commit, dispatch }, crew) {
            const response = await api.post(`/crew`, { crew })
            if (response.status === 201) {
                dispatch('getCrew')
            } else if (response.status === 401 || response.status === 403) {
                // dispatch('goToLogin')
            }
        },
        async updateCrew({ commit, dispatch }, crew) {
            const response = await api.put(`/crew/${crew.id}`, { crew })
            if (response.status === 200) {
                dispatch('getCrew')
            } else if (response.status === 401 || response.status === 403) {
                // dispatch('goToLogin')
            }
        },
        async deleteCrew({ commit, dispatch }, crewId) {
            const response = await api.delete(`/crew/${crewId}`)
            if (response.status === 204) {
                dispatch('getCrew')
            } else if (response.status === 401 || response.status === 403) {
                // dispatch('goToLogin')
            }
        },
    },
};