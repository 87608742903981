<template>
    <div class="mespreg d-flex flex-row text-center" :style="`width: ${width}; height: ${calcWidth/4.5}px;`">
        <div class="d-flex flex-column" style="background-color:#003399; width: 10%;">
            <div style="padding: 3px;">
                <v-img :src="require('@/assets/eu.png')"></v-img>
            </div>
            <span class="white--text" :style="`font-size: ${calcWidth/15}px;`">E</span>
        </div>
        <div :style="`font-size: ${calcWidth/5.2}px; margin-top: -${calcWidth/22}px; width: 100%;`">
            <span>
                {{ value }}
            </span> 
        </div>
    </div> 
</template>

<script>


export default {
    name: 'LicensePlateComponent',
    components: {
    },
    props: {
        width: {
            type: String,
            // default: '100%'
            default: '200px'
        },
        value: {
            type: String,
            default: '0000 BBB'
        },
    },
    data() {
        return {
        };
    },
    computed: {
        calcWidth() {
            return this.width.split('px')[0];
        },
    },
    watch: {

    },
    methods: {

    },
    created() {

    },
    mounted() {

    },
};
</script>
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap');
/* @font-face {
    font-family: 'MESPREG';
    src: url('~@/assets/fonts/MESPREG.ttf') format('truetype');
} */

/* .mespreg {
    font-family: 'MESPREG', sans-serif;
} */
.mespreg {
    font-family: 'Oswald';
    border: 1px solid #003399;
    border-radius: 5px;
    background: white;
    color: black;
}
</style>