import api from '@/services/api'

export default {
    namespaced: false,
    state: {
        treatmentReasons: [],
    },
    getters: {
        treatmentReasons: state => state.treatmentReasons,
    },
    mutations: {
        setTreatmentReasons(state, treatmentReasons) {
            state.treatmentReasons = treatmentReasons
        },
    },
    actions: {
        async getTreatmentReasons({ commit, dispatch }) {
            const response = await api.get(`/treatmentReasons`)
            if (response.status === 200) {
                commit('setTreatmentReasons', response.data.treatmentReasons)
            } else if (response.status === 401 || response.status === 403) {
                dispatch('goToLogin')
            }
        },
    },
};