import api from '@/services/api'

export default {
    namespaced: false,
    state: {
        fertilizationTypes: [],
    },
    getters: {
        fertilizationTypes: state => state.fertilizationTypes,
    },
    mutations: {
        setFertilizationTypes(state, fertilizationTypes) {
            state.fertilizationTypes = fertilizationTypes
        },
    },
    actions: {
        async getFertilizationTypes({ commit, dispatch }) {
            const response = await api.get(`/fertilizationTypes`)
            if (response.status === 200) {
                commit('setFertilizationTypes', response.data.fertilizationTypes)
            } else if (response.status === 401 || response.status === 403) {
                dispatch('goToLogin')
            }
        },
    },
};