import api from '@/services/api'

export default {
    namespaced: false,
    state: {
        vegetableLeftoverDestinations: [],
    },
    getters: {
        vegetableLeftoverDestinations: state => state.vegetableLeftoverDestinations,
    },
    mutations: {
        setVegetableLeftoverDestinations(state, vegetableLeftoverDestinations) {
            state.vegetableLeftoverDestinations = vegetableLeftoverDestinations
        },
    },
    actions: {
        async getVegetableLeftoverDestinations({ commit, dispatch }) {
            const response = await api.get(`/vegetableLeftoverDestinations`)
            if (response.status === 200) {
                commit('setVegetableLeftoverDestinations', response.data.vegetableLeftoverDestinations)
            } else if (response.status === 401 || response.status === 403) {
                dispatch('goToLogin')
            }
        },
    },
};