import api from '@/services/api'

export default {
    namespaced: false,
    state: {
        staff: [],
    },
    getters: {
        staff: state => state.staff,
    },
    mutations: {
        setStaff(state, staff) {
            state.staff = staff
        },
    },
    actions: {
        async getStaff({ commit, dispatch }) {
            const response = await api.get(`/staff`)
            if (response.status === 200) {
                commit('setStaff', response.data.staff)
            } else if (response.status === 401 || response.status === 403) {
                dispatch('goToLogin')
            }
        },
        
        async insertStaff({ commit, dispatch }, staff) {
            const response = await api.post(`/staff`, { staff })
            if (response.status === 201) {
                dispatch('getStaff')
            } else if (response.status === 401 || response.status === 403) {
                // dispatch('goToLogin')
            }
        },
        async updateStaff({ commit, dispatch }, staff) {
            const response = await api.put(`/staff/${staff.id}`, { staff })
            if (response.status === 200) {
                dispatch('getStaff')
            } else if (response.status === 401 || response.status === 403) {
                // dispatch('goToLogin')
            }
        },
        async deleteStaff({ commit, dispatch }, staffId) {
            const response = await api.delete(`/staff/${staffId}`)
            if (response.status === 204) {
                dispatch('getStaff')
            } else if (response.status === 401 || response.status === 403) {
                // dispatch('goToLogin')
            }
        },
    },
};