import api from '@/services/api'

export default {
    namespaced: false,
    state: {
        jobs: [],
        jobTypes: [
            {
                name: "Abonar",
                value: "Fertilize",
                icon: "mdi-auto-mode",
                need: ["fertilizers"]
            },
            {
                name: "Cosechar o Recolectar",
                value: "HarvestOrCollect",
                icon: "mdi-basket-fill",
                need: ["harvests"]
            },
            {
                name: "Otros trabajos",
                value: "OtherJobs",
                icon: "mdi-hammer-wrench"
            },
            {
                name: "Otros trabajos del cultivo",
                value: "OtherCropJobs",
                icon: "mdi-sprout"
            },
            {
                name: "Podar",
                value: "Prune",
                icon: "mdi-content-cut"
            },
            {
                name: "Preparar el terreno",
                value: "PrepareLand",
                icon: "mdi-tractor"
            },
            {
                name: "Sembrar o Plantar",
                value: "SowOrPlant",
                icon: "mdi-seed-outline",
                need: ["seeds"]
            },
            {
                name: "Tratamiento fitosanitario",
                value: "PhytosanitaryTreatment",
                icon: "mdi-leaf",
                need: ["phytosanitaries"]
            },
            {
                name: "Tratamiento fitosanitario a almacén",
                value: "WarehousePhytosanitaryTreatment",
                icon: "mdi-warehouse",
                need: ["phytosanitaries"]
            },
            {
                name: "Tratamiento fitosanitario a medio de transporte",
                value: "TransportPhytosanitaryTreatment",
                icon: "mdi-truck-check",
                need: ["phytosanitaries"]
            },
            {
                name: "Tratamiento fitosanitario a postcosecha",
                value: "PostHarvestPhytosanitaryTreatment",
                icon: "mdi-food-apple-outline",
                need: ["phytosanitaries"]
            },
            {
                name: "Tratamiento fitosanitario a semilla",
                value: "SeedPhytosanitaryTreatment",
                icon: "mdi-seed",
                need: ["phytosanitaries"]
            },
            {
                name: "Tratamiento no químico",
                value: "NonChemicalTreatment",
                icon: "mdi-flower-tulip-outline",
                need: ["phytosanitaries", "phytosanitaryMeasureTypes"]
            },
            {
                name: "Riego",
                value: "Irrigation",
                icon: "mdi-watering-can"
            }
        ],
    },
    getters: {
        jobs: state => state.jobs,
        jobTypes: state => state.jobTypes,
    },
    mutations: {
        setJobs(state, jobs) {
            state.jobs = jobs
        },
    },
    actions: {
        async getJobs({ commit, dispatch }) {
            const response = await api.get(`/jobs`)
            if (response.status === 200) {
                console.log(response.data.jobs)
                commit('setJobs', response.data.jobs)
            } else if (response.status === 401 || response.status === 403) {
                dispatch('goToLogin')
            }
        },
        
        async insertJob({ commit, dispatch }, job) {
            const response = await api.post(`/jobs`, { job })
            if (response.status === 201) {
                dispatch('getJobs')
            } else if (response.status === 401 || response.status === 403) {
                // dispatch('goToLogin')
            }
        },
        async updateJob({ commit, dispatch }, job) {
            const response = await api.put(`/jobs/${job.id}`, { job })
            console.log(response.status)
            if (response.status === 200) {
                dispatch('getJobs')
            } else if (response.status === 401 || response.status === 403) {
                // dispatch('goToLogin')
            }
        },
        async deleteJob({ commit, dispatch }, jobId) {
            const response = await api.delete(`/jobs/${jobId}`)
            if (response.status === 204) {
                dispatch('getJobs')
            } else if (response.status === 401 || response.status === 403) {
                // dispatch('goToLogin')
            }
        },
    },
};